import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Puzzle from '../../assets/images/puzzle_circle_gray.svg';
import BikeLearning from '../../assets/images/learning-philosophy2.png';
import Profile from '../../assets/images/profile-tikka.jpg';
import { Container } from 'react-bootstrap';
import './styles.scss';

const AboutPage = () => {
	return (
		<div className='about_page'>
			<section className='inner_banner_section'>
				<Container>
					<h1>About Us</h1>
				</Container>
			</section>
			<div className='about_container'>
				<section className='our_mission' id='mission-section'>
					<Container>
						<div className='our_mission_wrapper'>
							<h2>Our mission is to ...</h2>
							<p>make learning more rewarding and fulfilling, less burdensome</p>
							<p>.</p>
							<p>"A parent should not have to choose between
							a good education for the child and putting food on the table."</p>
							{/* <p>"A parent should not have to choose between
							putting food on the table and a good education/healthcare for the child."</p>
							<p>"No one should have to choose between putting food on the table
							and <span className="animated"></span>."</p> */}
						</div>
					</Container>
				</section>
				{/* <section className='message white-background'>
					<Container>
						<div className='message_wrapper'>
							<div className='message_wrapper_text'>
								<br></br>
								<h2>What does boo9 mean?</h2>
								<ol>
									<li><b>like a boon</b><br></br>
										<em>boo9 = boonine = boon-ine = boon-like = like a boon</em><br></br>
										The invention of GPS was <em>boo9</em> for navigation.
									</li>
									<li>
										<b>more rewarding and fulfilling, less burdensome; enriching</b><br></br>
										Our mission is to make learning <em>boo9</em>.
									</li>
								</ol>
							</div>
						</div>
					</Container>
				</section> */}
				<section className='our_learning'>
					<Container>
						<div className='our_learning_wrapper'>
							<div className='our_learning_text'>
								<h2>Our approach to learning</h2>
								<ul>
									<li>
										1. Emphasis on <strong>strong fundamentals</strong> (make it
										intuitive)
									</li>
									<li>
										2. <strong>Scalable</strong> problem solving skills (spark ah-ha
										moments)
									</li>
									<li>
										3. Keep the <strong>big picture</strong> in mind (what is the
										purpose?)
									</li>
									<li>4. Make it fun to learn!</li>
								</ul>
							</div>
							<div className='our_learning_image'>
								<ReactSVG src={Puzzle} className='our_learning_puzzle' />
							</div>
						</div>
					</Container>
				</section>
				<section className='our_philosophy'>
					<Container>
						<div className='our_philosophy_wrapper'>
							<div className='our_philosophy_image'>
								<img src={BikeLearning} />
							</div>
							<div className='our_philosophy_text'>
								<div className='our_philosophy_text_wrapper'>
									<h2>Our philosophy for learning</h2>
									<p>Learn the right way and practice.</p>
									<p>
										If you can visualize things, it can help you understand better and
										make the learning easier. We are building{' '}
										<Link to='/intela'>InteLa&trade;</Link> for that!
									</p>
								</div>
							</div>
						</div>
					</Container>
				</section>
				<section className='message'>
					<Container>
						<div className='message_wrapper'>
							<div className='message_wrapper_founder'>
								<img src={Profile} className='profile' />
								<p>
									<a
										href='https://www.linkedin.com/in/tikeswar/'
										target='_blank'
										rel='noreferrer'
									>
										Tikeswar Naik
									</a>
									, <span>Engineer & Founder</span>
								</p>
							</div>
							<div className='message_wrapper_text'>
								<h2>Message from founder</h2>
								<p>Hello!</p>
								<p>My name is Tikka. I have a background in
									Mechanical Engineering and Machine Learning.</p>
								<p>
								I believe education and wellness are like air and water.
								Quality education and healthcare are the birthright of everyone,
								regardless of financial or social status.
								We are taking a humble start towards realizing this vision.
								</p>

								<p>
									<div>
										Check out our YouTube channel: {' '}
										<a
											href='https://www.youtube.com/@boo9X'
											target='_blank'
											rel='noreferrer'
										>
											@boo9X
										</a>
									</div>
									<div>
										In this channel we post videos related to Math, Physics,
										and Problem Solving in general.
									</div>
									{/* <div>
										Our main Instagram channel: {' '}
										<a
											href='https://www.instagram.com/boo9xyz/'
											target='_blank'
											rel='noreferrer'
										>
											boo9xyz
										</a>
									</div> */}
									{/* <div>
										Our YouTube channel for Wellness: {' '}
										<a
											href='https://www.youtube.com/@boo9Wellness'
											target='_blank'
											rel='noreferrer'
										>
											@boo9Wellness
										</a>
									</div> */}
								</p>
								<p>
								If you think we should connect, let's connect :){' '}
									<a
										href='https://www.linkedin.com/in/tikeswar/'
										target='_blank'
										rel='noreferrer'
									>
										LinkedIn
									</a>
								</p>
								<p className='message_wrapper_last_text'>Thank you for stopping by!</p>
							</div>
						</div>
					</Container>
				</section>
				<section className='about_map'>
					<Container>
						<div className='about_map_wrapper'>
							<h2 className='about_map_heading'>
								Our coordinates ... Silicon Valley, CA
							</h2>
						</div>
					</Container>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202844.3766950822!2d-122.181182009794!3d37.40299366529422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb68ad0cfc739%3A0x7eb356b66bd4b50e!2sSilicon%20Valley%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1673332332353!5m2!1sen!2sin'
						allowFullScreen=''
						loading='lazy'
						referrerPolicy='no-referrer-when-downgrade'
					></iframe>
				</section>
			</div>
		</div>
	);
};

export default AboutPage;
