/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props) => {
	const location = useLocation();

	useEffect(() => {
		const timer = setTimeout(() => {
			if (location.hash) {
				const scrollId = location.hash.replace('#', '');
				const scrollSection = document.getElementById(scrollId);
				if (scrollSection) {
					scrollSection.scrollIntoView({ behavior: 'smooth' });
				} else {
					window.scrollTo({
						top: 0,
						behavior: 'smooth'
					});
				}
			} else {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}
		}, 200);
		return () => {
			clearTimeout(timer);
		};
	}, [location]);

	return <>{props.children}</>;
};

export default ScrollToTop;
