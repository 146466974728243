import { isArray, isEmpty, isObject } from 'lodash';
import React from 'react';
import './styles.scss';

const ErrorHandleComponent = ({ errorMessage }) => {
	if (isEmpty(errorMessage)) return;

	if (!isObject(errorMessage)) {
		return <div >
			<p className='error_message'>{errorMessage}</p>
		</div>;
	}

	return (
		<div >
			{errorMessage &&
				Object.values(errorMessage).map((e, i) => {
					if (isArray(e)) {
						return e.map((element, index) => {
							return (
								<h6 key={index} className='error_message_handle'>
									{element}
								</h6>
							);
						});
					} else {
						return <h6 key={i} className='error_message_handle'>
							{e}
						</h6>;
					}
				})}
		</div>
	);
};

export default ErrorHandleComponent;
