import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';

const AccordionHeader = ({
	section,
	handleAttempted,
	navigateToQuestion,
	index,
	data,
	LockIcon
}) => {
	return (
		<Accordion.Header
			className={section.list_qgs.length < 1 ? 'section_lock' : 'logged-in'}
		>
			<span className='number'>{section.sno}</span>
			<div className='accordion_header_content'>
				<h3>{section.section_title}</h3>
				<div className='attempted'>
					<div className='inner'>
						<div className='dots'>
							{section.list_qgs.map((SubSection) => {
								return SubSection.list_ques.map((e, i) => {
									return (
										<OverlayTrigger
											key={i}
											placement='bottom'
											overlay={
												<Tooltip id={`tooltip-${i}`}>
													<strong>{e.qno}</strong>
												</Tooltip>
											}
										>
											<span
												className={handleAttempted(e, index)}
												onClick={(eve) => navigateToQuestion(eve, e, section)}
											></span>
										</OverlayTrigger>
									);
								});
							})}
						</div>
						<div className='attempted_number'>
							{data?.submitted?.attempted && (
								<div>
									Score {section.section_score} / {section.section_score_outof}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{section.list_qgs.length < 1 && (
				<ReactSVG src={LockIcon} className='lock_icon' />
			)}
		</Accordion.Header>
	);
};

export default memo(AccordionHeader);
