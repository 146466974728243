/* eslint-disable */
import React, { useEffect } from "react";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import RightAnswereComponent from "../RightAnswereComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import {
  questionCorrectnessType1,
  attemptedQuestionCounterType3,
  correctQuestionValType2,
  // correctQuestionValType2New,
  handleChange,
} from "../../../utils/PracticePage";
import "./styles.scss";
import Markdown2 from "../../Markdown2";
import AnsMarkingComponent from "../AnsMarkingComponent";
import CommonFlag from "../CommonFlag";

const FillInlineQ = ({
  data,
  value,
  fieldValue,
  indexValue,
  setFieldVal,
  name,
}) => {
  const getArray = () => {
    let b = 0;
    const arr = data.q_html.split(/(<input type='text' \/>)/).map((e) => {
      if (e === "<input type='text' />") {
        b++;
        return { element: e, index: b - 1 };
      }
      return { element: e };
    });
    return arr;
  };

  useEffect(() => {
    const tempList = data.q_html
      .split(/(<input type='text' \/>)/)
      .filter((e) => e === "<input type='text' />");
    attemptedQuestionCounterType3(
      data,
      value,
      indexValue,
      fieldValue,
      tempList
    );
    // correctQuestionValType2New(data, fieldValue, setFieldVal, name, value, indexValue, tempList)
    correctQuestionValType2(data, fieldValue, setFieldVal, name);
  }, [value]);

  const generateHTMLContent = (ctext) => {
    return <Markdown2>{ctext}</Markdown2>;
  };

  return (
    <div className="fig_simple_display_group" id={`${data.q_type}_${data.qno}`}>
      <div className="fig_simple_display">
        <div className="fig_question_display">
          <div className="question_number">
            <h6>{data.qno}</h6>
            <QuestionScoreComponent data={data} />
            <QuestionHintComponent hints={data.hints} />
            <QueExplanationComponent
              data={data}
              explanations={data.explanations}
            />
            <AnsMarkingComponent data={data} />
            <CommonFlag data={data} />
          </div>
          <div className="fig_question_list">
            {getArray().map((ele, index) => {
              if (ele.element === "<input type='text' />") {
                return (
                  <React.Fragment key={index}>
                    <input
                      type="text"
                      onChange={(e) => {
                        handleChange(e, setFieldVal, name + `.${ele.index}`);
                      }}
                      value={(fieldValue && fieldValue[ele.index]) || ""}
                      name={name + `.${ele.index}`}
                      disabled={data.ans_obj}
                      className={
                        data.ans_obj &&
                        questionCorrectnessType1(data, ele.index)
                      }
                    />
                    <RightAnswereComponent data={data} index={ele.index} />
                  </React.Fragment>
                );
              }
              // return ele.element;
              return generateHTMLContent(ele.element);
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FillInlineQ;
