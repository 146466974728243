/* eslint-disable */
import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hidelFaggedQuestionModal } from "../../../../redux/actions";

const FlaggedAlreadyQuestionModal = ({
  setStillwantFlag,
  currentFlag,
  setCurrentFlag,
}) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    image_not_showing_well: Yup.boolean(),
    math_symbol_not_showing_correctly: Yup.boolean(),
    question_is_not_showing_well: Yup.boolean(),
    question_has_a_typo: Yup.boolean(),
    question_is_confusing: Yup.boolean(),
    question_seems_wrong: Yup.boolean(),
    i_have_a_suggestion_for_this_question: Yup.boolean(),
    other_issues: Yup.boolean(),
    comment: Yup.string(),
  });

  const flagArray = [
    { label: "Question has a typo", value: "question_has_a_typo" },
    { label: "Image(s) not showing well", value: "image_not_showing_well" },
    {
      label: "Math symbol(s) not showing correctly",
      value: "math_symbol_not_showing_correctly",
    },
    {
      label: "Question/choice(s) not showing well",
      value: "question_is_not_showing_well",
    },
    { label: "Question seems wrong", value: "question_seems_wrong" },
    { label: "Question is confusing", value: "question_is_confusing" },
    { label: "Question seems incomplete", value: "question_seems_incomplete" },

    {
      label: "I have a suggestion for this question",
      value: "i_have_a_suggestion_for_this_question",
    },
    { label: "Other issues", value: "other_issues" },
  ];

  const initialValues = {
    image_not_showing_well: false,
    math_symbol_not_showing_correctly: false,
    question_is_not_showing_well: false,
    question_has_a_typo: false,
    question_is_confusing: false,
    question_seems_wrong: false,
    question_seems_incomplete: false,
    i_have_a_suggestion_for_this_question: false,
    other_issues: false,
    comment: "",
  };
  const { flaggedQuestion } = useSelector((state) => state.practiceSet);
  const onSubmit = (data) => {
    setStillwantFlag(true);
    setCurrentFlag(0);
  };
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          useEffect(() => {
            const flag =
              flaggedQuestion?.existing_flag?.flags?.[currentFlag]?.flag;
            flagArray.forEach((field) =>
              setFieldValue(field.value, flag[field.value])
            );
            setFieldValue("comment", flag?.comment);
          }, [flaggedQuestion, currentFlag, flagArray]);
          return (
            <Container>
              <Form onSubmit={handleSubmit}>
                {flagArray &&
                  flagArray.map((item, index) => (
                    <div className="mb-3" key={index}>
                      <Field
                        type="checkbox"
                        id={item.value}
                        name={item.value}
                        className="form-check-input me-2"
                        onChange={handleChange}
                        disabled
                      />
                      <label className="form-check-label">{item.label}</label>
                    </div>
                  ))}

                <BootstrapForm.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                  disabled
                >
                  <BootstrapForm.Label>
                    Description of the issue
                  </BootstrapForm.Label>
                  <Field
                    as="textarea"
                    rows={3}
                    name="comment"
                    className="form-control bg-white border-0 resize-none"
                    disabled
                  />
                </BootstrapForm.Group>

                <div className="footer-btn">
                  <div className="d-flex">
                    <Button
                      type="button"
                      className="btn"
                      variant="outline-secondary"
                      style={{ border: "none" }}
                      onClick={() => setCurrentFlag((prev) => prev - 1)}
                      disabled={currentFlag === 0}
                    >
                      <img
                        src="/left-arrow.png"
                        alt="left-arrow"
                        className={`arrow-img-icon ${
                          currentFlag === 0 ? "disabled" : ""
                        }`}
                      />
                    </Button>

                    <p className="m-auto">
                      {currentFlag + 1}/
                      {flaggedQuestion?.existing_flag?.flags?.length}
                    </p>
                    <Button
                      type="button"
                      className="btn"
                      variant="outline-secondary"
                      style={{ border: "none" }}
                      onClick={() => {
                        setCurrentFlag((prev) => prev + 1);
                      }}
                      disabled={
                        currentFlag + 1 ===
                        flaggedQuestion?.existing_flag?.flags?.length
                      }
                    >
                      <img
                        src="/right-arrow.png"
                        alt="left-arrow"
                        className={`arrow-img-icon ${
                          flaggedQuestion?.existing_flag?.flags?.length ===
                          currentFlag + 1
                            ? "disabled"
                            : ""
                        }`}
                      />
                    </Button>
                  </div>
                  <Button
                    type="button"
                    className="btn"
                    variant="outline-secondary"
                    onClick={() => {
                      dispatch(hidelFaggedQuestionModal(false));
                      setCurrentFlag(0);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    I still want to flag
                  </Button>
                </div>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </Container>
  );
};

export default FlaggedAlreadyQuestionModal;
