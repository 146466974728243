import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ToastContainer, toast } from 'react-toastify';
import contentImage from '../../../assets/images/login.svg';
import LeftArrow from '../../../assets/images/white-left-arrow.png';
import passwordIcon from '../../../assets/images/lock.png';
import eyeIcon from '../../../assets/images/eye.png';
import eyeCloseIcon from '../../../assets/images/eye_close.png';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import AuthCarousel from '../../../components/AuthComponents/AuthCarousel';
import FloatinglabelComponent from '../../../components/AuthComponents/FloatinglabelComponent';
import FormikErrorComponent from '../../../components/AuthComponents/FormikErrorComponent';
import {
	initialValuesSignIn,
	signinSchema
} from '../../../constants/Authentication';
import Loader from '../../../components/Loader';
import { signIn, handleSignupConfirm } from '../../../redux/actions';
import './styles.scss';

const SignInPage = () => {
	const dispatch = useDispatch();
	const [isCall, setIsCall] = useState(false);
	const {
		loading,
		signinErrorMessage,
		signupConfirmResponse,
		signupConfirmErrorResponse
	} = useSelector((state) => state.auth);

	const location = useLocation();
	const fail = new URLSearchParams(location.search).get('error');
	const message = new URLSearchParams(location.search).get('message');
	const signUpConfirmToken = new URLSearchParams(location.search).get('token');

	useEffect(() => {
		if (!isEmpty(signUpConfirmToken)) {
			dispatch(handleSignupConfirm(signUpConfirmToken));
			setIsCall(true);
		}
	}, []);

	useEffect(() => {
		if (isCall) {
			errorMsg();
		}
	}, [signupConfirmResponse, signupConfirmErrorResponse]);

	const errorMsg = () => {
		if (!isEmpty(signupConfirmErrorResponse) && signupConfirmErrorResponse.error) {
			setIsCall(false);
			toast.error(signupConfirmErrorResponse.message);
		} else if (!isEmpty(signupConfirmResponse) && signupConfirmResponse?.status_code === 200) {
			setIsCall(false);
			toast.success(signupConfirmResponse.message);
		} else if (!isEmpty(signupConfirmResponse) && signupConfirmResponse?.status_code === 400) {
			setIsCall(false);
			toast.error(signupConfirmResponse.message);
		} else {
			setIsCall(false);
		}
	};

	const onSubmit = () => {
		const formData = new FormData();
		formData.append('username', formik.values.username);
		formData.append('password', formik.values.password);
		dispatch(signIn(formData));
	};
	const formik = useFormik({
		initialValues: initialValuesSignIn,
		validationSchema: signinSchema,
		onSubmit
	});
	const [values, setValues] = React.useState({
		showPWD: false
	});
	const passwordClick = () => {
		setValues({ showPWD: !values.showPWD });
	};

	return (
		<div className='auth_page'>
			<AuthCarousel contentImage={contentImage} />
			<ToastContainer />
			<div className='auth_form'>
				<div className='auth_form_wrapper'>
					<div className='left_arrow'>
						<img src={LeftArrow} />
					</div>
					<h2>Log in</h2>
					<Form onSubmit={formik.handleSubmit}>
						<div className='main-form-floating'>
							<FloatinglabelComponent
								formikTouched={formik.touched.username}
								formikErrors={formik.errors.username}
								formikValues={formik.values.username}
								onChange={formik.handleChange}
								label='Username'
								id='username'
								type='text'
								placeholder='name@example.com'
							/>
							<FormikErrorComponent
								errorName={formik.errors.username}
								touched={formik.touched.username}
							/>
						</div>
						<div className='main-form-floating'>
							<FloatingLabel
								label='Password'
								htmlFor='password'
								className={
									formik.touched.password && formik.errors.password ? 'error' : null
								}
							>
								<span className='icon'>
									<img src={passwordIcon} />
								</span>
								<Form.Control
									type={values.showPWD ? 'text' : 'password'}
									id='password'
									value={formik.values.password}
									onChange={formik.handleChange}
									placeholder='Password'
								/>
								<span className='eye-icon' onClick={passwordClick}>
									<img src={values.showPWD ? eyeCloseIcon : eyeIcon} />
								</span>
							</FloatingLabel>
							<FormikErrorComponent
								errorName={formik.errors.password}
								touched={formik.touched.password}
							/>
						</div>
						<Button variant='primary' type='submit' disabled={loading}>
							{loading ? <Loader /> : 'Log in'}
						</Button>
					</Form>
					{message && (
						<p className={`${fail === 'false' ? 'success' : 'fail'}`}>{message}</p>
					)}
					{!isEmpty(signinErrorMessage) && (
						<ErrorHandleComponent errorMessage={signinErrorMessage} />
					)}
					<span className='forgot'>
						<Link to='/forgot-password'>Forgot password?</Link>
					</span>
					<span className='signup'>
						Do not have an account?{' '}
						<b>
							<Link to='/signup'>Sign up</Link>
						</b>
					</span>
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
