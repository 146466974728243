import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from '../pages/HomePage';
import CustomRoute from '../components/CustomRoute';
import { allRoutes } from '../constants/AllRoutes';
import ScrollToTop from '../components/ScrollToTop';
import NotFound from '../pages/NotFoundPage';

const RouterContainer = () => {
	return (
		<div className='App'>
			<ScrollToTop>
				<ToastContainer />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='*' element={<NotFound />} />
					{allRoutes.map((e, i) => {
						const routeProps = {
							routeType: e.routeType,
							components: e.components,
							isTimed: e.isTimed,
							isAdminRoute: e.isAdminRoute
						};
						return (
							<Route
								key={i}
								path={e.path}
								element={
									<CustomRoute {...routeProps} />
								}
							/>
						);
					})}
				</Routes>
			</ScrollToTop>
		</div>
	);
};

export default RouterContainer;
