import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import SignUpSuccessImage from '../../../assets/images/forgot_password.svg';
import './styles.scss';

const SignUpSuccessPage = () => {
	const message = useSelector((state) => state.auth.signupSuccessMessage);
	return (
		<div className='signup_success_page'>
			<div className='signup_success_content'>
				<div className='signup_success_content_wrapeer'>
					<ReactSVG src={SignUpSuccessImage} className='signup_image' />
					<div className='signup_success_title'>
						<h2>One last step …</h2>
					</div>
					<div className='signup_success_description'>
						<h6 dangerouslySetInnerHTML={{ __html: message }} />
						<p>
							If you do not see the email, please check your spam folder. If you still
							have issues signing up, please <Link to='/contact'>contact us</Link>.
						</p>
						<p>
							Already clicked on the confirmation link?{' '}
							<Link to='/login'>Log in</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUpSuccessPage;
