import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Logo from '../../assets/images/logo.svg';
import YTLogo from '../../assets/images/youtube-svgrepo-com.svg';
import IGLogo from '../../assets/images/instagram-color-svgrepo-com.svg';
import LILogo from '../../assets/images/linkedin-svgrepo-com.svg';
import { footerContents } from '../../constants/FooterData';
import './styles.scss';

const FooterComponent = () => {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer_wrapper'>
					<div className='footer_logo'>
						<NavLink to='/'>
							<ReactSVG src={Logo} />
						</NavLink>
						<p>Our mission is to make learning more rewarding and fulfilling, less burdensome</p>
						<span>&copy; {(new Date().getFullYear())} boo9 inc. | boo9&trade; | InteLa&trade;</span>
						<span className='youtube-link'>
							<a href="https://www.youtube.com/@boo9X" target="_blank" rel="noopener noreferrer">
								<ReactSVG src={YTLogo} />
							</a>
							<a href="https://www.instagram.com/boo9xyz/" target="_blank" rel="noopener noreferrer">
								<ReactSVG src={IGLogo} />
							</a>
							<a href="https://www.linkedin.com/company/boo9" target="_blank" rel="noopener noreferrer">
								<ReactSVG src={LILogo} />
							</a>
						</span>
						<span style={{ fontSize: '10px', color: 'gray' }}>
							SAT&reg; is a registered trademark of the College Board.
							PSAT/NMSQT&reg; is a registered trademark of the College Board
							and National Merit Scholarship Corporation.
							AMC is owned by the Mathematical Association of America.
							MOEMS&reg; is a registered trademark of moems.org.
							Math Kangaroo is owned by mathkangaroo.org.
							We (boo9 inc.) are neither affiliated with, nor endorsed by, these organizations.
						</span>
					</div>
					<div className='footer_menu'>
						<h2>Company</h2>
						{footerContents.filter((_, i) => i < 5).map((e, i) => {
							return <NavLink key={i} to={e.to}>{e.text}</NavLink>;
						})}
					</div>
					<div className='footer_menu'>
						<h2>Products & Services</h2>
						{footerContents.filter((_, i) => i > 4).map((e, i) => {
							return <NavLink key={i} to={e.to}>{e.text}</NavLink>;
						})}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;
