import React from 'react';
import { Carousel } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/logo-light.svg';
import './styles.scss';

const AuthCarousel = ({ contentImage, contentMessage }) => {
	const location = useLocation();
	const carouselItems = () => {
		return (
			<Carousel>
				<Carousel.Item>
					<p>Join boo9&trade; to practice and learn:<br />
						Math, English, and more ...</p>
				</Carousel.Item>
				<Carousel.Item>
					<p>Our approach to learning - strong fundamentals | scalable skills | big picture</p>
				</Carousel.Item>
				<Carousel.Item>
					<p>InteLa&trade;: Interactive learning ... coming soon</p>
				</Carousel.Item>
				<Carousel.Item>
					<p>Our mission is to make learning more rewarding and fulfilling, less burdensome</p>
				</Carousel.Item>
			</Carousel>
		);
	};
	return (
		<div className='auth_content'>
			<div className='auth_content_wrapeer'>
				<ReactSVG src={contentImage} className='auth_image' />
				<div className='auth_title'>
					<h2>Welcome to</h2>
					<ReactSVG src={Logo} className='auth_logo' />
				</div>
				{
					location.pathname === '/signup' && (
						carouselItems()
					)
				}
			</div>
		</div>
	);
};

export default AuthCarousel;
