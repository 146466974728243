import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import passwordIcon from '../../../assets/images/lock.png';
import eyeIcon from '../../../assets/images/eye.png';
import eyeCloseIcon from '../../../assets/images/eye_close.png';
import infoIcon from '../../../assets/images/info.svg';
import checkIcon from '../../../assets/images/check.png';
import verifyIcon from '../../../assets/images/icons/check-icon.svg';
import crossIcon from '../../../assets/images/cross.png';

const PasswordFloatingLabel = ({
	passwordCheck,
	formikTouched,
	formikErrors,
	formikValues,
	onChange
}) => {
	const [passwordErrorDisplay, setPasswordErrorDisplay] = useState(false);
	const [values, setValues] = useState({ showPWD: false });
	const [passwordCorrection, setPasswordCorrection] = useState(false);

	const passwordClick = () => {
		setValues({ showPWD: !values.showPWD });
	};

	const isvalidPassword = () => {
		return passwordCheck.passwordLowerCaseError &&
				passwordCheck.passwordUpperCaseError &&
				passwordCheck.passwordNumberError &&
				passwordCheck.passwordSpecialCharError &&
				passwordCheck.passwordLengthError;
	};

	return (
		<FloatingLabel
			label='Password'
			htmlFor='password'
			className={formikTouched.password && formikErrors.password ? 'error' : null}
		>
			<span className='icon'>
				<img src={passwordIcon} />
			</span>
			{
				passwordCorrection && (
					<span className='correction_icon'>
						{
							isvalidPassword()
								? <ReactSVG src={verifyIcon} />
								: <img src={crossIcon} />
						}
					</span>
				)
			}
			<Form.Control
				type={values.showPWD ? 'text' : 'password'}
				id='password'
				value={formikValues.password}
				onChange={onChange}
				placeholder='Password'
				onFocus={() => {
					setPasswordErrorDisplay(true);
				}}
				onBlur={() => {
					setPasswordErrorDisplay(false);
					setPasswordCorrection(true);
				}}
			/>
			<span className='eye-icon' onClick={passwordClick}>
				<img src={values.showPWD ? eyeCloseIcon : eyeIcon} />
			</span>
			<span className='info-icon'>
				<ReactSVG src={infoIcon} />
			</span>
			<div
				className={`form_error_handle ${
					passwordErrorDisplay ? 'errorShow' : 'errorHide'
				}`}
			>
				<p className={`${passwordCheck.passwordLowerCaseError && 'valid'}`}>
					* a lowercase letter (a-z) <img src={checkIcon} />
				</p>
				<p className={`${passwordCheck.passwordUpperCaseError && 'valid'}`}>
					* an uppercase letter (A-Z) <img src={checkIcon} />
				</p>
				<p className={`${passwordCheck.passwordNumberError && 'valid'}`}>
					* a number (0-9) <img src={checkIcon} />
				</p>
				<p className={`${passwordCheck.passwordSpecialCharError && 'valid'}`}>
					* a special character ($%*&_) <img src={checkIcon} />
				</p>
				<p className={`${passwordCheck.passwordLengthError && 'valid'}`}>
					* 8-16 characters <img src={checkIcon} />
				</p>
			</div>
		</FloatingLabel>
	);
};

export default PasswordFloatingLabel;
