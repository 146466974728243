import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export const PublicRoute = ({ children }) => {
	const { userData, userLastLocation } = useSelector(
		(state) => state.auth
	);

	if (!isEmpty(userData)) {
		return (
			<Navigate to={userLastLocation !== '' ? userLastLocation : '/'} replace />
		);
	}

	return children;
};
