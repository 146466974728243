/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ReactSVG } from "react-svg";
import { useSpeechSynthesis } from "react-speech-kit";
import { Container, Accordion, Button, Form } from "react-bootstrap";
import { isEmpty, isNull, isArray, has } from "lodash";
import LockIcon from "../../assets/images/icons/lock-icon.svg";
import TextAnswerQ from "../../components/QuestionsGroup/TextAnswerQ";
import DictationQ from "../../components/QuestionsGroup/DictationQ";
import MathExpressionQ from "../../components/QuestionsGroup/MathExpressionQ";
import MultipleChoiceQ from "../../components/QuestionsGroup/MultipleChoiceQ";
import UniqueChoiceQ from "../../components/QuestionsGroup/UniqueChoiceQ";
import TrueFalseQ from "../../components/QuestionsGroup/TrueFalseQ";
import FillGroupQ from "../../components/QuestionsGroup/FillGroupQ";
import FillInlineQ from "../../components/QuestionsGroup/FillInlineQ";
import FillBlankQ from "../../components/QuestionsGroup/FillBlankQ";
import FillImgQ from "../../components/QuestionsGroup/FillImgQ";
import MatchItemsQ from "../../components/QuestionsGroup/MatchItemsQ";
import MatrixMatchQ from "../../components/QuestionsGroup/MatrixMatchQ";
import ErrorHandleComponent from "../../components/ErrorHandleComponent";
import HandleButtonComponent from "../../components/PracticeSectionComponents/HandleButtonComponent";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import infoIcon from "../../assets/images/icons/info.svg";
import {
  practiceSections,
  saveAsDraft,
  practiceSectionsSubmit,
  adminPracticeSections,
  adminPracticeSectionsSubmit,
  adminTimedSaveAsDraft,
  getAdminTimedSaveAsDraftData,
  showLoginModalView,
  storeCurrentQuestionAns,
} from "../../redux/actions";
import {
  resetFormData,
  currentAccordion,
  setShouldShowAns,
} from "../../redux/slices/practiceSlice";
import HomeIcon from "../../assets/images/icons/home-icon.svg";
import breadcrumbArrow from "../../assets/images/icons/breadcrumb-arrow.svg";
import AccordionItem from "../../components/PracticeSectionComponents/AccordionItem";
import { ToastContainer, toast } from "react-toastify";
import { getFieldVal } from "../../utils/PracticePage";
import "./styles.scss";
import FlaggedQuestionModal from "../PracticePage/flaggedQuestionPage";

const SectionsPage = () => {
  const shouldRender = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [loadingForResponse, setLoadingForResponse] = useState(true);
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState("1");
  const [finalSubmitModalShow, setFinalSubmitModalShow] = useState(false);
  const [finalAnsTosubmit, setFinalAnsTosubmit] = useState({});
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [draftMessageShow, setDraftMessageShow] = useState(false);
  const [showHideAns, setShowHideAns] = useState(false);
  const [flagQuestion, setFlagQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const { loading: authLoading, userData } = useSelector((state) => state.auth);
  const {
    answerSubmitted,
    saveAsDraftSuccess,
    loading,
    practiceSectionData,
    practiceSectionErrorMessage,
    alertSuccessMessage,
    alertErrorMessage,
    alert,
    activeAccordion,
    baseUrlForSectionImg,
    timedSaveAsDraftResponse,
    setStore,
    flagQuestionModal,
    showLoginModal,
    currentQuestion: flagQues,
  } = useSelector((state) => state.practiceSet);
  // Get sectionId
  const location = useLocation();
  const qsetId = new URLSearchParams(location.search).get("id");
  const qsid = new URLSearchParams(location.search).get("sid") ? true : false;
  const isAdmin =
    userData?.payload?.is_admin &&
    location.pathname.startsWith("/admin/practice");
  useEffect(() => {
    const fetchPracticeSections = (qsetId) => {
      dispatch(
        isAdmin ? adminPracticeSections(qsetId) : practiceSections(qsetId)
      );
    };
    if (shouldRender.current) {
      shouldRender.current = false;
      dispatch(showLoginModalView(false));
      !qsid && fetchPracticeSections(qsetId);
    }
    if (answerSubmitted || saveAsDraftSuccess) {
      !qsid && fetchPracticeSections(qsetId);
    }
    return () => {
      dispatch(currentAccordion(null));
    };
  }, [answerSubmitted, saveAsDraftSuccess]);

  useEffect(() => {
    dispatch(setShouldShowAns(false));
    return () => {
      dispatch(resetFormData());
    };
  }, []);

  // Success and error message handle
  useEffect(() => {
    if (isCall) {
      errorMsg();
    }
  }, [alertSuccessMessage, alertErrorMessage]);

  const [isCall, setIsCall] = useState(false);
  const errorMsg = () => {
    if (!isEmpty(alertErrorMessage)) {
      if (!isEmpty(alertErrorMessage?.qset_id)) {
        setIsCall(false);
        toast.error(alertErrorMessage?.qset_id[0]);
      } else {
        setIsCall(false);
        toast.error(alertErrorMessage);
      }
    } else if (
      !isEmpty(alertSuccessMessage) &&
      alertSuccessMessage?.status_code === 200
    ) {
      setIsCall(false);
      toast.success(alertSuccessMessage.message);
    } else {
      setIsCall(false);
    }
  };

  const onSubmit = (values) => {
    for (let i = 0; i < values.list_sections.length; i++) {
      const arr = Object.values(values.list_sections[i].dict_qno_attempted);
      values.list_sections[i].noq_attempted = 0;
      values.list_sections[i].noq_total = arr.length;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j] === true) {
          values.list_sections[i].noq_attempted += 1;
        }
      }
    }
    setFinalSubmitModalShow(true);
    setFinalAnsTosubmit(values);
  };

  const handleClose = () => {
    setFinalSubmitModalShow(false);
  };

  const onSaveAsDraft = () => {
    const valuesForDraft = formik.values;
    for (let i = 0; i < valuesForDraft.list_sections.length; i++) {
      const arr = Object.values(
        valuesForDraft.list_sections[i].dict_qno_attempted
      );
      valuesForDraft.list_sections[i].noq_attempted = 0;
      valuesForDraft.list_sections[i].noq_total = arr.length;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j] === true) {
          valuesForDraft.list_sections[i].noq_attempted += 1;
        }
      }
    }
    dispatch(
      isAdmin
        ? adminTimedSaveAsDraft(valuesForDraft)
        : saveAsDraft(valuesForDraft)
    );
    dispatch(currentAccordion(currentActiveAccordion));
    setDraftMessageShow(true);
    setFinalSubmitModalShow(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit,
  });

  useEffect(() => {
    if (isEmpty(practiceSectionData)) return;

    formik.setFieldValue("qset_id", `${practiceSectionData.set_id}`);
    formik.setFieldValue("clicked_submit", null);

    for (let i = 0; i < practiceSectionData.list_sections.length; i++) {
      const noqAttempted = practiceSectionData.list_sections[i].noq_attempted;
      const noqTotal = practiceSectionData.list_sections[i].noq_total;

      formik.setFieldValue(`list_sections.${i}.noq_attempted`, 0);

      if (noqAttempted) {
        formik.setFieldValue(`list_sections.${i}.noq_attempted`, noqAttempted);
      }
      if (noqTotal) {
        formik.setFieldValue(`list_sections.${i}.noq_total`, noqTotal);
      }

      for (
        let j = 0;
        j < practiceSectionData.list_sections[i].list_qgs.length;
        j++
      ) {
        for (
          let k = 0;
          k < practiceSectionData.list_sections[i].list_qgs[j].list_ques.length;
          k++
        ) {
          const qno =
            practiceSectionData.list_sections[i].list_qgs[j].list_ques[k].qno;
          const dictQnoAttempted =
            practiceSectionData.list_sections[i].dict_qno_attempted;
          const listQues =
            practiceSectionData.list_sections[i].list_qgs[j].list_ques[k];

          formik.setFieldValue(
            `list_sections.${i}.dict_qno_attempted.${qno}`,
            false
          );

          // for questions after save as draft and submit
          if (
            (dictQnoAttempted && dictQnoAttempted[qno]) ||
            (has(listQues, "attempted_obj") && !isNull(listQues.attempted_obj))
          ) {
            formik.setFieldValue(
              `list_sections.${i}.dict_qno_attempted.${qno}`,
              true
            );
          }

          formik.setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.qno`,
            qno
          );
          formik.setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
            null
          );

          // for submitted Question
          if (has(listQues, "attempted_obj")) {
            formik.setFieldValue(
              `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
              listQues.attempted_obj
            );
          }
          // for Questions after save as a draft
          if (!isAdmin && practiceSectionData.draft.attempted) {
            formik.setFieldValue(
              `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
              listQues.draft.stud_ans
            );
          }
          formik.setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_type`,
            listQues.q_type
          );
          formik.setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.list_times`,
            []
          );
          formik.setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_id`,
            listQues.q_id
          );
        }
      }
    }
    setLoadingForResponse(false);
  }, [practiceSectionData]);

  useEffect(() => {
    const ansField = formik?.values?.list_sections?.map((list) =>
      list.list_ags?.[0]?.list_ans?.find((item) => item.q_id === flagQues.q_id)
    );
    dispatch(
      storeCurrentQuestionAns({
        ans_key: ansField?.[0]?.stud_ans ?? ansField?.[1]?.stud_ans,
      })
    );
    if (!isEmpty(practiceSectionErrorMessage) && isEmpty(practiceSectionData)) {
      setLoadingForResponse(false);
    }
  }, [practiceSectionErrorMessage, formik.values, flagQues]);

  const [answerFetched, setAnswerFetched] = useState(false);

  useEffect(() => {
    const qset_Id = localStorage.getItem("set-id");
    if (isAdmin && !qsid) {
      dispatch(getAdminTimedSaveAsDraftData(qset_Id))
        .unwrap()
        .then((res) => {
          const practiceSectionData = res.data;
          practiceSectionData?.list_sections?.map((section, i) => {
            formik.setFieldValue(
              `list_sections.${i}.noq_attempted`,
              section.noq_attempted || 0
            );
            formik.setFieldValue(
              `list_sections.${i}.noq_total`,
              section.noq_total || 0
            );

            section?.list_ags?.map((qg, j) => {
              qg?.list_ans?.map((ques, k) => {
                const qno = ques.qno;
                const dictQnoAttempted = section.dict_qno_attempted;
                formik.setFieldValue(
                  `list_sections.${i}.dict_qno_attempted.${qno}`,
                  dictQnoAttempted && dictQnoAttempted[qno] ? true : false
                );
                formik.setFieldValue(
                  `list_sections.${i}.list_ags.${j}.list_ans.${k}.qno`,
                  qno
                );
                formik.setFieldValue(
                  `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
                  ques.stud_ans || null
                );
                formik.setFieldValue(
                  `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_type`,
                  ques.q_type
                );
                formik.setFieldValue(
                  `list_sections.${i}.list_ags.${j}.list_ans.${k}.list_times`,
                  []
                );
                formik.setFieldValue(
                  `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_id`,
                  ques.q_id
                );
              });
            });
          });
          setAnswerFetched(true);
        })
        .catch((error) => {});
    }
  }, [isAdmin, dispatch, answerFetched, qsid]);

  const onEnd = () => {
    setId(null);
  };
  const { speak } = useSpeechSynthesis({ onEnd });

  const handleClick = (qType, questions, i, j, k) => {
    const changeClass = () => {
      speak({ text: questions.q_html });
      setId(k);
    };
    const active = () => {
      if (id === k) {
        return true;
      }
      return false;
    };
    const props = {};
    props.key = k;
    props.data = questions;
    props.indexValue = { i, j, k };
    props.value = !isEmpty(formik.values) && formik.values;
    props.onChange = formik.handleChange;
    props.active = active();
    props.onPress = changeClass;
    props.setFieldVal = formik.setFieldValue;
    props.baseUrl = baseUrlForSectionImg;
    props.fieldValue = getFieldVal(formik.values, i, j, k);
    props.name = `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`;

    switch (qType) {
      case "TextAnswerQ":
        return <TextAnswerQ {...props} />;
      case "DictationQ":
        return <DictationQ {...props} />;
      case "MathExpressionQ":
        return <MathExpressionQ {...props} />;
      case "MultipleChoiceQ":
        return <MultipleChoiceQ {...props} />;
      case "UniqueChoiceQ":
        return <UniqueChoiceQ {...props} />;
      case "TrueFalseQ":
        return <TrueFalseQ {...props} />;
      case "FillImgQ":
        return <FillImgQ {...props} />;
      case "FillBlankQ":
        return <FillBlankQ {...props} />;
      case "FillGroupQ":
        return <FillGroupQ {...props} />;
      case "FillInlineQ":
        return <FillInlineQ {...props} />;
      case "MatchItemsQ":
        return <MatchItemsQ {...props} />;
      case "MatrixMatchQ":
        return <MatrixMatchQ {...props} />;
      default:
        return "";
    }
  };

  const navigateToQuestion = (eve, e, section) => {
    if (currentActiveAccordion === section.sno) {
      eve.stopPropagation();
    }
    const ele = document.getElementById(`${e.q_type}_${e.qno}`);
    setTimeout(() => {
      window.scrollTo({
        top: ele.offsetTop - 250,
        behavior: "smooth",
      });
    }, 700);
  };

  const handleAttempted = (e, index) => {
    const attemptValue = formik.values?.list_sections[
      index
    ]?.list_ags?.[0]?.list_ans?.find((item) => item.qno === e?.qno);
    if (e?.ans_obj?.correctness === "right") {
      return "right";
    } else if (e?.ans_obj?.correctness === "wrong") {
      return "wrong";
    } else if (e?.attempted_obj && isArray(e?.ans_obj?.ugot_right)) {
      if (e?.ans_obj?.ugot_right?.every((e) => e === true)) {
        return "right";
      } else {
        return "wrong";
      }
    } else if (
      !isEmpty(formik.values) &&
      (formik.values?.list_sections[index]?.dict_qno_attempted[e?.qno] ===
        true ||
        attemptValue?.stud_ans !== null)
    ) {
      return "question_attempted";
    } else {
      return "";
    }
  };

  const handleActiveAccordion = (e) => {
    if (isNull(e)) setCurrentActiveAccordion(null);

    setCurrentActiveAccordion(e);
  };

  const isQuestionAttempted = () => {
    if (!practiceSectionData?.submitted?.attempted) return false;

    return true;
  };
  const isDraftAttempted = () => {
    if (!practiceSectionData?.draft?.attempted) return false;

    return true;
  };
  const isDraftAttemptedNotSubmitted = () => {
    if (
      practiceSectionData?.subscription_type !== "paid" ||
      practiceSectionData?.submitted?.attempted
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    dispatch(
      isAdmin
        ? adminPracticeSectionsSubmit(finalAnsTosubmit)
        : practiceSectionsSubmit(finalAnsTosubmit)
    );
    dispatch(currentAccordion(null));
    setIsCall(true);
    setFinalSubmitModalShow(false);
  };

  const modalFooterButtons = () => {
    if (!practiceSectionData?.submitted?.attempted && !isAdmin) {
      return [
        { label: "Save draft", variant: "info", onClick: onSaveAsDraft },
        { label: "Submit", variant: "primary", onClick: handleSubmit },
      ];
    }
    return [{ label: "Submit", variant: "primary", onClick: handleSubmit }];
  };

  const readMoreCloseModal = () => setReadMoreModal(false);
  const readMoreOpenModal = () => setReadMoreModal(true);

  const handleShowHideAnswers = (e) => {
    setShowHideAns(e.target.checked);
    dispatch(setShouldShowAns(e.target.checked));
  };

  const SectionMainPage = () => {
    if (loading || authLoading || loadingForResponse) {
      return (
        <div className="loader_section">
          <Loader />
        </div>
      );
    }

    if (
      typeof practiceSectionErrorMessage !== "undefined" &&
      !isEmpty(practiceSectionErrorMessage)
    ) {
      return (
        <ErrorHandleComponent errorMessage={practiceSectionErrorMessage} />
      );
    }

    if (isEmpty(practiceSectionData) && isEmpty(formik.values)) return;

    const readMoreModalTitle = "Description";
    const readMoreModalContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: practiceSectionData?.set_description,
        }}
      ></div>
    );
    const readMoreModalFooterButtons = [
      { label: "Close", variant: "danger", onClick: readMoreCloseModal },
    ];

    const handleLoginCloseModal = () => {
      dispatch(showLoginModalView(false));
    };
    const navigateLoginPage = () => {
      navigate("/login");
    };
    const modalTitle = userData.payload ? "Upgrade to Premium" : "Login";
    const modalContent = (
      <p>
        {userData.payload
          ? "This is for Premium users"
          : "You have to login first to flag this question!"}
      </p>
    );
    const modalFooterButtonsLogin = [
      { label: "Close", variant: "danger", onClick: handleLoginCloseModal },
      {
        label: userData.payload ? "upgrade to premium" : "Login",
        variant: "primary",
        onClick: navigateLoginPage,
      },
    ];

    return (
      <div>
        <ToastContainer />
        <section className="banner_section">
          <Container>
            {!isEmpty(practiceSectionData) && (
              <h1>{practiceSectionData.set_name}</h1>
            )}
            {!isEmpty(practiceSectionData) &&
              !isEmpty(practiceSectionData.set_description) && (
                <>
                  <p>
                    {practiceSectionData.set_description?.substring(0, 60) +
                      "..."}
                    {practiceSectionData.set_description?.length > 60 && (
                      <Button onClick={() => readMoreOpenModal(true)}>
                        read more
                      </Button>
                    )}
                  </p>
                </>
              )}
          </Container>
          <CustomModal
            size={"md"}
            title={readMoreModalTitle}
            content={readMoreModalContent}
            footerButtons={readMoreModalFooterButtons}
            show={readMoreModal}
            handleClose={readMoreCloseModal}
          />
        </section>
        <section className="breadcrumbs">
          <Container>
            <div className="breadcrumbs_wrapper">
              <ul>
                {" "}
                {/* <p className="mb-0">{list?.set_name_short}</p> */}
                <li>
                  <ReactSVG src={HomeIcon} className="icon" />
                  {isAdmin ? (
                    <Link to={"/admin/practice"}>Practiced</Link>
                  ) : (
                    <Link to={"/practice"}>Practice</Link>
                  )}
                </li>
                <li className="active">
                  <ReactSVG src={breadcrumbArrow} className="icon" />
                  {!isEmpty(practiceSectionData) &&
                    practiceSectionData.set_name}
                </li>
                {/* <li>
									<OverlayTrigger
										overlay={
											<Tooltip className='cs-tooltip'>			
												<div>
												<p className='mb-1'>{setStore?.type}</p>
												<strong>{setStore?.setname} </strong>
												</div>
				                            </Tooltip>
												}>
									<span className='text-dark'><img src={infoIcon} width={18} /></span>
									</OverlayTrigger>
									<span><Form.Check
														className='text-start check-cs'
														reverse
														label=""
														name="group1"
														type="checkbox"
														checked={closetimer}
														onChange={()=>setClosetimer(!closetimer)}
													/></span>
									<small className='text-gray text-12px d-block'>Show time remaining</small>
								</li> */}
              </ul>
              {isQuestionAttempted() && (
                <div style={{ display: "flex" }}>
                  <Form.Check inline={true}>
                    <Form.Check.Input
                      type="checkbox"
                      id="show_hide_ans"
                      name="show_hide_ans"
                      onChange={handleShowHideAnswers}
                      value={showHideAns}
                      checked={showHideAns}
                    />
                    <Form.Check.Label htmlFor="show_hide_ans">
                      Show the answers/explanations
                    </Form.Check.Label>
                  </Form.Check>
                  <p className="total_score">
                    Total Score:{" "}
                    {practiceSectionData?.submitted?.data?.total_score_pct} %
                  </p>
                </div>
              )}
            </div>
          </Container>
        </section>
        <section className="sections_list">
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Accordion
                defaultActiveKey={
                  !isNull(activeAccordion) ? activeAccordion : "1"
                }
                onSelect={(e) => handleActiveAccordion(e)}
              >
                {!isEmpty(practiceSectionData) &&
                  practiceSectionData.list_sections.map((section, i) => (
                    <AccordionItem
                      key={section.sno}
                      section={section}
                      index={i}
                      handleAttempted={handleAttempted}
                      navigateToQuestion={navigateToQuestion}
                      data={practiceSectionData}
                      LockIcon={LockIcon}
                      currentActiveAccordion={currentActiveAccordion}
                      handleClick={handleClick}
                      setFlagQuestion={setFlagQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                    />
                  ))}
              </Accordion>
              <CustomModal
                title="Are you sure?"
                content={
                  <p>After submitting you cannot edit this practice set.</p>
                }
                footerButtons={modalFooterButtons()}
                show={finalSubmitModalShow}
                handleClose={handleClose}
              />
              <CustomModal
                content={
                  alert
                    ? alertSuccessMessage?.message ||
                      timedSaveAsDraftResponse?.message
                    : alertErrorMessage
                }
                show={draftMessageShow}
                handleClose={() => {
                  setDraftMessageShow(false);
                  isAdmin ? navigate("/admin/practice") : navigate("/practice");
                }}
              />
              {!isQuestionAttempted() && (
                <HandleButtonComponent
                  data={practiceSectionData}
                  onSaveAsDraft={onSaveAsDraft}
                  LockIcon={LockIcon}
                />
              )}
              {isQuestionAttempted() && (
                <p className="after_submit_note">This set is already graded.</p>
              )}
            </form>

            <FlaggedQuestionModal
              flagQuestion={flagQuestionModal}
              //setFlagQuestion={setFlagQuestion}
            />
            <CustomModal
              size={"md"}
              title={modalTitle}
              content={modalContent}
              footerButtons={modalFooterButtonsLogin}
              show={showLoginModal}
              handleClose={handleLoginCloseModal}
            />

            <div className="last_submission_time">
              {isDraftAttempted() && (
                <p>
                  {" "}
                  Last draft saved:{" "}
                  {practiceSectionData?.draft?.last_draft_saved_time}{" "}
                </p>
              )}
              {isDraftAttemptedNotSubmitted() && (
                <p>
                  Deadline to submit for grading:{" "}
                  <span>Sunday at noon (US PT)</span>
                </p>
              )}
            </div>
          </Container>
        </section>
      </div>
    );
  };

  const handleSectionPageClass = () => {
    if (
      typeof practiceSectionErrorMessage !== "undefined" &&
      !isEmpty(practiceSectionErrorMessage)
    ) {
      return "section-error";
    }

    return "sections_page";
  };

  return <div className={handleSectionPageClass()}>{SectionMainPage()}</div>;
};

export default SectionsPage;
