import React, { memo } from 'react';
import { isArray } from 'lodash';
import './styles.scss';
import useShowHideAns from '../../../hooks/useShowHideAns';

const RightAnswereComponent = ({ data, index }) => {
	if (!useShowHideAns()) return;
	if (
		!data.ans_obj ||
		data.ans_obj.ugot_right === true ||
		data.ans_obj.ugot_right[index]
	) { return; }

	if (isArray(data.ans_obj.ans_key)) {
		return <p className='answere'>{data.ans_obj.ans_key[index]}</p>;
	}

	return <p className='answere'>{data.ans_obj.ans_key}</p>;
};

export default memo(RightAnswereComponent);
