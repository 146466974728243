/* eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import {
	getUserProfile,
	updateUser,
	changePassword,
	currentSubscriptionPlane,
	userScoreHistory,
    updateNotificationValues
} from '../actions';

export const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState: {
		loading: false,
		userData: {},
		updateUserData: {},
		currentSubscriptionData: {},
		changePasswordResponse: '',
		error: '',
		getUserProfileError: '',
		updateProfileError: {},
		userLastWeekScore: {},
		notificationUpdate:{}
	},
	reducers: {
		reset: (state) => {
			return { ...state, updateProfileError: {}, updateUserData: {} };
		}
	},
	extraReducers: {
		[getUserProfile.pending]: (state) => {
			return { ...state, loading: true, getUserProfileError: '' };
		},
		[getUserProfile.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				userData: payload
			};
		},
		[getUserProfile.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				getUserProfileError: payload
			};
		},
		[updateUser.pending]: (state) => {
			return { ...state, loading: true };
		},
		[updateUser.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				updateUserData: payload,
				updateProfileError: {}
			};
		},
		[updateUser.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				updateProfileError: payload
			};
		},
		[changePassword.pending]: (state) => {
			return { ...state, loading: true, error: '' };
		},
		[changePassword.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				changePasswordResponse: payload
			};
		},
		[changePassword.rejected]: (state, { payload }) => {
			return {
				...state, loading: false, error: payload
			};
		},
		[currentSubscriptionPlane.pending]: (state) => {
			return { ...state, loading: true };
		},
		[currentSubscriptionPlane.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				currentSubscriptionData: payload,
				error: ''
			};
		},
		[currentSubscriptionPlane.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				currentSubscriptionData: payload
			};
		},
		[userScoreHistory.pending]: (state) => {
			return { ...state, loading: true };
		},
		[userScoreHistory.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				error: '',
				userLastWeekScore: payload
			};
		},
		[userScoreHistory.rejected]: (state, { payload }) => {
			return {
				...state, loading: false
			};
		},

		[updateNotificationValues.pending]: (state) => {
			return { ...state, loading: true };
		},
		[updateNotificationValues.fulfilled]: (state, { payload }) => {
			return {
				notificationUpdate: payload
			};
		},
		[updateNotificationValues.rejected]: (state, { payload }) => {
			return {
				...state, loading: false
			};
		}
	}
});
export const { reset } = userProfileSlice.actions;
export default userProfileSlice.reducer;
