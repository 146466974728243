import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// Components
import FormikErrorComponent from '../../components/AuthComponents/FormikErrorComponent';
import ErrorHandleComponent from '../../components/ErrorHandleComponent';
// Constants
import {
	initialValuesContact,
	contactSchema
} from '../../constants/Authentication';
// Actions and Slices
import { contactEmail } from '../../redux/actions';
import { reset } from '../../redux/slices/authSlice';
// Image
import conatactImg from '../../assets/images/contact-us-image.png';
import './styles.scss';
import CustomModal from '../../components/CustomModal';
import Loader from '../../components/Loader';
import useAutoCompleteFocus from '../../components/useAutoCompleteFocus';

const ContactPage = () => {
	const dispatch = useDispatch();
	const [subject, setSubject] = useState('');
	const [contactSuccess, setContactSuccess] = useState(false);
	const [selectHasValue, setSelectHasValue] = useState(false);
	const { loading, contactEmailErrorMessage, contactEmailSuccessMessage } =
		useSelector((state) => state.auth);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (contactEmailSuccessMessage) {
			setContactSuccess(true);
		}
	}, [contactEmailSuccessMessage]);

	const handleContactSuccessClose = () => {
		formik.resetForm();
		setSubject(() => '');
		setContactSuccess(false);
		dispatch(reset());
	};

	const onSubmit = (values) => {
		const formData = new FormData();
		formData.append('name', values.name);
		formData.append('email', values.email);
		formData.append('subject', subject);
		formData.append('message', values.message);
		dispatch(contactEmail(formData));
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValuesContact,
		validationSchema: contactSchema,
		onSubmit
	});

	const options = [
		{ id: 'Interested in collaborating', name: 'Interested in collaborating' },
		{ id: 'Issue with login / sign-up', name: 'Issue with login / sign-up' },
		{ id: 'Issue with payment / billing', name: 'Issue with payment / billing' },
		{
			id: 'Reporting a bug - practice questions',
			name: 'Reporting a bug - practice questions'
		},
		{
			id: 'Reporting a bug - practice grading',
			name: 'Reporting a bug - practice grading'
		},
		{
			id: 'Reporting a bug - user dashboard',
			name: 'Reporting a bug - user dashboard'
		},
		{ id: 'Reporting a bug - website', name: 'Reporting a bug - website' },
		{ id: 'Submitting a testimonial', name: 'Submitting a testimonial' }
	];

	const handleOnSearch = (string) => {
		setSubject(string);
		formik.setFieldValue('subject', string);
	};

	const handleOnSelect = (item) => {
		setSubject(item.name);
		formik.setFieldValue('subject', item.name);
	};

	const formatResult = (item) => {
		return (
			<>
				<span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
			</>
		);
	};

	const modalTitle = 'We got your request!';
	const modalContent = (
		<p>Thanks for contacting us! We will be in touch with you shortly.</p>
	);
	const modalFooterButtons = [
		{ label: 'Close', variant: 'danger', onClick: handleContactSuccessClose }
	];

	const parentRef = useRef(null);
	const { isFocused } = useAutoCompleteFocus({
		parentRef,
		onBlurred: () => {
			const inputValue = parentRef.current.querySelector('input').value;
			if (inputValue) {
				setSelectHasValue(true);
			} else {
				setSelectHasValue(false);
			}
		}
	});
	return (
		<div className='contact_page'>
			<section className='inner_banner_section'>
				<Container>
					<h1>Contact Us</h1>
				</Container>
			</section>
			<div className='contact_page_container'>
				<section className='contact_form_section'>
					<Container>
						<div className='contact_form_banner_section'>
							<div className='contact_banner'>
								<h2>Email</h2>
								<p>
									The best way to reach us is via email:{' '}
									<a href='mailto: info@boo9.com'>click here</a> <br />
									Or, just use the contact form.
								</p>
								<img src={conatactImg} />
							</div>
							<div className='contact_form_wrapper'>
								<h2>Contact form</h2>
								<form
									className='contact_form'
									autoComplete='off'
									onSubmit={formik.handleSubmit}
								>
									<div className='contact_form_group'>
										<div className='contact_form_group_item'>
											<input
												id='name'
												placeholder='Your name'
												className='input_field user-field'
												type='text'
												value={formik.values.name}
												onChange={formik.handleChange}
											/>
											<label className='input_label'>Your name</label>
											<FormikErrorComponent
												errorName={formik.errors.name}
												touched={formik.touched.name}
											/>
										</div>
										<div className='contact_form_group_item'>
											<input
												id='email'
												type='text'
												className='input_field email-field'
												placeholder='Email'
												value={formik.values.email}
												onChange={formik.handleChange}
											/>
											<label className='input_label'>Email</label>
											<FormikErrorComponent
												errorName={formik.errors.email}
												touched={formik.touched.email}
											/>
										</div>
									</div>
									<div className='contact_form_select'>
										<div
											className={`${isFocused || selectHasValue
												? 'contact_form_select_input contact_form_select_focus'
												: 'contact_form_select_input'}`}>
											<label>Subject</label>
											<div ref={parentRef}>
												<ReactSearchAutocomplete
													items={options}
													onSearch={handleOnSearch}
													onSelect={handleOnSelect}
													formatResult={formatResult}
													showIcon={false}
													inputSearchString={subject}
													className="hello"
												/>
											</div>
										</div>
										<FormikErrorComponent
											errorName={formik.errors.subject}
											touched={formik.touched.subject}
										/>
									</div>
									<div className='contact_form_group_item'>
										<textarea
											id='message'
											className='message-field'
											placeholder='Your Message'
											rows='4'
											cols='50'
											value={formik.values.message}
											onChange={formik.handleChange}
										></textarea>
										<label className='input_label'>Your message</label>
										<FormikErrorComponent
											errorName={formik.errors.message}
											touched={formik.touched.message}
										/>
									</div>
									{!isEmpty(contactEmailErrorMessage) && (
										<ErrorHandleComponent errorMessage={contactEmailErrorMessage} />
									)}
									<div className='primary-btn'>
										<button className='btn btn-primary' type='submit' disabled={loading}>
											{loading ? <Loader /> : 'Submit'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</Container>
				</section>
				<section className='town_section'>
					<Container>
						<div className='town_section_wrapper'>
							<h2>Are you in town?</h2>
							<div className='map_notice'>
								<p>
									If you would like to meet in-person, please message Tikka via{' '}
									<a
										href='https://www.linkedin.com/in/tikeswar/'
										target='_blank'
										rel='noreferrer'
									>
										LinkedIn
									</a>
									. We are located in San Jose, CA.
								</p>
							</div>
						</div>
					</Container>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202844.3766950822!2d-122.181182009794!3d37.40299366529422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb68ad0cfc739%3A0x7eb356b66bd4b50e!2sSilicon%20Valley%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1673332332353!5m2!1sen!2sin'
						allowFullScreen=''
						loading='lazy'
						referrerPolicy='no-referrer-when-downgrade'
					></iframe>
				</section>
			</div>
			<CustomModal
				title={modalTitle}
				content={modalContent}
				footerButtons={modalFooterButtons}
				show={contactSuccess}
				handleClose={handleContactSuccessClose}
			/>
		</div>
	);
};

export default ContactPage;
