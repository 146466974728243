/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import questionData from './questions.json';
import VideoExplanationComponent from '../../../../components/VideoExplanationComponent';

const ResourcesMathK12G3Set001B = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Practice Problems: Math Grade 3</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    {
                        questionData.map((qn, i) => {
                            return <div className='question_section'>
                            <p className='question_value'>{i+1}. {qn.question}</p>
                            <p className="key_value">
                                <span className='field_name'>Question: </span>
                                <span className='field_value'>                        
                                    <img src={qn.question_image} alt='question image'/>
                                </span>
                            </p>
                            {/* <p className="key_value">
                            {qn.video_link ? <VideoExplanationComponent videoLink={qn.video_link}></VideoExplanationComponent> :                             
                            <p className="key_value">
                                <span className='field_name'>Answer key: </span>
                                <span className='field_value'>                        
                                    {qn.answer}
                                </span>
                            </p>}
                            </p> */}
                        </div>;
                        })
                    }
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathK12G3Set001B;
