/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesMathK12 = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Resources: Math K-12</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h3>Practice Problems</h3>
                    <table>
                        <tr>
                            <td>Grade 3</td>
                            <td><Link to='/resources/mk12/g3-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/mk12/g3-set001B'>Set 2</Link></td>
                            <td><Link to='/resources/mk12/g3-set001C'>Set 3</Link></td>
                        </tr>
                        <tr>
                            <td>Grade 4</td>
                            <td><Link to='/resources/mk12/g4-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/mk12/g4-set001B'>Set 2</Link></td>
                            <td><Link to='/resources/mk12/g4-set001C'>Set 3</Link></td>
                        </tr>
                        <tr>
                            <td>Grade 5</td>
                            <td><Link to='/resources/mk12/g5-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/mk12/g5-set001B'>Set 2</Link></td>
                        </tr>
                        <tr>
                            <td>Grade 6</td>
                            <td><Link to='/resources/mk12/g6-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/mk12/g6-set001B'>Set 2</Link></td>
                        </tr>
                        <tr>
                            <td>Grade 7</td>
                            <td><Link to='/resources/mk12/g7-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/mk12/g7-set001B'>Set 2</Link></td>
                            <td><Link to='/resources/mk12/g7-set001C'>Set 3</Link></td>
                        </tr>
                    </table> 
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathK12;
