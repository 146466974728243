import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import graphIcon from '../../assets/images/graph-up.svg';
import userIcon from '../../assets/images/person-fill-gear.svg';
import logOutIcon from '../../assets/images/box-arrow-right.svg';
import settingsIcon from '../../assets/images/settings2.svg';
const NavbarDropDown = ({ setExpanded, handleClick }) => {
	const { isLoggedIn, userData } = useSelector((state) => state.auth);
	if (!isLoggedIn) {
		return (
			<NavDropdown
				title='Account'
				id='basic-nav-dropdown'
				className='nav-dropdown'
			>
				<NavLink
					to='/login'
					className='nav-link'
					onClick={() => setExpanded(false)}
				>
          Log in
				</NavLink>
				<NavLink
					to='/signup'
					className='nav-link'
					onClick={() => setExpanded(false)}
				>
          Sign up
				</NavLink>
			</NavDropdown>
		);
	}

	return (
		<NavDropdown
			title={`${userData?.payload?.first_name} ${userData?.payload?.last_name}`}
			id='basic-nav-dropdown'
			className='nav-dropdown'
		>
			<NavLink to='/dashboard' className='nav-link' onClick={() => setExpanded(false)}>
				<img className='nav-icon' src={graphIcon} />Dashboard
			</NavLink>
			<NavLink to='/profile' className='nav-link' onClick={() => setExpanded(false)}>
				<img className='nav-icon' src={userIcon} />Profile
			</NavLink>
			<NavLink to='/settings' className='nav-link' onClick={() => setExpanded(false)}>
				<img className='nav-icon' src={settingsIcon} />Settings
			</NavLink>
			<button type='button' className='nav-link' onClick={handleClick}>
				<img className='nav-icon' src={logOutIcon} />Log out
			</button>
		</NavDropdown>
	);
};

export default NavbarDropDown;
