import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import userIcon from '../../../assets/images/user.png';
import emailIcon from '../../../assets/images/email.png';
import passwordIcon from '../../../assets/images/lock.png';
import otpIcon from '../../../assets/images/otp.png';

const FloatinglabelComponent = ({
	formikTouched,
	formikErrors,
	formikValues,
	onChange,
	label,
	id,
	type,
	placeholder
}) => {
	const imageIcon = () => {
		switch (id) {
			case 'email':
				return emailIcon;
			case 'password':
				return passwordIcon;
			case 'otp' :
				return otpIcon;
			default:
				return userIcon;
		}
	};
	return (
		<FloatingLabel
			label={label}
			htmlFor={id}
			className={formikTouched && formikErrors ? 'error' : null}
		>
			<span className='icon'>
				<img src={imageIcon()} />
			</span>
			<Form.Control
				type={type}
				id={id}
				value={formikValues}
				onChange={onChange}
				placeholder={id === 'username' ? placeholder : label}
			/>
		</FloatingLabel>
	);
};

export default FloatinglabelComponent;
