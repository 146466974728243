import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import contentImage from '../../../assets/images/forgot_password.svg';
import LeftArrow from '../../../assets/images/white-left-arrow.png';
import { forgotPassword } from '../../../redux/actions';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import AuthCarousel from '../../../components/AuthComponents/AuthCarousel';
import FloatinglabelComponent from '../../../components/AuthComponents/FloatinglabelComponent';
import Loader from '../../../components/Loader';
import FormikErrorComponent from '../../../components/AuthComponents/FormikErrorComponent';
import { reset } from '../../../redux/slices/authSlice';
import {
	initialValuesForgotPassword,
	forgotPasswordSchema
} from '../../../constants/Authentication';
import './styles.scss';
import CustomModal from '../../../components/CustomModal';

const ForgotPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [forgotSuccess, setForgotSuccess] = useState(false);
	const { loading, forgotPaswordSuccessMessage, forgotPaswordErrorMessage } =
		useSelector((state) => state.auth);

	useEffect(() => {
		if (forgotPaswordSuccessMessage) {
			setForgotSuccess(true);
		}
		return () => {
			dispatch(reset());
		};
	}, [forgotPaswordSuccessMessage]);

	const handleForgotSuccessClose = () => {
		navigate('/login');
	};

	const onSubmit = () => {
		const formData = new FormData();
		formData.append('email', formik.values.email);
		dispatch(forgotPassword(formData));
		formik.resetForm();
	};
	const formik = useFormik({
		initialValues: initialValuesForgotPassword,
		validationSchema: forgotPasswordSchema,
		onSubmit
	});

	const modalTitle = 'We got your request!';
	const modalContent = <p>If the information you provided matches our records, an email
		will be sent to you containing your username and a password reset link. If you still
		require assistance, please don't hesitate to <Link to='/contact'>contact us</Link>.</p>;
	const modalFooterButtons = [
		{ label: 'Close', variant: 'danger', onClick: handleForgotSuccessClose }
	];

	return (
		<div className='forgot_page'>
			{loading && (
				<div className='forgot_loader'>
					<Loader />
				</div>
			)}
			<CustomModal
				title={modalTitle}
				content={modalContent}
				footerButtons={modalFooterButtons}
				show={forgotSuccess}
				handleClose={handleForgotSuccessClose}
			/>
			<AuthCarousel contentImage={contentImage} />
			<div className='forgot_form'>
				<div className='forgot_form_wrapper'>
					<div className='left_arrow'>
						<img src={LeftArrow} />
					</div>
					<h2>Forgot Password?</h2>
					<p>Please complete the form below. If it matches our records, we will send you an
						email. If you continue to experience any issues,
						please <Link to='/contact'>reach out to us.</Link></p>
					<Form className='forgot_form_handle' onSubmit={formik.handleSubmit}>
						<div className='row'>
							<div className="main-form-floating">
								<FloatinglabelComponent
									formikTouched={formik.touched.firstname}
									formikErrors={formik.errors.firstname}
									formikValues={formik.values.firstname}
									onChange={formik.handleChange}
									label='First name'
									id='firstname'
									type='text'
								/>
								<FormikErrorComponent
									errorName={formik.errors.firstname}
									touched={formik.touched.firstname}
								/>
							</div>
							<div className="main-form-floating">
								<FloatinglabelComponent
									formikTouched={formik.touched.lastname}
									formikErrors={formik.errors.lastname}
									formikValues={formik.values.lastname}
									onChange={formik.handleChange}
									label='Last name'
									id='lastname'
									type='text'
								/>
								<FormikErrorComponent
									errorName={formik.errors.lastname}
									touched={formik.touched.lastname}
								/>
							</div>
						</div>
						<div className="main-form-floating">
							<FloatinglabelComponent
								formikTouched={formik.touched.email}
								formikErrors={formik.errors.email}
								formikValues={formik.values.email}
								onChange={formik.handleChange}
								label='Email'
								id='email'
								type='email'
							/>
							<FormikErrorComponent
								errorName={formik.errors.email}
								touched={formik.touched.email}
							/>
						</div>
						<Button variant='primary' type='submit' disabled={loading}>  Email me </Button>
					</Form>
					{!isEmpty(forgotPaswordErrorMessage) && (
						<ErrorHandleComponent errorMessage={forgotPaswordErrorMessage} />
					)}
					<span className='signup'>
						Do not have an account?{' '}
						<b>
							<Link to='/signup'>Sign up</Link>
						</b>
					</span>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
