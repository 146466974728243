import React from 'react';
import { PublicRoute } from '../../router/publicRoute';
import { ProtectedRoute } from '../../router/protectedRoute';
import { GeneralRoute } from '../../router/generalRoute';

const CustomRoute = (props) => {
	if (props.routeType === 'protected') {
		return (
			<ProtectedRoute isTimed={props.isTimed} isAdminRoute={props.isAdminRoute}>
				<props.components />
			</ProtectedRoute>
		);
	}
	if (props.routeType === 'public') {
		return (
			<PublicRoute>
				<props.components />
			</PublicRoute>
		);
	}
	return (
		<GeneralRoute>
			<props.components />
		</GeneralRoute>
	);
};

export default CustomRoute;
