import React, { memo } from 'react';

const TableHeadComponent = ({ data }) => {
	if (!data.tooltip_data) return <th>Products & Services</th>;

	return (
		<th>
			<div className='label'>
				{data.head}
				<span>{data.tooltip_data}</span>
			</div>
		</th>
	);
};

export default memo(TableHeadComponent);
