/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { Form as BootstrapForm } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmSaveFlaggedQuestion,
  hidelFaggedQuestionModal,
  saveFlaggedQuestion,
} from "../../../../redux/actions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  image_not_showing_well: Yup.boolean(),
  math_symbol_not_showing_correctly: Yup.boolean(),
  question_is_not_showing_well: Yup.boolean(),
  question_has_a_typo: Yup.boolean(),
  question_is_confusing: Yup.boolean(),
  question_seems_wrong: Yup.boolean(),
  i_have_a_suggestion_for_this_question: Yup.boolean(),
  other_issues: Yup.boolean(),
  comment: Yup.string(),
});

const flagArray = [
  { label: "Question has a typo", value: "question_has_a_typo" },
  { label: "Image(s) not showing well", value: "image_not_showing_well" },
  {
    label: "Math symbol(s) not showing correctly",
    value: "math_symbol_not_showing_correctly",
  },
  {
    label: "Question/choice(s) not showing well",
    value: "question_is_not_showing_well",
  },
  { label: "Question seems wrong", value: "question_seems_wrong" },
  { label: "Question is confusing", value: "question_is_confusing" },
  { label: "Question seems incomplete", value: "question_seems_incomplete" },
  {
    label: "I have a suggestion for this question",
    value: "i_have_a_suggestion_for_this_question",
  },
  { label: "Other issues", value: "other_issues" },
];

const initialValues = {
  image_not_showing_well: false,
  math_symbol_not_showing_correctly: false,
  question_is_not_showing_well: false,
  question_has_a_typo: false,
  question_is_confusing: false,
  question_seems_wrong: false,
  question_seems_incomplete: false,
  i_have_a_suggestion_for_this_question: false,
  other_issues: false,
  comment: "",
};

const FlaggedStartQuestionModal = ({ setStillwantFlag }) => {
  const { flaggedQuestion, currentQuestion, currentQuestionAns } = useSelector(
    (state) => state.practiceSet
  );

  const dispatch = useDispatch();
  const onSubmit = (data, { resetForm }) => {
    const obJData = {
      q_id: currentQuestion?.q_id,
      q: currentQuestion,
      flag_timestamp: new Date(),
      flag: data,
      ans_obj: currentQuestion.ans_obj
        ? currentQuestion?.ans_obj
        : currentQuestionAns,
    };
    if (flaggedQuestion.existing_flag === false) {
      dispatch(saveFlaggedQuestion(obJData))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(hidelFaggedQuestionModal(false));
          setStillwantFlag(false);
          resetForm();
        });
    } else {
      dispatch(
        confirmSaveFlaggedQuestion({
          ...obJData,
          resolved: flaggedQuestion?.existing_flag?.resolved,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(hidelFaggedQuestionModal(false));
          setStillwantFlag(false);
          resetForm();
        });
    }
  };
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => {
          return (
            <Container>
              <Form onSubmit={handleSubmit}>
                {flagArray &&
                  flagArray.map((item, index) => (
                    <div className="mb-3" key={index}>
                      <Field
                        type="checkbox"
                        id={item.value}
                        name={item.value}
                        className="form-check-input me-2"
                        onChange={handleChange}
                      />
                      <label htmlFor={item.value} className="form-check-label">
                        {item.label}
                      </label>
                    </div>
                  ))}

                <BootstrapForm.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <BootstrapForm.Label>
                    Please provide more detail
                  </BootstrapForm.Label>
                  <Field
                    as="textarea"
                    rows={3}
                    name="comment"
                    className="form-control"
                  />
                </BootstrapForm.Group>

                <div className="footer-btn">
                  <Button
                    type="button"
                    className="btn"
                    variant="outline-secondary"
                    onClick={() => {
                      dispatch(hidelFaggedQuestionModal(false));
                      setStillwantFlag(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      !values.image_not_showing_well &&
                      !values.math_symbol_not_showing_correctly &&
                      !values.question_is_not_showing_well &&
                      !values.question_has_a_typo &&
                      !values.question_is_confusing &&
                      !values.question_seems_wrong &&
                      !values.i_have_a_suggestion_for_this_question &&
                      !values.other_issues &&
                      !values?.comment?.trim()
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </Container>
  );
};

export default FlaggedStartQuestionModal;
