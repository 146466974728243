import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import checkIcon from '../../../assets/images/check.png';
import closeIcon from '../../../assets/images/close.png';
import './styles.scss';

const AlertComponent = ({ handleClick, show, message }) => {
	if (!message) return;
	if (message.length === 0) return;
	return (
		<div className='alert_message'>
			<Alert show={show} variant='success'>
				<img className='check-icon' src={checkIcon} />
				<p>{message}</p>
				<div className='close-icon'>
					<Button onClick={handleClick} variant='outline-success'>
						<img src={closeIcon} />
					</Button>
				</div>
			</Alert>
		</div>
	);
};

export default AlertComponent;
