/* eslint-disable */
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
// import QuestionHeadComponent from '../QuestionHeadComponent';
import RightAnswereComponent from "../RightAnswereComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import {
  questionCorrectnessType1,
  attemptedQuestionCounterType1,
  correctQuestionVal,
  handleChange,
} from "../../../utils/PracticePage";
import "./styles.scss";
import Markdown from "../../Markdown";
import AnsMarkingComponent from "../AnsMarkingComponent";
import CommonFlag from "../CommonFlag";

const TextAnswerQ = ({
  data,
  value,
  indexValue,
  fieldValue,
  name,
  setFieldVal,
}) => {
  useEffect(() => {
    attemptedQuestionCounterType1(data, value, indexValue, fieldValue);
    correctQuestionVal(fieldValue, setFieldVal, name);
  }, [value]);

  const generateHTMLContent = (ctext) => {
    return <Markdown>{ctext}</Markdown>;
  };

  return (
    <div
      className="faq_base_display_componet"
      id={`${data.q_type}_${data.qno}`}
    >
      <div className="question_number">
        <h6>{data.qno}</h6>
        <QuestionScoreComponent data={data} />
        <QuestionHintComponent hints={data.hints} />
        <QueExplanationComponent data={data} explanations={data.explanations} />
        <AnsMarkingComponent data={data} />
        <CommonFlag data={data} />
      </div>
      <div className="faq_base_display_componet_text">
        {/* <QuestionHeadComponent
					data={data}
					questionData={data.q_text}
					questionHintData={data.hints}
					headText={data.q_html}
					questionType={data.q_type}
				/> */}
        <h4>{generateHTMLContent(data.q_html)}</h4>
        <div className="input-tooltip">
          <Form.Control
            type="text"
            name={name}
            onChange={(e) => {
              handleChange(e, setFieldVal, name);
            }}
            value={fieldValue || ""}
            disabled={data.ans_obj}
            className={data.ans_obj && questionCorrectnessType1(data)}
          />
          <RightAnswereComponent data={data} />
        </div>
      </div>
    </div>
  );
};

export default TextAnswerQ;
