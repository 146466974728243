/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesSQL = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Resources: SQL</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <p>
                        <h4>SQL Notes (eBook)</h4>
                    </p>
                    <p>
                        <b>Download pdf:</b> <a href="https://github.com/tikeswar/public/blob/main/sql/sql_notes.pdf?raw=true" target="_blank">click here</a><br />
                        The PDF is hosted on GitHub. If there is any issue downloading please <Link to='/contact'>let us know</Link>.
                    </p>
                    <p>
                        <b>About the book:</b><br />
                        Starting from the very basics, it covers enough material to make you feel confident and hit the ground running.
                        After this, you can learn any advanced materials on the go.
                    </p>

                    <p>
                        <b>Table of Contents:</b><br />
                        Introduction<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Introduction<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Installation notes (Version)<br />
                        Database table<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Conventions<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;DATABASE commands<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;TABLE commands (Scriptfile)<br />
                        CRUD<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;CRUD (Create; Read; Update and Delete; CRUD syntax tips)<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Datatypes<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Practiceproblems - CRUD ***<br />
                        Refining selections<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Functions (Numeric functions; Aggregate functions; String functions; Date functions; Other functions)<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Operators<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Branching<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Grouping (GROUP BY ... HAVING; Window functions - OVER; GROUP BY vs Window functions)<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Refining selections<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Practice problems - refining selections ***<br />
                        Joins<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Relationships and joins (Joins; Relationships)<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Practice problems - relationships and joins ***<br />
                        Resources<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Further learning resources<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Solutions to practice problems<br />
                    </p>
                </Container>
            </div>
		</div>
	);
};

export default ResourcesSQL;
