import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paymentVarify } from '../../../redux/actions';
import Loader from '../../../components/Loader';
import { Button } from 'react-bootstrap';
import successImage from '../../../assets/images/signup.svg';
import './styles.scss';

const PaymentSuccessPage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sessionId = new URLSearchParams(location.search).get('session_id');
	const { loading, paymentVarificationError, paymentVarificationSuccessData } =
		useSelector((state) => state.payment);
	const authloading = useSelector((state) => state.auth.loading);
	const { isLoggedIn } = useSelector((state) => state.auth);

	useEffect(() => {
		if (isLoggedIn) {
			const values = new FormData();
			values.append('session_id', sessionId);
			dispatch(paymentVarify(values));
		}
	}, [isLoggedIn]);

	const paymentStatusMainpage = () => {
		if (loading || authloading) {
			return (
				<div className='loader_section'>
					<Loader />
				</div>
			);
		} else {
			return (
				<>
					<ReactSVG src={successImage} className='success_image' />
					<div className='description'>
						<p>
							{paymentVarificationSuccessData.message
								? paymentVarificationSuccessData.message
								: paymentVarificationError}
						</p>
					</div>
					<div className='back-btn'>
						<Button variant='primary' onClick={() => navigate('/practice')}>
							Back to Practice
						</Button>
					</div>
				</>
			);
		}
	};

	return (
		<div className='container'>
			<div className='success-page'>{paymentStatusMainpage()}</div>
		</div>
	);
};

export default PaymentSuccessPage;
