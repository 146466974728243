/* eslint-disable */
import React, { useEffect } from "react";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import RightAnswereComponent from "../RightAnswereComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import {
  questionCorrectnessType1,
  attemptedQuestionCounterType3,
  // correctQuestionValType2New,
  correctQuestionValType2,
  handleChange,
} from "../../../utils/PracticePage";
import "./styles.scss";
import AnsMarkingComponent from "../AnsMarkingComponent";
import CommonFlag from "../CommonFlag";

const FillGroupQ = ({
  data,
  value,
  indexValue,
  setFieldVal,
  fieldValue,
  name,
}) => {
  useEffect(() => {
    correctQuestionValType2(data, fieldValue, setFieldVal, name);
    // correctQuestionValType2New(data, fieldValue, setFieldVal, name, value, indexValue, data.list_qhtml)
    attemptedQuestionCounterType3(
      data,
      value,
      indexValue,
      fieldValue,
      data.list_qhtml
    );
  }, [value]);

  return (
    <div className="fig_simple_display_group" id={`${data.q_type}_${data.qno}`}>
      <div className="fig_simple_display">
        <div className="fig_question_display">
          <div className="question_number">
            <h6>{data.qno}</h6>
            <QuestionScoreComponent data={data} />
            <QuestionHintComponent hints={data.hints} />
            <QueExplanationComponent
              data={data}
              explanations={data.explanations}
            />
            <AnsMarkingComponent data={data} />
            <CommonFlag data={data} />
          </div>
          <ol className="fig_question_list">
            <div className="fig_list_worps">
              {data.list_worps.map((worps, index) => (
                <span key={index}>{worps}</span>
              ))}
            </div>
            {data.list_qhtml.map((ques, index) => {
              const string = ques.split("<input type='text' />");
              return (
                <li key={index}>
                  <div className="string">
                    {string[0]}
                    <input
                      type="text"
                      name={name + `.${index}`}
                      onChange={(e) => {
                        handleChange(e, setFieldVal, name + `.${index}`);
                      }}
                      value={fieldValue ? fieldValue[index] : ""}
                      disabled={data.ans_obj}
                      className={
                        data.ans_obj && questionCorrectnessType1(data, index)
                      }
                    />
                    {string[1]}
                    <RightAnswereComponent data={data} index={index} />
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default FillGroupQ;
