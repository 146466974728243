/* eslint-disable */
import React from "react";
import "./styles.scss";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function CustomToggle({ children, eventKey }) {
   const decoratedOnClick = useAccordionButton(eventKey, () =>
     console.log('totally custom!'),
   );
 
   return (
      <Button className="toggle-link" variant="link" onClick={decoratedOnClick}>
       {children}
     </Button>
     
   );
 }
const FaqPage = () => {
   return (
      <div className='faq-page'>
         <section class="inner_banner_section">
            <div class="container">
               <h1>FAQ</h1>
            </div>
         </section>
         <div className='faq-page-content'>
            <Container>
               <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                     <Accordion.Header>General</Accordion.Header>
                     <Accordion.Body>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="3">What is currently available in boo9? What's next?</CustomToggle>
                           <Accordion.Collapse eventKey="3">
                              <Card.Body>
                              <p>What is currently available?
                                 <ul>
                                    <li>
                                       A <strong>simple and easy-to-use platform</strong> to practice Math and English questions
                                       <ul>
                                          <li>Math questions for Grades 3-7</li>
                                          <li>Competitive-Math questions for Grades 1-12</li>
                                          <li>English spelling and vocabulary coming soon</li>
                                       </ul>
                                    </li>
                                    <li>
                                       <strong>Timed-practice</strong> sessions to help you prepare for real tests
                                       <ul>
                                          <li>At present, this is offered only time-to-time</li>
                                       </ul>
                                    </li>
                                    <li>
                                       <strong>Automated grading</strong> so you can get instant result
                                       <ul>
                                          <li>For all questions</li>
                                       </ul>
                                    </li>
                                    <li>
                                       <strong>User dashboard</strong> to track progress and receive granular feedback
                                       <ul>
                                          <li>Track your weekly score, set-wise</li>
                                          <li>More granular and personalized feedback coming soon</li>
                                       </ul>
                                    </li>
                                 </ul>
                              </p>
                              <p>What's next?
                                 <ul>
                                    <li>Expanding the breadth and depth of the practice problems</li>
                                    <li>Video explanations to the practice problems</li>
                                    <li>Personalization (tests, feedback, etc.)</li>
                                    <li>Learning materials (including videos, InteLa&trade;, etc.)</li>
                                 </ul>
                                 Is there anything you would like us to work on, or prioritize? 
                                 We would love to hear from you, please <Link to='/contact'>reach out to us</Link>.
                              </p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="4">How are you different from XYZ?</CustomToggle>
                           <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                 <p>
                                    Our differentiation lies in our interactive and practice-focused approach to learning. As we continue to grow, its impact will become more apparent.
                                    However, for now, we'd like to share parents' feedback that offers insight into what sets us apart.
                                    <br/><br/>                             
                                    <em>"Platform is simple and easy to use. Pricing is reasonable even with the limited practice sets you are offering now (very useful for students and saves a ton of time for parents).
                                    Cannot wait to see your personalization and interactive content."</em>
                                    <br/><br/>
                                    <em>"You guys are on the right track, love your mission and future plans."</em>
                                    <br/><br/>
                                    <em>"My kids really liked your teaching style - starting with the fundamentals and making it sink-in with simple examples, then gradually building up in a way that made them feel right at home.
                                    At the end, they were tackling problems they thought were too difficult earlier. The best part is I could see a sense of confidence in them!
                                    I am hoping to see the same style in your online videos."</em>
                                    <br/><br/>
                                    <em>"I was looking for a simple, no BS, website where my kid could practice some questions and get instant feedback. You guys have nailed it. Now, please work on video explanations!"</em>
                                 </p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="5">What if I have more questions?</CustomToggle>
                           <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                 <p>
                                    Please do <Link to='/contact'>reach out to us</Link>.
                                 </p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                     </Accordion.Body>
                  </Accordion.Item>
                  
                  <Accordion.Item eventKey="1">
                     <Accordion.Header>Practice sets</Accordion.Header>
                     <Accordion.Body>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="6">Why only weekly practice sets? Why not on-demand and personalized questions?</CustomToggle>
                           <Accordion.Collapse eventKey="6">
                              <Card.Body>
                                 <p>We are currently limited by time and resources to provide on-demand and personalized practice sets.         
                                    But hopefully soon, please staty tuned! If this is a must have for you, please do <Link to='/contact'>reach out to us</Link>.
                                 </p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                     </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                     <Accordion.Header>Pricing</Accordion.Header>
                     <Accordion.Body>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="7">Lifetime discount - what's up with that?</CustomToggle>
                           <Accordion.Collapse eventKey="7">
                              <Card.Body>
                              <p>To incentivize early sign-ups because we need funds for the development work. In return, we have this incentive for our early users.</p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                        <Accordion defaultActiveKey="0">
                           <CustomToggle eventKey="8">I really like your platform, but I cannot afford to pay. Can you help?</CustomToggle>
                           <Accordion.Collapse eventKey="8">
                              <Card.Body>
                              <p>We do NOT turn anyone away simply because they cannot pay. Please tell us your <Link to='/forms/special1'>story</Link>.</p>
                              </Card.Body>
                           </Accordion.Collapse>
                        </Accordion>

                     </Accordion.Body>
                  </Accordion.Item>

               </Accordion>
               <br /><br />
               <p>
                  This page will be regularly updated to answer FAQs.
                  If you have a question that hasn't been answered yet or would like additional information, please don't hesitate to <Link to='/contact'>reach out to us</Link>.
               </p>
            </Container>
         </div>
      </div>
   )
}

export default FaqPage;
