/* eslint-disable */
import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss'
import { Container } from 'react-bootstrap'
import questionData from './questions.json';
import VideoExplanationComponent from '../../../../components/VideoExplanationComponent';

const ResourcesMathKangaroo2021L6 = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [show, setShow] = useState({
        q_id: false
    });

    const handleShow = (value) => (key) => {
        if (isLoggedIn) {
            setShow({
                q_id: value.q_id
            }
            )
        } else {
            navigate('/login')
        }        
    }

    return (
		<div className='tos-page resource-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Practice Problems: MK 2020 Level 6</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    {
                        questionData.map((qn, i) => {
                            return <div className='question_section'>
                            <p className='question_value'>{i+1}. {qn.question}</p>
                            <p className="key_value">
                                <span className='field_name'>Question: </span>
                                <span className='field_value'>                        
                                    <img className="resource-qn-image" src={qn.question_image} alt='question image'/>
                                </span>
                            </p>                                                         
                            <p className="key_value">
                                <span className='field_name'>Answer: </span>
                                <span className='field_value' 
                                    onClick={handleShow(qn)}>
                                        {show.q_id === qn.q_id ? qn.answer : 
                                            <span className='text-link' title= "It will take you to sign-in. After signing-in you can see the answers." >
                                                {isLoggedIn ? 'Click to show' : 'Log in to see'}
                                            </span>
                                        }
                                </span>
                                { qn.video_link && <> |
                                <VideoExplanationComponent videoLink={qn.video_link}></VideoExplanationComponent></>}
                            </p>
                        </div>;
                        })
                    }
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathKangaroo2021L6;
