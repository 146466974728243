import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/authSlice';
import practiceSlice from './slices/practiceSlice';
import paymentSlice from './slices/paymentSlice';
import userProfileSlice from './slices/userProfileSlice';
import special1Slice from './slices/special1Slice';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['']
};

const authConfig = {
	key: 'auth',
	storage,
	whitelist: ['authToken', 'userData']
};

const practiceConfig = {
	key: 'practice',
	storage,
	whitelist: ['sectionId']
};

const rootReducer = combineReducers({
	auth: persistReducer(authConfig, authSlice),
	practiceSet: persistReducer(practiceConfig, practiceSlice),
	payment: paymentSlice,
	profile: userProfileSlice,
	special1: special1Slice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [PERSIST, REHYDRATE]
			}
		})
});

export const persistor = persistStore(store);
