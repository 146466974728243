/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import nextIcon from "../../../assets/images/icons/next-icon.svg";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import CustomModal from "../../../components/CustomModal";
import {
  adminPracticeSectionsSubmit,
  getFlaggedQuestion,
  practiceSectionsSubmit,
  sendOnDemandAdaptiveParameters,
  sendOndemandParameters,
  storeCurrentQuestionAns,
} from "../../../redux/actions";
import { useFormik } from "formik";
import { isEmpty, has, isNull, isArray } from "lodash";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { getFieldVal, getQuestionIndex } from "../../../utils/PracticePage";
import UniqueChoiceQ from "../../../components/QuestionsGroup/UniqueChoiceQ";
import TextAnswerQ from "../../../components/QuestionsGroup/TextAnswerQ";
import DictationQ from "../../../components/QuestionsGroup/DictationQ";
import MathExpressionQ from "../../../components/QuestionsGroup/MathExpressionQ";
import MultipleChoiceQ from "../../../components/QuestionsGroup/MultipleChoiceQ";
import TrueFalseQ from "../../../components/QuestionsGroup/TrueFalseQ";
import FillImgQ from "../../../components/QuestionsGroup/FillImgQ";
import FillBlankQ from "../../../components/QuestionsGroup/FillBlankQ";
import FillGroupQ from "../../../components/QuestionsGroup/FillGroupQ";
import FillInlineQ from "../../../components/QuestionsGroup/FillInlineQ";
import MatchItemsQ from "../../../components/QuestionsGroup/MatchItemsQ";
import MatrixMatchQ from "../../../components/QuestionsGroup/MatrixMatchQ";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import infoIcon from "../../../assets/images/icons/info.svg";
import prevIcon from "../../../assets/images/icons/prev-icon.svg";
import exitIcon from "../../../assets/images/icons/exit-icon.svg";
import warningIcon from "../../../assets/images/icons/warning-icon.svg";
import moment from "moment";
import { useSpeechSynthesis } from "react-speech-kit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Timer from "../../../components/PracticeSectionComponents/Timer";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import FlaggedQuestionModal from "../flaggedQuestionPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
const OndemandAdaptivePage = () => {
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [warningShow, setWarningShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [closetimer, setClosetimer] = useState(true);
  const [closewarntimer, setClosewarntimer] = useState(false);
  const startTime = useRef(null);
  const questionIndex = useRef({ i: null, j: null, k: null });
  const {
    loading,
    practiceSectionData: questionsList,
    alertSuccessMessage,
    alertErrorMessage,
    counterResponse,
    counterPostResponse,
    getTimedSaveAsDraftDataResponse,
    timedSectionLoading,
    setStore,
    ondemandAdaptiveData,
    flagQuestionModal,
  } = useSelector((state) => state.practiceSet);
  const [quesids, setQuesids] = useState([]);
  const [questionId, setQuestionId] = useState(0);
  const [id, setId] = useState(null);
  const [markForReviewQuestions, setMarkForReviewQuestions] = useState([]);
  const [marknext, setMarknext] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [listInfo, setListInfo] = useState([]);
  const [sumbitMode, setSubmitMode] = useState("clicked-submit");
  const [flagQuestion, setFlagQuestion] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const data = JSON.parse(ondemandAdaptiveData?.data);

  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const maxQues = localStorage.getItem("maxQues");
  const letAI = JSON.parse(localStorage.getItem("let_ai"));
  const includePreviousChapters = JSON.parse(
    localStorage.getItem("include_prev")
  );
  const includeBoosterQuestions = JSON.parse(
    localStorage.getItem("rank_booster")
  );
  const difficultyStart = JSON.parse(localStorage.getItem("difficulty_start"));
  const difficultyRamp = JSON.parse(localStorage.getItem("difficulty_ramp"));

  const { userData } = useSelector((state) => state.auth);
  const handleNext = (ans) => {
    setCurrentDate(new Date());
    const sidId = localStorage.getItem("sidId");
    const obJData = {
      sid: sidId,
      lot__qid_rite: quesids,
      list_uans: [...selectedOption, ans === 0 ? 0 : ans ? ans : null],
      finish: questionId + 1 === parseInt(maxQues) || submitForm ? true : false,
      list_info: listInfo,
      set_info: null,
      user_params: {
        let_ai: letAI,
        include_prev: !letAI ? includePreviousChapters : null,
        rank_booster: !letAI ? includeBoosterQuestions : null,
      },
    };

    if (submitForm || questionId + 1 === parseInt(maxQues)) {
      const last_list_info = {
        marked_4review: markForReviewQuestions[questionId]?.checked
          ? true
          : false,
        ever_marked_4review: markForReviewQuestions.some(
          (item) => item?.checked
        )
          ? true
          : false,
        time_started: currentDate ? currentDate.toISOString() : "",
        time_spent_in_secs:
          parseFloat(((new Date() - currentDate) / 1000).toFixed(3)) ?? 0.02,
      };

      obJData.list_info = [...obJData.list_info, last_list_info];
    }

    if (params.slug === "ondemand") {
      if (questionId + 1 === parseInt(maxQues) || submitForm) {
        obJData.set_info = {
          submit_mode: sumbitMode,
          username: userData?.payload?.username,
          session_id: generateRandomString(),
        };
      }
      dispatch(sendOndemandParameters(obJData))
        .unwrap()
        .then((res) => {
          const answer = res.ugot_right;
          setQuesids((prev) => {
            const newQuesids = [...prev];
            newQuesids[questionId][1] = answer;
            return newQuesids;
          });
          setQuestionId((prev) => prev + 1);
          setLoadingStatus(false);
        });
    } else if (params.slug === "adaptive") {
      obJData.user_params.difficulty_start = !letAI ? difficultyStart : null;
      obJData.user_params.difficulty_ramp = !letAI ? difficultyRamp : null;
      if (questionId + 1 === parseInt(maxQues) || submitForm) {
        obJData.set_info = {
          submit_mode: sumbitMode,
          username: userData?.payload?.username,
          session_id: generateRandomString(),
        };
      }
      dispatch(sendOnDemandAdaptiveParameters(obJData))
        .unwrap()
        .then((res) => {
          const answer = res.ugot_right;
          setQuesids((prev) => {
            const newQuesids = [...prev];
            newQuesids[questionId][1] = answer;
            return newQuesids;
          });
          setQuestionId((prev) => prev + 1);
          setLoadingStatus(false);
        });
    }
  };

  const getuserValue = (values, index) => {
    const result =
      values?.list_sections?.[index]?.list_ags?.[index]?.list_ans?.[index]
        ?.stud_ans;
    return result;
  };

  const [markattempted, setMarkAttempted] = useState([]);

  const onSubmit = () => {
    setLoadingStatus(true);
    const list_info = {
      marked_4review: markForReviewQuestions[questionId]?.checked
        ? true
        : false,
      ever_marked_4review: markForReviewQuestions.some((item) => item?.checked)
        ? true
        : false,
      time_started: currentDate ? currentDate.toISOString() : "",
      time_spent_in_secs:
        parseFloat(((new Date() - currentDate) / 1000).toFixed(3)) ?? 0.02,
    };

    const ans = getuserValue(values, questionId);
    // setMarkAttempted((prev) => [...prev, { id: questionId, attempted: ans }]);
    // ans == true ? 1 : ans === false ? 0 : 1;
    const convertedAns = Array.isArray(ans)
      ? ans.map((innerArray) =>
          Array.isArray(innerArray)
            ? innerArray.map((val) => (val ? 1 : 0))
            : innerArray
            ? 1
            : 0
        )
      : ans === true
      ? 1
      : ans === false
      ? 0
      : ans;

    setListInfo((prev) => [...prev, list_info]);
    const is_admin =
      userData?.payload?.is_admin &&
      location.pathname.startsWith("/admin/practices");
    if (submitForm === true) {
      handleNext(convertedAns);
      localStorage.removeItem("time");
      localStorage.removeItem("save_draft");
      toast.success("Test submitted successfully");
      setTimeout(() => {
        is_admin ? navigate("/admin/practice") : navigate("/practice");
      }, 3000);
    } else {
      setMarknext(true);
      setSelectedOption((prev) => [...prev, convertedAns]);
      handleNext(convertedAns);
      if (questionId + 1 === parseInt(maxQues)) {
        localStorage.removeItem("time");
        localStorage.removeItem("save_draft");
        toast.success("Test submitted successfully");
        setTimeout(() => {
          is_admin ? navigate("/admin/practice") : navigate("/practice");
        }, 3000);
      }
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {},
      onSubmit,
    });

  useEffect(() => {
    const ans = getuserValue(values, questionId);
    setMarkAttempted((prev) => {
      const index = prev.findIndex((item) => item.id === questionId);
      if (index !== -1) {
        const updatedMarkAttempted = [...prev];
        updatedMarkAttempted[index].attempted = ans;
        return updatedMarkAttempted;
      } else {
        return [...prev, { id: questionId, attempted: ans }];
      }
    });

    dispatch(storeCurrentQuestionAns({ ans_key: ans }));
  }, [values]);

  const onEnd = () => {
    setId(null);
  };

  const { speak } = useSpeechSynthesis({ onEnd });
  const handleQuestionsSet = (data) => {
    if (data?.q_type !== undefined) {
      const { q_type: qType } = data;
      // const { i, j, k } = getQuestionIndex(questionsList, data);
      let i = questionId,
        j = questionId,
        k = questionId;
      questionIndex.current = { i, j, k };
      const props = {};
      const changeClass = () => {
        speak({ text: data.q_html });
        setId(k);
      };
      const active = () => {
        if (id === k) {
          return true;
        }
        return false;
      };
      props.key = k;
      props.active = active();
      props.onPress = changeClass;
      props.data = data;
      props.data.qno = questionId + 1;
      props.indexValue = { i, j, k };
      props.value = values;
      props.onChange = handleChange;
      props.setFieldVal = setFieldValue;
      props.isTimedQuestion = true;
      props.questionId = questionId + 1;
      props.fieldValue = getFieldVal(values, i, j, k);
      props.name = `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`;
      // if (isEmpty(values)) return;

      switch (qType) {
        case "TextAnswerQ":
          return <TextAnswerQ {...props} />;
        case "DictationQ":
          return <DictationQ {...props} />;
        case "MathExpressionQ":
          return <MathExpressionQ {...props} />;
        case "MultipleChoiceQ":
          return <MultipleChoiceQ {...props} />;
        case "UniqueChoiceQ":
          return <UniqueChoiceQ {...props} />;
        case "TrueFalseQ":
          return <TrueFalseQ {...props} />;
        case "FillImgQ":
          return <FillImgQ {...props} />;
        case "FillBlankQ":
          return <FillBlankQ {...props} />;
        case "FillGroupQ":
          return <FillGroupQ {...props} />;
        case "FillInlineQ":
          return <FillInlineQ {...props} />;
        case "MatchItemsQ":
          return <MatchItemsQ {...props} />;
        case "MatrixMatchQ":
          return <MatrixMatchQ {...props} />;
        default:
          return "";
      }
    }
  };

  useEffect(() => {
    if (data) {
      setCurrentDate(new Date());
      if (data?.ques?.q_id !== undefined) {
        const { q_id } = data.ques;

        setQuesids((prev) => {
          const exists = prev.some(([id]) => id === q_id);
          if (exists) {
            return prev.map(([id, val]) =>
              id === q_id ? [id, null] : [id, val]
            );
          } else {
            return [...prev, [q_id, null]];
          }
        });
      }
    }
  }, [data?.ques?.q_id]);

  const handleMarkForReview = (e, id) => {
    const tempArray = [...markForReviewQuestions];
    tempArray[id] = { id, checked: e.target.checked };
    setMarkForReviewQuestions(tempArray);
  };

  const [showReloadModal, setShowReloadModal] = useState(false);

  const handleBeforeUnload = (event) => {
    setShowReloadModal(true);
    event.preventDefault();
    event.returnValue = "";
  };

  const handleConfirmReload = async () => {
    setSubmitForm(true);
    setSubmitMode("abandoned");
    handleSubmit(onSubmit);
  };

  const handleCancelReload = () => {
    setShowReloadModal(false);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      setSubmitMode("abandoned");
      setShow(true);
      setIsSubmit(false);
      setSubmitForm(true);
      window.history.pushState(null, document.title, window.location.href);
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const footerButtons = [
    {
      label: "Cancel",
      variant: "danger",
      onClick: () => {
        setSubmitForm(false);
        setShow(false);
      },
    },
    {
      label: isSubmit ? "Submit" : "Exit",
      variant: "primary",
      onClick: () => {
        handleSubmit(onSubmit);
      },
    },
  ];

  const handleisAttempted = (arr) => {
    if (Array.isArray(arr[0])) {
      // Handle nested arrays
      return arr.some(
        (innerArray) =>
          Array.isArray(innerArray) && innerArray.some((item) => item === true)
      );
    } else {
      // Handle single array
      return arr.some((item) => item !== null || item === true);
    }
  };

  return (
    data && (
      <div style={{ marginTop: "80px" }} className="bg-light">
        <section className="timer_section  pt-3">
          <Container>
            <div className="timer_wrapper bg-white d-flex flex-md-nowrap flex-wrap gap-2 gap-md-0 justify-content-center justify-content-md-between ps-md-2 pe-md-0 py-md-0 p-2 rounded-pill">
              <div className="timer_left_buttons">
                <Button
                  onClick={() => {
                    setSubmitMode("abandoned");
                    setShow(true);
                    setIsSubmit(false);
                    setSubmitForm(true);
                  }}
                  className="exit_btn w-40px h-40px border-0 p-0 text-center d-flex align-items-center justify-content-center"
                >
                  <ReactSVG
                    src={exitIcon}
                    className="arrow_icon w-30px h-30px"
                  />
                </Button>
                <Button
                  onClick={() => {
                    setWarningShow(true);
                  }}
                  className="warning_btn w-40px h-40px p-0"
                >
                  <ReactSVG src={warningIcon} className="warning_icon" />
                </Button>
              </div>
              <div className="time_center_infoo d-flex gap-2 align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="cs-tooltip">
                      <div>
                        <p className="mb-1">{setStore?.type}</p>
                        <strong>{setStore?.setname} </strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div className="pe-lg-3">
                    <img src={infoIcon} width={24} />
                  </div>
                </OverlayTrigger>
                {closewarntimer === false ? (
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="checkbox"
                      label="Show time remaining"
                      checked={closetimer}
                      onChange={() => setClosetimer(!closetimer)}
                    />
                  </Form.Group>
                ) : null}
                <div>
                  {!closetimer ? (
                    <div className="timmer_section_div"></div>
                  ) : (
                    <div className="timmer_section_div">
                      <Timer
                        handleSubmit={() => {
                          setSubmitForm(true);
                          handleSubmit(onSubmit);
                        }}
                        setClosetimer={setClosetimer}
                        setClosewarntimer={setClosewarntimer}
                        setSubmitMode={setSubmitMode}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Button
                onClick={() => {
                  setIsSubmit(true);
                  setShow(true);
                  setSubmitMode("clicked-submit");
                  setSubmitForm(true);
                }}
                // disabled={questionId + 1 < maxQues}
                className="grad_submit_btn"
              >
                Submit for grading
              </Button>
            </div>
          </Container>
        </section>

        <section className="question_section fix-height">
          <Container>
            <div className="questions_wrap">
              <Container
                id={questionId + 1}
                style={{ overflow: loadingStatus ? "hidden" : "" }}
              >
                {loadingStatus ? (
                  <div className="one-ques-loader">
                    <Loader />
                  </div>
                ) : (
                  handleQuestionsSet(data?.ques)
                )}

                <Form.Check inline={true} className="mark_for_review_checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    id="mark_for_review"
                    name="mark_for_review"
                    onChange={(e) => handleMarkForReview(e, questionId)}
                    value={markForReviewQuestions?.[questionId]?.checked}
                    checked={markForReviewQuestions?.[questionId]?.checked}
                  />
                  <Form.Check.Label htmlFor="mark_for_review">
                    Mark for review
                  </Form.Check.Label>
                </Form.Check>
              </Container>
            </div>
          </Container>
          <FlaggedQuestionModal flagQuestion={flagQuestionModal} />
        </section>

        <div className="page-ques-next questions_list_box">
          <div className="container">
            <div className="question_list_section">
              <div className="question_list_section_wrapper">
                {Array.from({ length: maxQues }, (e, i) => {
                  const isAttempted = markattempted[i]?.attempted;
                  const isMarkedForReview =
                    isArray(markForReviewQuestions) &&
                    markForReviewQuestions?.filter(
                      (ele) => ele?.id === i && ele?.checked
                    )?.length > 0;
                  return (
                    <div
                      key={i}
                      onClick={() => {}}
                      className={`dots  ${
                        i === questionId ? "active-question" : ""
                      } ${isMarkedForReview ? "mark_for_review" : ""}
                      ${
                        (
                          Array.isArray(isAttempted)
                            ? handleisAttempted(isAttempted)
                            : isAttempted !== null && isAttempted !== undefined
                        )
                          ? "question_attempted"
                          : ""
                      }
                      ${i > questionId ? "next-questions" : ""}`}
                    >
                      {i + 1}
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  className="arrow_btn next"
                  disabled={loadingStatus}
                  onClick={() => {
                    if (questionId + 1 === parseInt(maxQues)) {
                      setSubmitMode("clicked-submit");
                      setShow(true);
                      setIsSubmit(true);
                    } else {
                      handleSubmit(onSubmit);
                    }
                    setMarkAttempted((prev) => [
                      ...prev,
                      { id: questionId + 1, attempted: null },
                    ]);
                  }}
                >
                  {questionId + 1 === parseInt(maxQues) ? "Finish" : "Next"}
                  <ReactSVG src={nextIcon} className="arrow_icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showReloadModal} onHide={handleCancelReload}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Page Reload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to reload the page? Reloading the page will be
            considered as submitting the test
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelReload}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmReload}>
              Reload
            </Button>
          </Modal.Footer>
        </Modal>
        <CustomModal
          title={
            isSubmit
              ? "Are you sure you want to submit?"
              : "Are you sure you want to exit?"
          }
          content={
            <>
              <p>You wont be able to attempt this question set again.</p>
            </>
          }
          footerButtons={footerButtons}
          show={show}
          handleClose={() => {
            setSubmitForm(false);
            setShow(false);
          }}
        />

        <CustomModal
          title="Warning"
          content={
            <p>
              Do not press the back button, refresh the page, or close the
              window during the exam. Doing so will automatically submit the
              exam, and you will not be able to resubmit it. Please ensure that
              you complete the exam without performing any of these actions to
              avoid unintended submission.
            </p>
          }
          show={warningShow}
          handleClose={() => setWarningShow(false)}
        />
      </div>
    )
  );
};

export default OndemandAdaptivePage;
