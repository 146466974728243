import React, { memo } from 'react';

const TableContentsComponent = ({ data }) => {
	return (
		<tr>
			<th>{data.tableHead}</th>
			{data.tableDocument.map((image, index) => {
				return (
					<td key={index} className={image.class}>
						<img src={image.icon} />
					</td>
				);
			})}
		</tr>
	);
};

export default memo(TableContentsComponent);
