import React from 'react';
import Header from '../components/HeaderComponent';
import Footer from '../components/FooterComponent';

export const GeneralRoute = ({ children }) => {
	return (
		<div className='main'>
			<Header />
			<div className='content'>{children}</div>
			<Footer />
		</div>
	);
};
