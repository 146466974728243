/* eslint-disable */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import FlaggedStartQuestionModal from "./flaggedStartpage";
import FlaggedAlreadyQuestionModal from "./flaggedAlreadyPage";
import { hidelFaggedQuestionModal } from "../../../redux/actions";

const FlaggedQuestionModal = ({ flagQuestion }) => {
  const [stillwantFlag, setStillwantFlag] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(0);
  const { flaggedQuestion } = useSelector((state) => state.practiceSet);
  const dispatch = useDispatch();
  return (
    flaggedQuestion.message && (
      <Modal
        show={flagQuestion}
        onHide={() => {
          // setFlagQuestion(false);
          dispatch(hidelFaggedQuestionModal(false));
          setStillwantFlag(false);
          setCurrentFlag(0);
        }}
        size="lg"
        className="modal-400 subscription_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {flaggedQuestion.existing_flag === false ||
            stillwantFlag === true ||
            flaggedQuestion?.existing_flag?.resolved === true
              ? "Report an issue in this question"
              : flaggedQuestion?.message}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flaggedQuestion.existing_flag === false ||
          stillwantFlag === true ||
          flaggedQuestion?.existing_flag?.resolved === true ? (
            <FlaggedStartQuestionModal setStillwantFlag={setStillwantFlag} />
          ) : (
            <FlaggedAlreadyQuestionModal
              setStillwantFlag={setStillwantFlag}
              currentFlag={currentFlag}
              setCurrentFlag={setCurrentFlag}
            />
          )}
        </Modal.Body>
      </Modal>
    )
  );
};

export default FlaggedQuestionModal;
