/* eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";
import {
  signUp,
  refreshToken,
  signIn,
  forgotPassword,
  resetPassword,
  contactEmail,
  sendOtp,
  resetOtp,
  verifyOtp,
  handleSignupConfirm,
} from "../actions";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    isLoggedIn: false,
    signupSuccessMessage: "",
    authToken: "",
    userData: {},
    signupErrorMessage: {},
    signinErrorMessage: {},
    forgotPaswordSuccessMessage: "",
    forgotPaswordErrorMessage: {},
    resetPaswordSuccessMessage: "",
    resetPaswordErrorMessage: {},
    tokenVerified: false,
    userLastLocation: "",
    contactEmailSuccessMessage: "",
    contactEmailErrorMessage: {},
    sendOtpSuccessResponse: null,
    resetOtpSuccessResponse: null,
    verifyOtpSuccessResponse: null,
    otpError: {},
    isOtpVerified: false,
    logoutModalShow: false,
    signupConfirmResponse: null,
    signupConfirmErrorResponse: null,
  },
  reducers: {
    signOut: (state) => {
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        authToken: "",
        userData: {},
        logoutModalShow: true,
      };
    },
    setLogoutModalShow: (state) => {
      return { ...state, logoutModalShow: false };
    },
    location: (state, { payload }) => {
      return { ...state, userLastLocation: payload };
    },
    reset: (state) => {
      return {
        ...state,
        forgotPaswordSuccessMessage: "",
        resetPaswordSuccessMessage: "",
        contactEmailSuccessMessage: "",
        sendOtpSuccessResponse: null,
        resetOtpSuccessResponse: null,
        verifyOtpSuccessResponse: null,
        otpError: {},
      };
    },
  },
  extraReducers: {
    [sendOtp.pending]: (state) => {
      return { ...state };
    },
    [sendOtp.fulfilled]: (state, { payload }) => {
      localStorage.setItem("timer", Date.now() + 30000);
      return {
        ...state,
        sendOtpSuccessResponse: payload,
        otpError: {},
      };
    },
    [sendOtp.rejected]: (state, { payload }) => {
      return {
        ...state,
        otpError: payload,
      };
    },
    [resetOtp.pending]: (state) => {
      return { ...state };
    },
    [resetOtp.fulfilled]: (state, { payload }) => {
      localStorage.setItem("timer", Date.now() + 30000);
      return {
        ...state,
        resetOtpSuccessResponse: payload,
        otpError: {},
      };
    },
    [resetOtp.rejected]: (state, { payload }) => {
      return {
        ...state,
        otpError: payload,
      };
    },
    [verifyOtp.pending]: (state) => {
      return { ...state };
    },
    [verifyOtp.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        verifyOtpSuccessResponse: payload,
        otpError: {},
        isOtpVerified: true,
      };
    },
    [verifyOtp.rejected]: (state, { payload }) => {
      return {
        ...state,
        otpError: payload,
        isOtpVerified: false,
      };
    },

    [signUp.pending]: (state) => {
      return { ...state, loading: true };
    },
    [signUp.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        signupSuccessMessage: payload,
        signupErrorMessage: {},
      };
    },
    [signUp.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        signupErrorMessage: payload,
      };
    },

    [handleSignupConfirm.pending]: (state) => {
      return { ...state, loading: true };
    },
    [handleSignupConfirm.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        signupConfirmResponse: payload,
      };
    },
    [handleSignupConfirm.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        signupConfirmErrorResponse: payload,
      };
    },

    [signIn.pending]: (state) => {
      return { ...state, loading: true };
    },
    [signIn.fulfilled]: (state, { payload }) => {
      localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        authToken: payload.token,
        signupErrorMessage: {},
        signinErrorMessage: {},
        resetPaswordSuccessMessage: "",
        userData: { payload },
      };
    },
    [signIn.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        signinErrorMessage: payload,
      };
    },
    [refreshToken.pending]: (state) => {
      return { ...state, loading: true };
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      localStorage.setItem("authToken", payload);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        authToken: payload,
        tokenVerified: true,
      };
    },
    [refreshToken.rejected]: (state, { payload }) => {
      localStorage.clear();
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        tokenVerified: true,
        authToken: "",
        userData: {},
      };
    },
    [forgotPassword.pending]: (state) => {
      return { ...state, loading: true };
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        forgotPaswordSuccessMessage: payload,
        forgotPaswordErrorMessage: {},
        signinErrorMessage: {},
      };
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        forgotPaswordErrorMessage: payload,
        forgotPaswordSuccessMessage: "",
      };
    },
    [resetPassword.pending]: (state) => {
      return { ...state, loading: true };
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        resetPaswordErrorMessage: {},
        resetPaswordSuccessMessage: payload,
        forgotPaswordSuccessMessage: "",
      };
    },
    [resetPassword.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        resetPaswordErrorMessage: payload,
      };
    },
    [contactEmail.pending]: (state) => {
      return { ...state, loading: true };
    },
    [contactEmail.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        contactEmailErrorMessage: {},
        contactEmailSuccessMessage: payload,
      };
    },
    [contactEmail.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        contactEmailErrorMessage: payload,
      };
    },
  },
});
export const { signOut, location, reset, setLogoutModalShow } =
  authSlice.actions;
export default authSlice.reducer;
