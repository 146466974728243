/* eslint-disable */
import React, { memo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isArray, isNull } from "lodash";

const QuestionScoreComponent = ({ data }) => {
  if (data.grading_type === "instant" && isNull(data.ans_obj.score_points))
    return;

  const yourScore = () => {
    if (data.ans_obj && data.grading_type !== "instant") {
      return `${data.ans_obj.score_points}/${data.ans_obj.score_outof}`;
    }
    return data.max_points;
  };

  const maxPoints = () => {
    if (data.grading_type !== "instant") {
      if (isArray(data.max_points)) {
        return data.max_points.reduce((partialSum, a) => partialSum + a, 0);
      }
      return data.max_points;
    }
    return data.max_points;
  };

  const toolTipText = () => {
    // if (data.grading_type !== 'instant') {
    // 	return 'This question is not graded';
    // }
    return "Your score for this question";
  };

  const toolTipTextBeforeSubmit = () => {
    // if (data.grading_type !== 'instant') {
    // 	return 'This question is not graded';
    // }
    return "Max points for this question";
  };

  if (data.ans_obj) {
    // return <span className='score'>{yourScore()}</span>
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <strong>{toolTipText()}</strong>
          </Tooltip>
        }
      >
        <div className="score">{yourScore()}</div>
      </OverlayTrigger>
    );
  } else {
    return (
      // <span className='score'>
      // 	{maxPoints()}
      // </span>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <strong>{toolTipTextBeforeSubmit()}</strong>
          </Tooltip>
        }
      >
        <span className="score">{maxPoints()}</span>
      </OverlayTrigger>
    );
  }
};

export default memo(QuestionScoreComponent);
