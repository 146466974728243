import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
	return (
		<Spinner
			as='span'
			role='status'
			aria-hidden='true'
			animation='border'
		/>
	);
};

export default Loader;
