import React from 'react';
import './styles.scss';

const AccountPage = () => {
	return (
		<div className='emptyPage'>
			<h2>Account Page</h2>
		</div>
	);
};

export default AccountPage;
