import * as Yup from 'yup';

export const initialValuesSignUp = {
	firstname: '',
	lastname: '',
	username: '',
	password: '',
	email: '',
	phone_number: '',
	confirm_age: false
};
export const initialValuesForgotPassword = {
	firstname: '',
	lastname: '',
	email: ''
};
export const initialValuesResetPassword = {
	password: ''
};
export const initialValuesSignIn = {
	username: '',
	password: ''
};
export const initialValuesContact = {
	name: '',
	email: '',
	subject: '',
	message: ''
};
export const contactSchema = Yup.object({
	name: Yup.string().required('Please enter name'),
	email: Yup.string().email('Email must be in valid format').required('Please enter email'),
	subject: Yup.string().required('Please enter subject'),
	message: Yup.string().required('Please enter message')
});
export const signinSchema = Yup.object({
	username: Yup.string().required('Please enter username'),
	password: Yup.string().required('Please enter password')
});
export const forgotPasswordSchema = Yup.object({
	// firstname: Yup.string().required('Please enter firstname'),
	// lastname: Yup.string().required('Please enter lastname'),
	email: Yup.string()
		.email('Email must be in valid format')
		.required('Please enter email')
});
export const resetPasswordSchema = Yup.object({
	password: Yup.string()
		.matches(
			/(?=.*\d)(?=.*[@_$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}/,
			'Password must be in valid format'
		)
		.min(8)
		.max(16)
		.required('Please enter password')
});

export const signupSchema = Yup.object({
	firstname: Yup.string().required('Please enter firstname'),
	lastname: Yup.string().required('Please enter lastname'),
	username: Yup.string()
		.min(6)
		.max(16)
		.matches(
			/^[a-zA-Z][a-zA-Z0-9_\b]{5,15}$/,
			'Username must be in valid format'
		)
		.required('Please enter username'),
	password: Yup.string()
		.matches(
			/(?=.*\d)(?=.*[@_$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}/,
			'Password must be in valid format'
		)
		.min(8)
		.max(16)
		.required('Please enter password'),
	email: Yup.string()
		.email('Email must be in valid format')
		.required('Please enter email'),
	confirm_age: Yup.bool().isTrue('Must check this box to sign up')
		.required('Please check this box')
});
