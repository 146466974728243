/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { isEmpty, isNull } from "lodash";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import {
  attemptedQuestionCounterType1,
  questionCorrectnessType1,
  correctQuestionVal,
} from "../../../utils/PracticePage";

import "./styles.scss";
import Markdown from "../../Markdown";
import AnsMarkingComponent from "../AnsMarkingComponent";
import useShowHideAns from "../../../hooks/useShowHideAns";
import CommonFlag from "../CommonFlag";

const FillBlankQ = ({
  data,
  indexValue,
  setFieldVal,
  value,
  fieldValue,
  name,
}) => {
  const [inputVal, setInputVal] = useState(
    isNull(fieldValue) ? "" : fieldValue
  );

  const string = data.q_html.split("<input type='text' />");

  useEffect(() => {
    attemptedQuestionCounterType1(data, value, indexValue, fieldValue);
    correctQuestionVal(fieldValue, setFieldVal, name);
  }, [value]);

  const handleChange = (e) => {
    setFieldVal(name, e);
    setInputVal(e);
  };
  const handleInputChange = (e) => {
    if (isEmpty(e.target.value)) {
      setFieldVal(name, null);
    } else setFieldVal(name, e.target.value);
    setInputVal(e.target.value);
  };

  const generateHTMLContent = (ctext) => {
    return <Markdown>{ctext}</Markdown>;
  };

  const correctStyle = (data, value) => {
    // if (data?.grading_type !== 'instant') return null;

    if (!useShowHideAns()) return "";

    if (data?.ans_obj?.correctness === "unattempted") {
      if (data?.ans_obj?.ans_key === value) {
        return "question_ans_right";
      }
      return "question_ans_wrong";
    }
    if (data?.ans_obj?.ans_key === value) {
      return "question_ans_right";
    }
    if (data?.attempted_obj === value) {
      return "question_ans_wrong";
    }
    return "";
  };

  return (
    <div className="fib_simple_display" id={`${data.q_type}_${data.qno}`}>
      <div className="question_number">
        <h6>{data.qno}</h6>
        <QuestionScoreComponent data={data} />
        <QuestionHintComponent hints={data.hints} />
        <QueExplanationComponent data={data} explanations={data.explanations} />
        <AnsMarkingComponent data={data} />
        <CommonFlag data={data} />
      </div>
      <div className="fib_simple_display_text">
        <h4>
          {string[0]}
          <input
            type="text"
            name={name}
            onChange={(e) => handleInputChange(e)}
            value={isEmpty(inputVal) ? "" : inputVal}
            disabled={data.ans_obj}
            className={`fib_simple_display_input ${
              data.ans_obj && questionCorrectnessType1(data)
            }`}
          />
          {string[1]}
        </h4>
        <div className="fib_simple_display_radio">
          {data.list_ctext.map((element, index) => {
            return (
              <Form.Check key={index}>
                <Form.Check.Input
                  key={index}
                  name={name}
                  id={`${data.qno}`}
                  type="radio"
                  onChange={() => handleChange(element)}
                  value={element}
                  checked={element === inputVal}
                  disabled={data.ans_obj}
                />
                <Form.Check.Label
                  className={`${element === inputVal && "active"} ${
                    data.ans_obj && correctStyle(data, element)
                  }`}
                >
                  {generateHTMLContent(element)}
                </Form.Check.Label>
              </Form.Check>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FillBlankQ;
