import { isEmpty } from 'lodash';
import React from 'react';
import Markdown from '../../Markdown';

const QuestionParagraph = ({ group }) => {
	if (isEmpty(group.paras)) return;
	const generateHTMLContent = (ctext) => {
		return <Markdown>{ctext}</Markdown>;
	};
	return (
		<div className='mixed_english_reading_group'>
			<div className='mixed_english_reading_box'>
				{/* <h4>{generateHTMLContent(group.group_title)}</h4> */}
				{group.paras.map((paras, k) => (
					<p key={k}>{generateHTMLContent(paras)}</p>
				))}
			</div>
		</div>
	);
};

export default QuestionParagraph;
