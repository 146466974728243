/* eslint-disable */
import React from "react";
import { Container, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactSVG } from "react-svg";
import timeIcon from "../../../assets/images/icons/timer-icon.svg";
import crownIcon from "../../../assets/images/icons/crown.png";
import starIcon from "../../../assets/images/star.png";
import { useSelector } from "react-redux";

const UserPracticePage = ({
  loadData,
  practiceSetData,
  handleClick,
  handleShowCount,
  handleOndemand,
  handleCheckTestDone,
  handleAdaptive,
  getSingleTestDataOndemandAdaptive,
}) => {
  return (
    loadData && (
      <section>
        <Container className="py-5">
          <Accordion defaultActiveKey="0" className="cs-accordion">
            {practiceSetData?.data?.map((item, index) => {
              return (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{item?.cat_title} :</Accordion.Header>
                  <Accordion.Body className="">
                    {item.set &&
                      item.set.map((list, idx) => (
                        <div className="accordion-inner" key={idx}>
                          <ul className="subject-row p-2 mb-0 list-unstyled subject-english d-flex row-cols-md-4 row-cols-1 flex-md-nowrap flex-wrap gap-2">
                            <li className="d-flex gap-1 align-items-center">
                              {list.fname_icon.slice(-3) === "svg" ? (
                                <ReactSVG
                                  src={list.fname_icon}
                                  className="button_icon_svg"
                                />
                              ) : (
                                <img src={list.fname_icon} />
                              )}
                              <p className="mb-0">{list?.set_name_short}</p>
                            </li>
                            <li className="d-flex gap-2 align-items-center">
                              <img
                                src={timeIcon}
                                width={25}
                                className={`${
                                  list?.timed
                                    ? "show-timer-img"
                                    : "hide-timer-img"
                                }`}
                              />
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="cs-tooltip">
                                    {list?.wk_allow === true ? (
                                      <div>
                                        <p className="mb-1">Weekly practice:</p>
                                        <strong>{list?.set_name_short} </strong>
                                        <p className="mb-0 ">
                                          These questions are updated every
                                          Monday.
                                        </p>
                                      </div>
                                    ) : (
                                      <p>
                                        Weekly practice is not available for
                                        this set currently.
                                      </p>
                                    )}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <button
                                    className="btn btn-weekly"
                                    onClick={() =>
                                      handleClick(
                                        list.url,
                                        list.id,
                                        list.timed,
                                        list.submitted,
                                        list.time_hrs,
                                        list.time_mins,
                                        list.q_count,
                                        list.time_x,
                                        list.sid,
                                        list
                                      )
                                    }
                                    disabled={!list.wk_allow}
                                  >
                                    Weekly{" "}
                                    {list?.submitted || list.draft_save ? (
                                      <span
                                        className={`big-star ${
                                          list?.submitted
                                            ? "gray-star"
                                            : list.draft_save
                                            ? "orange-star"
                                            : ""
                                        }`}
                                      >
                                        {" "}
                                        <img src={starIcon} width={20} />
                                      </span>
                                    ) : null}
                                  </button>
                                </span>
                              </OverlayTrigger>
                            </li>
                            <li className="d-flex gap-2 align-items-center">
                              <span
                                className={`counting min-w-md-25px count-weekly count-pointer-cursor ${
                                  handleShowCount(
                                    "user",
                                    list?.sid,
                                    item?.cat_title,
                                    "ondemand"
                                  )
                                    ? "show-timer-img"
                                    : "hide-timer-img"
                                }`}
                                onClick={() =>
                                  getSingleTestDataOndemandAdaptive(
                                    "ondemand",
                                    list.sid
                                  )
                                }
                              >
                                {handleShowCount(
                                  "user",
                                  list?.sid,
                                  item?.cat_title,
                                  "ondemand"
                                )}
                              </span>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="cs-tooltip">
                                    {list?.rd_allow === true ? (
                                      <div>
                                        <p>On-demand practice:</p>
                                        <strong>{list?.sid}</strong>
                                        <p>
                                          These Questions are picked randomly
                                          (and may be personalized). Use this
                                          for test prep.
                                        </p>
                                      </div>
                                    ) : (
                                      <p>
                                        On-demand practice is not available for
                                        this set currently.
                                      </p>
                                    )}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <button
                                    onClick={() => {
                                      handleOndemand(list, item);
                                      handleCheckTestDone(
                                        "user",
                                        list?.sid,
                                        item?.cat_title,
                                        "ondemand"
                                      );
                                    }}
                                    className="btn btn-on-demand d-inline-flex align-items-center gap-2"
                                    disabled={!list.rd_allow}
                                  >
                                    On-demand <img src={crownIcon} width={20} />{" "}
                                  </button>
                                </span>
                              </OverlayTrigger>
                            </li>
                            <li className="d-flex gap-2 align-items-center">
                              <span
                                className={`counting min-w-md-25px count-adaptive count-pointer-cursor ${
                                  handleShowCount(
                                    "user",
                                    list?.sid,
                                    item?.cat_title,
                                    "adaptive"
                                  )
                                    ? "show-timer-img"
                                    : "hide-timer-img"
                                }`}
                                onClick={() =>
                                  getSingleTestDataOndemandAdaptive(
                                    "adaptive",
                                    list.sid
                                  )
                                }
                              >
                                {handleShowCount(
                                  "user",
                                  list?.sid,
                                  item?.cat_title,
                                  "adaptive"
                                )}
                              </span>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="cs-tooltip">
                                    {list?.ad_allow === true ? (
                                      <div>
                                        <p>On-demand practice:</p>
                                        <strong>{list?.sid}</strong>
                                        <p>
                                          These questions are adapted based on
                                          your answer. Use this if you are just
                                          learning, or already an expert.
                                        </p>
                                      </div>
                                    ) : (
                                      <p>
                                        Adaptive practice is not available for
                                        this set currently.
                                      </p>
                                    )}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <button
                                    onClick={() => {
                                      handleAdaptive(list, item);
                                      handleCheckTestDone(
                                        "user",
                                        list?.sid,
                                        item?.cat_title,
                                        "adaptive"
                                      );
                                    }}
                                    className="btn btn-adaptive d-inline-flex align-items-center gap-2"
                                    disabled={!list.ad_allow}
                                  >
                                    Adaptive <img src={crownIcon} width={20} />
                                  </button>
                                </span>
                              </OverlayTrigger>
                            </li>
                          </ul>
                        </div>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Container>
      </section>
    )
  );
};

export default UserPracticePage;
