/* eslint-disable */
import React from "react";
import "./styles.scss";
import { Container } from 'react-bootstrap'

const PrivacyPage = () => {
   return (
      <div className='privacy-page'>
      <section class="inner_banner_section">
         <div class="container">
            <h1>Privacy Policy</h1>
            <div>(Updated on: May 28, 2023)</div>
         </div></section>
      <div className='privacy-page-content'>
      <Container>
         <p><b>Effective Date:</b> May 28, 2023</p>
         <p>
            This Privacy Policy (the <b>"Policy"</b>) describes the policies and
            procedures of boo9 Inc., (the <b>"Company"</b>, <b>"we"</b>, <b>"our"</b>{' '}
            or <b>"us"</b>) for the collection, use, storage, transfer and
            disclosure of information by us, through our Website,{' '}
            <a href='https://boo9.com/' target="_blank">https://boo9.com/</a> (the <b>"Website"</b>), which includes its
            applications, widgets or online services owned or controlled by the
            Company. This Policy applies to all users and visitors of the Website of
            the Company.
         </p>
         <p>
            Your privacy is extremely crucial to us. We therefore, endeavor to
            protect your data through reasonable application of best in industry
            practices to showcase our appreciation for your continued trust in us.
         </p>
         <p>
            By reading, understanding and by your action to create an account and
            use our services with us as a legal adult or as the legal guardian or
            Parent for an account created with us you provide an informed consent to
            have read, agreed, understood to abide by this Policy.
         </p>
         <p>
            If you are the parent or legal guardian of a child under 16 years of
            age, you will find that we have high privacy standards. We also comply
            with the Children's Online Privacy Act (the "COPPA") and partner with
            parents and legal guardians to ensure your child's safe use of our
            services.
         </p>
         <p>
            Please go through this Privacy Policy carefully in order to understand
            how the Website collects, stores, uses, discloses, retains, and protects
            your information. If you do not provide the information which the
            Website requires, we may only be able to provide limited Services (as
            defined in our Terms of Use) to you.
         </p>
         <p>
            By accessing or using our Services and/or registering an account on our
            Website, you agree to your information being handled as described in
            this Privacy Policy. However, you have utmost control over your data,
            and you are hereby being informed about your choices regarding the use,
            access, correction and deletion (wherever applicable) of your
            information. Any dispute over privacy of your information is subject to
            this Privacy Policy and applicable Terms of Use, including its
            applicable resolution of disputes and limitations on damages.{' '}
         </p>
         <p className="summary-table">
            <b>In simple terms:</b> This is how our website and app interacts with
            you and the minor of whom you may be the legal guardian.
         </p>
         <p>
            The <a href="https://boo9.com/terms-of-use" target="_blank">Terms of Use</a> are incorporated by reference into this
            Privacy Policy and, for the sake of brevity, are hereinafter referred to
            as <b>"Terms of Use"</b>.
         </p>
         <p>
            This Privacy Policy also outlines the rights that you have, as a user of
            the Website, in relation to the information which we collect from you.
            By submitting information on our Website, you confirm that you have the
            right to authorize us to process your data on your behalf in accordance
            with this Policy. Please read it carefully and if you are under 16,
            please ask a parent and guardian to read it for you. As a parent or
            legal guardian, for the child under the age of 16, we need your consent
            before we permit your child to use the Website. You may provide consent
            by verifying the authorized email and by verifying the OTP through text
            on your phone.
         </p>
         <p className="summary-table">
            <b>In simple terms:</b> This is how we provide for safe use of our
            website and applications.
         </p>
         <ol>
            <li className="header">SCOPE OF THIS POLICY</li>
            <ol>
               <li>
                  This Privacy Policy applies to all users of this Website (including
                  children in grades 0-12) who may access this Website, irrespective
                  of whether they are casually browsing or accessing the Website to
                  gain knowledge about our Services or for any other purposes,
                  whatsoever.
               </li>
               <li>
                  This Privacy Policy shall become automatically applicable on the
                  users who submit their information to us for the purposes of
                  registering with us as a user or for the purposes of subscribing to
                  our services.
               </li>
               <li>
                  Any reference to the term "you" throughout this Policy shall mean
                  and include all the users of this Website.
               </li>
               <li>
                  This Policy applies to your use of this Website and the services
                  being provided by the Website, regardless of how you access or use
                  this Website (including access via desktop, mobile devices and
                  mobile applications).
               </li>
               <li>
                  For the purposes of any account for the user less than 16 years of
                  age, the parent/guardian, is the primary account holder and hence,
                  you, the parent/guardian:
                  <ol>
                  <li>
                     Are primarily responsible for requesting services and paying any
                     fees or other charges made to the account;
                  </li>
                  <li>
                     Are responsible for deciding whether to share your child's content
                     with third parties;
                  </li>
                  <li>
                     Are responsible for the active monitoring of your child's content.
                     Children who are under the age of 16 years are expressly
                     prohibited from including any personally identifiable information
                     in any content that they post to our website.
                  </li>
                  </ol>
               </li>
            </ol>
            <p className="summary-table">
                  <b>In simple terms:</b> This is how our rules and the law that
                  applies to us also becomes applicable to you as well.
            </p>
            <li className="header">INFORMATION WE COLLECT</li>
            <p>
               During the course of our connection with you, we collect certain
               information about you when you create an account with us to subscribe
               to our Services.
            </p>
            <p>
               The Website conducts its business through 3 (three) models and
               information is collected as per your usage and our "need" pertaining
               to the said 3 (three) models.{' '}
            </p>
            <p>Our models are:</p>
            <ul>
               <li>
                  <b>Model 1:</b> Free to use model wherein no account creation is required.
                  Access is provided to you free of cost for a limited material.
               </li>
               <li>
                  <b>Model 2:</b> Free to use model wherein an account must be created by you
                  for further access to certain additional material.
               </li>
               <li>
                  <b>Model 3:</b> Premium model wherein you're supposed to pay to avail
                  premium services.
               </li>
            </ul>
            <p>
               Your information is collected during your course of engagement with
               us, as per the requirements and your usage of this Website.
               Irrespective of the model under which you avail our services, we will
               collect certain information about you.{' '}
            </p>
            <p>The same has been detailed hereunder:</p>
            <ol type="a">
               <li className="header">Information collected from the users</li>
               <ol>
                  <li>
                     <b>Basic Information:</b> Personal data, which you may share with
                     us while creating an account on our Website to subscribe to our
                     Services including your first and last name and contact
                     information like your mobile number and your email address.
                  </li>
                  <li>
                     <b>Financial Information</b> like your credit card number, your
                     preferred method of payment, transaction details, etc.,
                  </li>
                  <li>
                     <b>Device Identification data</b> including information which may
                     assist us to identify your device, including your IP Address,
                     login information, browser type and version, your operating
                     system, etc.
                  </li>
                  <li>
                     <b>Demographic information</b> like your birthday, age, gender.
                  </li>
               </ol>
               <li className="header">Optional information collected from the users</li>
               <ol>
                  <li>
                     <b>Profile Picture</b> which you may allocate to your account upon
                     registering or subscribing with us;
                  </li>
                  <li>
                     <b>Address</b> and contact information which you may provide to
                     us;
                  </li>
                  <li>
                     <b>Conversations with us</b> Content of reviews posted by you on
                     our Website, emails, chats shared with us by you for any purposes,
                     whenever you may choose to connect with us regarding our services,
                     for dispute resolution purposes, customer care services, surveys,
                     inquiries, etc.
                  </li>
                  <li>
                     <b>Other data</b> which we may collect from you during the course
                     of your engagement with us including:
                  </li>
                  <ul>
                     <li>
                        Whether you use our mobile application or web application or
                        both;
                     </li>
                     <li>Whether you are our premium client or not;</li>
                     <li>
                        Whether you are eligible for benefits under our referral scheme;
                     </li>
                     <li>
                        Statistics about whether you open the push notifications sent to
                        you and likelihood of opening such notifications;
                     </li>
                     <li>
                        Usual time at which you use our Website or mobile application
                        and the amount of time spent on our Website or mobile
                        application, etc.
                     </li>
                     <li>
                        Data which may be legally required to be collected from you for
                        the purposes of your authentication, for obtaining verifiable
                        parental consent, identification or verification.
                     </li>
                  </ul>
               </ol>
            </ol>
            <p className="summary-table">
               <b>In simple terms:</b> This is the data that we collect from you and
               keep it safe with us.
            </p>
            <li className="header">MEANS OF COLLECTION OF YOUR DATA</li>
            Most of your personal or other data is provided by you whenever you
            register or create an account with us or have a subscription with us or
            whenever you use our Website.
            <ol>
               <li className="header">Information collected automatically</li>
               <ol>
                  <li>
                     Data that is collected automatically as soon as you create an
                     account with our Website (for example, your personally
                     identifiable data).
                  </li>
                  <li>
                     Data that is generated as part of one of your transactions or that
                     is linked to your account as a result of a transaction in which
                     you are involved, such as transaction amounts, card details, time
                     and location of transactions.
                  </li>
                  <li>
                     Data that is generated through your other actions when you use our
                     Website and which is linked to your account on our Website, e.g.
                     when you surf our Website to look for various courses;
                  </li>
                  <li>
                     Data regarding all other interactions with our Website including
                     your location related information (like your IP address and
                     location) and your communications with us.
                  </li>
               </ol>
               <li className="header">
                  Personal Data we collect in connection with the use of cookies and
                  similar technologies
               </li>
               <ol>
                  <li>
                     We use web beacons and similar technologies to collect data in
                     connection with our Services. We collect this data from the
                     devices (including mobile devices) that you use to avail our
                     Services. The data collected includes the following usage and
                     device-related information:
                     <ul>
                     <li>
                        Data about the pages you visit, the access time, frequency and
                        duration of visits, the links on which you click and other actions
                        you take as part of your use of our Website and in email content.
                     </li>
                     <li>
                        Data about your activities and interactions with us including data
                        about the push notifications, when and where they were shown, and
                        whether you took any action, such as clicking on it and opening
                        the app.
                     </li>
                     <li>
                        Model or device type, operating system and version, browser type
                        and settings, device ID or individual device identifier,
                        individual device token, and cookie-related data (e.g. cookie ID).
                     </li>
                     <li>
                        The IP address from which your device accesses our Services.
                     </li>
                     </ul>
                  </li>
               </ol>
               <li className="header">When the information is automatically collected by software</li>
               We may also employ software technology known as "web beacons" and/or
               "tracking tags" to help keep track of what content on our Website is
               effective. Web beacons are small graphics with a unique identifier
               that may be invisible to the user, and which are used to track the
               online activity of internet users. Web beacons are embedded in the web
               pages reviewed or email messages received. Web beacons or similar
               technologies may be used for a number of purposes, including, without
               limitation, to count visitors to the Website, to monitor how users
               navigate the Website, to count how many emails that were sent were
               actually opened, or to count how many particular articles or links
               were actually viewed.
               <p className="summary-table">
                  <b>In simple terms:</b> In simple terms: These are the ways through
                  which your data reaches us.
               </p>
               <li className="header">Cookies</li>
               <ol>
                  <li>
                     When you use our Website, we use cookies and similar technologies
                     to provide you with a better, faster and safer user experience.
                     Cookies are small text files that are automatically created by
                     your browser and stored on your device when you use the Website.
                  </li>
                  <li>
                     The cookies and similar technologies used in connection with the
                     services which the Website is providing have different functions
                     listed below:
                     <ul>
                     <li>
                        They may be technically necessary for the provision of our
                        services.
                     </li>
                     <li>
                        They help us optimize our Services technically (e.g. monitoring
                        of error messages and loading times).
                     </li>
                     <li>
                        They help us improve your user experience (e.g. save font size
                        and form data entered).
                     </li>
                     <li>
                        They help us in recognising you whenever you sign-in to your
                        account on our Website which would help us personalize your
                        experience with our services.
                     </li>
                     <li>
                        They allow us to show you more relevant marketing and
                        promotional information.
                     </li>
                     <li>
                        They help us in understanding and keep track of your
                        preferences.
                     </li>
                     <li>
                        They also help us in analyzing the performance of our services.
                     </li>
                  </ul>
                  </li>
                  <li>
                  You are also free to disable the use of cookies and similar
                  technologies if this is supported by your device. You can manage
                  your cookie settings in your browser or device settings.
                  </li>
               </ol>
               <p className="summary-table">
                  <b>In simple terms:</b> This is how you choose which data you want
                  to share and what you choose not to share with us.
               </p>
               <li className="header">Personal Data collected from Other Sources</li>
               We may also collect information about you from other sources, to the
               extent permissible by law. In particular, the same includes the
               following data:
               <ul>
                  <li>
                     Data from public sources, for example, demographic information;
                  </li>
                  <li>Data from data providers;</li>
                  <li>
                     Data from government agencies, to the extent permissible by law in
                     order to verify your identity.
                  </li>
               </ul>
            </ol>
            <li className="header">PURPOSE FOR COLLECTION OF YOUR DATA</li>
            <ol>
               <li>
                  We collect your personal data lawfully and in a transparent manner
                  and only when we have a legal basis for doing so. We collect and
                  process your personal data for various legal and permissible
                  purposes. For example, we process your personal data to provide and
                  improve our services, to understand the age of the user, to obtain
                  verifiable parental consent where a child under the age of 16
                  (sixteen) is operating our website and send personalized marketing
                  notifications to you regarding discounts available to you as a user
                  of the Website, to provide customer service and to detect, prevent,
                  mitigate and investigate fraudulent or illegal activity. When you
                  consent to our use of information about you for a specific purpose,
                  you have the right to change your mind any time (however, this will
                  not affect any processing that has already taken place).
               </li>
               <li className="header">
                  We process your personal data in order to allow you to use our
                  Services as a Client or as a Service Provider
               </li>
               <ol>
                  <li>
                     <b>For provision of our Services:</b> We collect your personal
                     data in order to process your registration on our Website as a
                     user, to enable you to use our Services provided via our Website,
                     to respond to your queries and all other ancillary services which
                     we may provide to you from time to time in lieu of your usage of
                     our Website.
                  </li>
                  <li>
                     <b>For displaying your transactions history to you:</b> We collect
                     and retain your data in order to provide you with your transaction
                     related history to enable you to keep a record of it. It shall
                     further enable you to track your subscription with us which would
                     therefore enable you to renew the same before expiration.
                  </li>
                  <li>
                     <b>For enhancement of our Services:</b> We process your data, your
                     feedback, the issues encountered while using our Services through
                     our Website or technical glitches encountered by you to
                     continuously upgrade and improve our services.
                  </li>
                  <li>
                     <b>For providing recommendations</b> based on your subscription
                     history, including but not limited to recommending the courses/
                     learning material to you, providing you with notifications about
                     upcoming promotions or deals, providing you with information about
                     change in pricing of services provided by the Website, sharing
                     information about new courses added by us from time to time, etc.
                  </li>
                  <li>
                     <b>Providing general customer support</b> including the solution
                     of problems with your usage of our Website, management of
                     disputes, providing other services within the scope of customer
                     service as well as enforcement of fee claims. For these purposes,
                     we may contact you via notification in email, telephone, SMS, push
                     notification on your mobile device or by mail. If we contact you
                     by telephone, in order to ensure efficiency, we may use
                     automatically dialed calls with tape announcements or automated
                     text messages in accordance with our Terms of Use, to the
                     extent permitted by applicable law.
                  </li>
                  <li>
                     <b>Processing of general location data</b> (such as IP address or
                     postal code) in order to provide you with specific location-based
                     services and other content that is personalized on the basis of
                     your general location data.
                  </li>
                  <li>Enforcement of our Terms of Use.</li>
               </ol>
               <li className="header">
                  We process your personal data in order to comply with legal
                  obligations to which we are subject. This includes the following
                  purposes:
               </li>
               <ol>
                  <li>
                     To obtain verifiable parental consent from you when your child’s
                     personal information is shared with us.
                  </li>
                  <li>
                     Participation in investigations and proceedings (including
                     judicial proceedings) conducted by public authorities or
                     government agencies, in particular, for the purpose of detecting,
                     investigating and prosecuting illegal acts.
                  </li>
                  <li>
                     Prevention, detection and mitigation of illegal activities
                     (including but not limited to fraud, money laundering or terrorist
                     financing).
                  </li>
                  <li>
                     Complying with information requests from third parties based on
                     any statutory information rights they have against us (e.g. in the
                     event of an intellectual property infringement, product piracy, or
                     other unlawful activity).
                  </li>
                  <li>Ensuring the information security of our Services.</li>
                  <li>
                     Retention and storage of your personal data to comply with
                     specific legal retention requirements.
                  </li>
               </ol>
               <li className="header">
                  We process your personal data in order to protect your vital
                  interests or the vital interests of another natural person. This
                  includes the following purposes:
               </li>
               <p>Prevention, detection, mitigation and investigation of unlawful
               activities that may result in impairment of your vital interests or
               the vital interests of another natural person, unless there is a
               statutory obligation to this effect.</p>
            </ol>
            <p className="summary-table">
               <b>In simple terms:</b> This is what we do with your data.
            </p>
            <li className="header">DURATION OF RETENTION OF YOUR DATA</li>
            <ol>
               <li>
                  Data collected from children below the age of 16 (sixteen) shall be
                  deleted as soon as:
               </li>
               <ol>
                  <li>the purpose for which the data was collected has been met;</li>
                  <li>if the concerned user has decided to delete their account;</li>
                  <li>If the user has unsubscribed from our services;</li>
                  <li>
                     If the parent of such a user has requested for deletion of data of
                     such a user.
                  </li>
               </ol>
               <li>
                  Data collected from users above the age of 16 (sixteen) shall be
                  retained indefinitely unless you request that we delete certain
                  information, either in the form in which you have submitted it to us
                  or in the form of backups or copies made for business continuity
                  purposes. If you have provided us with personal data, we encourage
                  you to routinely update the data to ensure that we have accurate and
                  up-to-date information about you.
               </li>
               <li>
                  Your data shall be safely retained by us after the same has been
                  provided to us. Hence, we shall not delete your personal data even
                  after you have stopped using our Services for the following reasons:
               </li>
               <ol>
               <li>
                  <b>Provision of Services:</b> We shall retain your data to
                  effectively provide you with Services through our Website. Further,
                  we shall retain your data for such things as executing the Terms of
                  Service with you, maintaining and improving the performance of our
                  Website, keeping our systems secure, and maintaining appropriate
                  business and financial records.
               </li>
               <li>
                  <b>Statutory or contractual or other similar obligations:</b>{' '}
                  Corresponding storage obligations may arise, for example, from laws
                  or official orders. It may also be necessary to store personal data
                  with regard to pending or future legal disputes. Personal data
                  contained in contracts, notifications and business letters may be
                  subject to statutory storage obligations depending on national law.
               </li>
               </ol>
            </ol>
            <p className="summary-table"><b>In simple terms:</b> This is the duration and condition according to which
            we keep what you share with us.</p>
            <li className="header">DATA SHARING</li>
            <ol>
               <li>
                  We may share your data with third party service providers for the
                  purpose of enabling them to provide their services, including
                  (without limitation) IT service providers, data storage (Amazon Web
                  Services), payment service provider (i.e., Stripe), web-hosting and
                  server providers, our employees, contractors and/or related
                  entities; courts, tribunals, regulatory authorities and law
                  enforcement officers, as required by law, in connection with any
                  actual or prospective legal proceedings, or in order to establish,
                  exercise or defend our legal rights; and third parties, including
                  agents or sub-contractors, who assist us in providing information,
                  services or direct marketing to you.
               </li>
               <li>
                  We do not share user information with any third parties for the
                  purposes of direct marketing by them unless consented to by you, and
                  any sharing of information shall only happen with the affiliates,
                  vendors, employees and representatives of the Company.
               </li>
               <li>
                  We may use data processors for providing some elements of services.
                  But we have contracts in place with them. This means that such third
                  parties shall process the data only in accordance with the terms of
                  the contracts and with the objective to provide the requested
                  services. Such third parties shall hold your personal data securely
                  and retain it for the period instructed, in accordance with
                  appropriate data retention policies.
               </li>
               <li>
                  In some circumstances we may be legally obligated to share your
                  personal data such as in the event that the information is sought
                  under a court order or where the Company is cooperating with other
                  American supervisory authorities in handling complaints or
                  investigations. We might also share information with other
                  regulatory bodies or law enforcement agencies in order to further
                  their, or our, objectives. In any scenario, before sharing any
                  personal data of the user we will satisfy ourselves that there is a
                  lawful basis on which to share the information and shall document
                  such decision making and satisfy ourselves on the subject of having
                  a legal basis to share the information.
               </li>
               <li>
                  Your personal data is only shared by us, for the purposes set out in
                  the present Policy. In order to enable a seamless flow of Services,
                  we shall share your personal data with:
                  <p>
                     Your personal data shall be processed in accordance with this
                     Policy and shared with our service providers on a need-to-know
                     basis. Hence, the service providers shall only be made aware about
                     your personal data and information which is extremely necessary
                     for provision of Services. Further, we shall share your banking or
                     credit card details with our payment Service Provider, viz.
                     "Stripe" for the purposes of processing payments seamlessly. We
                     also share your information with third party servers who store
                     your data for safety (i.e. Amazon Web Services or MongoDB Cloud).
                  </p>
               </li>
               <li>
                  <b>Amazon Web Services / MongoDB Cloud:</b> We share your personal
                  data with our third party database service provider, i.e., Amazon
                  Web Services / MongoDB Cloud, who shall securely store your personal
                  data. In view thereof, your data shall be shared with Amazon Web
                  Services / MongoDB Cloud who shall process your data in accordance
                  with this Policy.
               </li>
               <ul><li>Amazon Web Services / MongoDB Cloud shall share your personal data
               with their service providers to facilitate seamless protection of your
               data.</li></ul>
               <li>
                  We share your information with our officers and employees for the
                  purpose of processing your personal data on your behalf, and it is
                  ensured that these recipients of such information agree to process
                  such information based on our instructions and in compliance with
                  this Policy and any other appropriate confidentiality and security
                  measures.
               </li>
            </ol>
            <p className="summary-table">
               <b>In simple terms:</b> This is how we share your data and what our
               obligations are towards sharing this data.
            </p>
            <li className="header">SAFETY OF YOUR DATA AND STORAGE</li>
            <ol>
               <li>
                  We take all reasonable and necessary steps to protect your personal
                  data during transmission by using encryption protocols and software,
                  however no security measures can be 100% foolproof.
               </li>
               <li>
                  We use SSL encryption to protect your data against unauthorized
                  access and loss of data.
               </li>
               <li>
                  We maintain physical, electronic, and procedural safeguards in
                  connection with the collection, storage, processing, and disclosure
                  of personal data and only users who have permission by the
                  administrator can access your data at the back-end.
               </li>
               <li>
                  It is important for you to protect yourself against unauthorized
                  access to your password and to your computers, devices and
                  applications. You must be sure to sign off when finished using a
                  shared computer.
               </li>
               <li>
                  Your data is safely and securely stored on a third-party database,
                  specifically employed by us for the purpose of secure storage of
                  your personal data.
               </li>
            </ol>
            <p className="summary-table">
               <b>In simple terms:</b> This is what measures our website, app and our
               organization deploys to ensure safety of your data.
            </p>
            <li className="header">YOUR RIGHTS AS A DATA SUBJECT</li>
            You, as a data subject, have certain rights to the personal data which
            has been provided by you to us. We have enlisted the same, hereunder:
            <ol>
               <li>
                  As a registered user on the Website, you can update your choices of
                  communication that you may receive in the course of your
                  interaction, or you can opt out of receiving any marketing
                  communication, or receiving push notifications, or any other
                  promotional communication.
               </li>
               <li>
                  You can <b>access</b> the information which has been retained by us
                  by visiting the "Profile" section of our Website which would display
                  information supplied by you to us. Information which you shall find
                  in that section would be:
               </li>
               <ul>
                  <li>Status of your subscription,</li>
                  <li>
                     Your personal data, like your name, email, password, contact
                     details
                  </li>
                  <li>Status of your membership with us;</li>
               </ul>
               Should you require any further access to the information retained by
               us, you may contact us at: <a href="mailto:info@boo9.com">info@boo9.com</a>.
               <li>
                  We ensure to keep your data up to date. Further, you can request us
                  to <b>update/correct</b> your information which is in our records. To exercise this option,
                  you may reach out to us at: <a href="mailto:info@boo9.com">info@boo9.com</a>.
               </li>
               <li>
                  You can exercise your rights as a data subject via our Website per
                  the details contained within the present Policy. The aforementioned
                  exercise of your rights is free of charge.
               </li>
               <li>
                  You have the right to ask us to rectify information that you find
                  inaccurate, or to complete the information that is otherwise
                  incomplete.
               </li>
               <li>
                  You have the right to ask us to erase any and all personal data that
                  we have collected and are holding in certain circumstances. Please
                  note that we may not be able to honor all requests for deletion of
                  your personal data since certain information may be required to be
                  retained either to meet the requirements of law or for the purpose
                  of audit or for the purpose of maintaining business continuity. In
                  addition, if you have yourself posted information which is publicly
                  available, we may not be able to honor requests for deleting the
                  same.
                  <li>
                     You have the right to ask us to restrict the processing of your
                     personal data in certain circumstances.
                  </li>
               </li>
               <li>
                  You have the right to ask us to transfer the information that has
                  been shared by you from one organization to another, or ask for it
                  to be given back to you. But such right shall apply only if we are
                  processing the information of the user based on his consent, or we
                  are having a communication with the user for entering into a
                  contract and the processing is automated.
               </li>
               <li>
                  boo9 works to high standards when it comes to processing user’s
                  personal data. If you have any queries or concerns, you can address
                  the same vide complaint to the <a href="mailto:info@boo9.com">info@boo9.com</a>, and we shall duly
                  respond.
               </li>
               <li>
                  The Website may include links to third party Websites, plug-ins,
                  services, and applications. Clicking on such links or enabling
                  connections to such third party Websites, plug-ins, services, and
                  applications may allow respective third parties to collect or share
                  data about you. We neither control nor endorse these third party
                  services and are not responsible for their privacy statements. When
                  you leave the Website or access third party links through the
                  Website, we encourage you to read the privacy policy of such third
                  party Service Providers.
               </li>
               <li>
                  If we receive personal data about you from a third party, we will
                  protect it as set out in this Privacy Policy. If you are a third
                  party providing personal data about somebody else, you represent and
                  warrant that you have such person's consent to provide the personal
                  data to us.
               </li>
               <li>
                  You may choose to restrict the collection or use of your personal
                  data. If you have previously agreed to us using your personal data
                  for direct marketing purposes, you may change your mind at any time
                  by contacting us using the details below. If you ask us to restrict
                  or limit how we process your personal data, we will let you know how
                  the restriction affects your use of our Website or products and
                  services.
               </li>
               <li>
                  You may request details of the personal data that we hold about you.
                  You may request a copy of the personal data we hold about you. Where
                  possible, we will provide this information in CSV format or other
                  easily readable machine format. You may request that we erase the
                  personal data we hold about you at any time. You may also request
                  that we transfer this personal data to another third party.
               </li>
               <li>
                  If you believe that any information we hold about you is inaccurate,
                  out of date, incomplete, irrelevant or misleading, please contact us
                  using the details below. We will take reasonable steps to correct
                  any information found to be inaccurate, incomplete, misleading or
                  out of date.
               </li>
               <li>
                  We will comply with laws applicable to us in respect of any data
                  breach.
               </li>
               <li>
                  If you believe that we have breached a relevant data protection law
                  and wish to make a complaint, please contact us using the details
                  below and provide us with full details of the alleged breach. We
                  will promptly investigate your complaint and respond to you, in
                  writing, setting out the outcome of our investigation and the steps
                  we will take to deal with your complaint. You also have the right to
                  contact a regulatory body or data protection authority in relation
                  to your complaint.
               </li>
               <li>
                  To unsubscribe from our email database or opt-out of communications
                  (including marketing communications), please contact us using the
                  details below or opt-out using the opt-out facilities provided in
                  the communication.
               </li>
            </ol>
            <p className="summary-table">
               <b>In simple terms:</b> This is where our customers or subscribers
               have the power to invoke their rights on their data that they share
               with us.
            </p>
            <li className="header">CHANGES TO THIS POLICY</li>
            We may change this Policy in order to meet with the changing
            requirements of the business or law, from time-to-time, per our sole
            discretion. Any material changes or updates made to this Policy may be
            notified via a notice on our Website or via email to you. If you are a
            Service Provider, changes to this Website's Privacy Policy may be
            notified to you via your dashboard. Further, the Effective date
            subsequent to changes shall be indicated at the top section of this
            Policy with the title: "Last Updated On".
            <p className="summary-table">
               <b>In simple terms:</b> We tell you what changes we make to this
               document.
            </p>
            <li className="header">CONTACT US</li>
            If you have any queries, complaints, or concerns with regards to content
            and or breach of these terms, please immediately reach out to us at our
            email address, <a href="mailto:info@boo9.com">info@boo9.com</a>.
            <p className="summary-table">
               <b>In simple terms:</b> This is where you may tell us how annoyed or
               appreciative you are of our efforts to make you feel secure and safe
               about your data and privacy.
            </p>
         </ol>
               </Container>
         </div>
      </div>
   )
}

export default PrivacyPage;
