import React, { memo } from 'react';
import QuestionHintComponent from '../QuestionHintComponent';
import QueExplanationComponent from '../QueExplanationComponent';
import './styles.scss';
import Markdown from '../../Markdown';

const QuestionHeadComponent = ({
	data,
	// questionData,
	questionHintData,
	headText
	// questionType,
}) => {
	// const questionHead = () => {
	// 	if (questionType === 'TextAnswerQ') {
	// 		return headText;
	// 	}
	// 	return questionData;
	// };

	return (
		<>
			<h4>
				<Markdown>{headText}</Markdown>
				<QuestionHintComponent hints={questionHintData} />
				<QueExplanationComponent data={data} explanations={data.explanations} />
			</h4>
		</>
	);
};

export default memo(QuestionHeadComponent);
