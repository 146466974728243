/* eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import { ReactSVG } from "react-svg";
import { Button, Form, Container, Image } from "react-bootstrap";
import ErrorHandleComponent from "../../components/ErrorHandleComponent";
import Loader from "../../components/Loader";
import AlertComponent from "../../components/AuthComponents/AlertComponent";
import FormikErrorComponent from "../../components/AuthComponents/FormikErrorComponent";
import {
  getUserProfile,
  updateUser,
  currentSubscriptionPlane,
  paymentSessionCreate,
  stopAutoRecurring,
} from "../../redux/actions";
import { reset } from "../../redux/slices/userProfileSlice";
import editIcon from "../../assets/images/icons/edit-icon-white.svg";
import "./styles.scss";
import CustomModal from "../../components/CustomModal";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const shouldrender = useRef(true);
  const shouldRenderCurrentPlan = useRef(true);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [autoRenewalModal, setAutoRenewalModal] = useState(false);
  const [value, setValue] = useState("");
  const [file, setFile] = useState();
  const { authToken, isLoggedIn } = useSelector((state) => state.auth);
  const {
    loading,
    userData,
    updateUserData,
    currentSubscriptionData,
    getUserProfileError,
    updateError,
  } = useSelector((state) => state.profile);
  const {
    paymentSessionUrl,
    stopAutoRecurringSuccess,
    stopAutoRecurringError,
  } = useSelector((state) => state.payment);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [updateUserData]);

  useEffect(() => {
    if (shouldrender.current) {
      shouldrender.current = false;
      dispatch(getUserProfile());
    }
  }, [updateUserData]);

  useEffect(() => {
    if (!authToken) {
      window.location.href = "/";
    }
    if (shouldRenderCurrentPlan.current && isLoggedIn) {
      shouldRenderCurrentPlan.current = false;
      dispatch(currentSubscriptionPlane());
    }
    if (currentSubscriptionData?.status_code === 400 && isLoggedIn) {
      dispatch(paymentSessionCreate());
    }
  }, [currentSubscriptionData, isLoggedIn]);

  const handleClose = () => {
    setShow(false);
    dispatch(reset());
  };
  const handleShow = () => setShow(true);

  const initialValues = {};

  const handleSubmit = (values) => {
    shouldrender.current = true;
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    if (values.dob) {
      formData.append("dob", values.dob);
    }
    if (!isEmpty(value)) {
      formData.append("phone", value);
    }
    if (values.address) {
      formData.append("address", values.address);
    }
    dispatch(updateUser(formData));
    setShowAlert(true);
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (userData.phone) {
      setValue(userData.phone);
    }
    formik.setValues({
      first_name: userData.first_name || "",
      last_name: userData.last_name || "",
      username: userData.username || "",
      email: userData.email || "",
      phone: userData.phone || "",
      dob: userData.dob || "",
      address: userData.address || "",
    });
    if (userData.profile_url) {
      setFile(userData.profile_url);
    }
  }, [userData]);

  useEffect(() => {
    if (
      stopAutoRecurringSuccess &&
      stopAutoRecurringSuccess.status_code === 200
    ) {
      toast.success(stopAutoRecurringSuccess.message);
      dispatch(currentSubscriptionPlane());
    } else if (stopAutoRecurringError) {
      toast.error(stopAutoRecurringError);
    }
  }, [stopAutoRecurringSuccess, stopAutoRecurringError]);

  const handleChange = (e) => {
    shouldrender.current = true;
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("first_name", formik.values.first_name);
    formData.append("last_name", formik.values.last_name);
    if (formik.values.dob) {
      formData.append("dob", formik.values.dob);
    }
    if (!isEmpty(value)) {
      formData.append("phone", value);
    }
    if (formik.values.address) {
      formData.append("address", formik.values.address);
    }
    dispatch(updateUser(formData));
    setShowAlert(true);
  };
  const handleClick = () => setShowAlert(false);

  if (loading) {
    return (
      <div className="loader_section">
        <Loader />
      </div>
    );
  }

  if (!isEmpty(getUserProfileError))
    return <ErrorHandleComponent errorMessage={getUserProfileError.message} />;

  const modalTitle = "Update Personal Info";
  const modalContent = (
    <form action="#" onSubmit={formik.handleSubmit} className="profile_form">
      <div className="profile_form_wrapper">
        <div className="profile_form_input">
          <span>First Name</span>
          <Form.Control
            name="first_name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.first_name}
          />
          <FormikErrorComponent
            errorName={formik.errors.first_name}
            touched={formik.touched.first_name}
          />
        </div>
        <div className="profile_form_input">
          <span>Last Name</span>
          <Form.Control
            name="last_name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.last_name}
          />
          <FormikErrorComponent
            errorName={formik.errors.last_name}
            touched={formik.touched.last_name}
          />
        </div>
      </div>
      <div className="profile_form_input">
        <span>Date of birth</span>

        <Form.Control
          name="dob"
          type="date"
          min={moment(userData?.today, "ddd, DD MMM YYYY HH:mm:ss z")
            .subtract(100, "years")
            .format("YYYY-MM-DD")}
          max={moment(userData?.today, "ddd, DD MMM YYYY HH:mm:ss z").format(
            "YYYY-MM-DD"
          )}
          onChange={formik.handleChange}
          value={formik.values.dob}
        />
      </div>
      <div className="profile_form_input">
        <span>Phone</span>
        <PhoneInput
          name="phone"
          value={value}
          onChange={(e) => setValue(`+${e}`)}
        />
      </div>
      <div className="profile_form_input">
        <span>Address</span>

        <Form.Control
          name="address"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.address}
        />
      </div>
      {updateError &&
        Object.values(updateError).map((e) => {
          return e.map((element, index) => {
            return (
              <h6 key={index} className="edit_profile_error">
                {element}
              </h6>
            );
          });
        })}
      <Button variant="secondary" onClick={handleClose}>
        {" "}
        Cancel{" "}
      </Button>
      <Button type="submit" variant="primary" onClick={handleClose}>
        {" "}
        Save Changes{" "}
      </Button>
    </form>
  );

  const handleStopAutoRecurringModal = () => {
    setAutoRenewalModal(!autoRenewalModal);
  };

  const handleStopAutoRecurring = () => {
    dispatch(stopAutoRecurring());
    setAutoRenewalModal(false);
  };

  const autoRenewaFooter = [
    {
      label: "Cancel",
      variant: "danger",
      onClick: () => setAutoRenewalModal(false),
    },
    {
      label: "OK",
      variant: "primary",
      onClick: handleStopAutoRecurring,
    },
  ];

  const handleFilterDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  return (
    <div className="profile_page">
      <section className="inner_banner_section">
        <Container>
          <h1>
            {" "}
            {userData.first_name} {userData.last_name}{" "}
          </h1>
        </Container>
      </section>
      <section className="profile_info_section">
        <Container>
          <div className="profile_info_wrapper">
            <h2>
              <span>Personal Info</span>
            </h2>
            <div className="profile_info">
              <ReactSVG
                src={editIcon}
                className="edit_icon"
                onClick={handleShow}
              />
              <div className="profile_info_image">
                <Image src={file || "https://placeimg.com/171/180/any"} />
                <label htmlFor="image_upload" className="image_upload_label">
                  <input
                    type="file"
                    id="image_upload"
                    accept="image/*"
                    className="image_upload"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="profile_info_content">
                <h5>
                  <strong>Username</strong>: {userData.username}
                </h5>
                <h5>
                  <strong>Email</strong>: {userData.email}
                </h5>
                <h5>
                  <strong>Date of birth</strong>: {userData.dob}
                </h5>
                <h5>
                  <strong>Phone</strong>:{" "}
                  {formatPhoneNumberIntl(userData?.phone)}
                </h5>
                <h5>
                  <strong>Address</strong>: {userData.address}
                </h5>
              </div>
            </div>
            <CustomModal
              title={modalTitle}
              content={modalContent}
              show={show}
              handleClose={handleClose}
            />
            <AlertComponent
              handleClick={handleClick}
              show={showAlert}
              message={updateUserData?.message}
            />
          </div>
        </Container>
      </section>
      <section className="subscription_section">
        <Container>
          {userData.subscription_type === "paid" ? (
            <div className="subscription_wrapper">
              <h2>
                Subscription:&nbsp;
                <span>Premium</span>&nbsp; (Expires&nbsp;
                <span>
                  {moment(
                    currentSubscriptionData.plan_end_date,
                    "ddd, DD MMM YYYY HH:mm:ss z"
                  ).format("DD MMM YYYY")}
                </span>
                )
              </h2>
              <div>
                {currentSubscriptionData.auto_recurring ? (
                  <div>
                    <h3>
                      Your subscription will renew automatically 30 days before
                      expiry.
                    </h3>
                    <h3>
                      {" "}
                      You can
                      <Button
                        onClick={() => handleStopAutoRecurringModal()}
                        className="recurring_button"
                      >
                        opt out of auto renewal
                      </Button>{" "}
                      anytime.
                    </h3>
                    <br />
                  </div>
                ) : (
                  <div>
                    <h3>
                      You can renew for another year on or after:&nbsp;
                      <span>
                        {moment(
                          currentSubscriptionData.plan_end_date,
                          "ddd, DD MMM YYYY HH:mm:ss z"
                        )
                          .subtract(30, "days")
                          .format("DD MMM YYYY")}
                      </span>
                    </h3>
                    <h3>
                      While renewing you can opt for{" "}
                      <strong>auto renewal</strong>.
                    </h3>
                    <br />
                  </div>
                )}

                <h3>
                  Your discount code:{" "}
                  <span>
                    {currentSubscriptionData.coupon?.id}*&nbsp; (
                    {currentSubscriptionData.coupon?.name})
                  </span>
                </h3>
                <p>
                  *This is a lifetime discount, meaning{" "}
                  <strong>
                    if there is no break in the subscription, your % discount
                    will be valid for a lifetime
                  </strong>
                  . You will continue to receive the same % discount even if we
                  increase the subscription price later. This discount will be
                  void if there is a break in the subscription.
                </p>
              </div>
            </div>
          ) : (
            <div className="subscription_wrapper">
              <h2>
                Subscription:{" "}
                <span>
                  {userData?.user_data?.subscription_type ? (
                    <>
                      <span className="text-capatalize">
                        {userData?.user_data?.subscription_type}
                      </span>
                      {!["basic", "Basic"].includes(
                        userData?.user_data?.subscription_type
                      ) ? (
                        <span style={{ color: "black" }}> (Expires </span>
                      ) : null}
                      {userData?.user_data?.expiry_date &&
                      !["basic", "Basic"].includes(
                        userData?.user_data?.subscription_type
                      )
                        ? handleFilterDate(userData?.user_data?.expiry_date)
                        : !["basic", "Basic"].includes(
                            userData?.user_data?.subscription_type
                          )
                        ? "--"
                        : null}
                      {!["basic", "Basic"].includes(
                        userData?.user_data?.subscription_type
                      ) ? (
                        <span style={{ color: "black" }}>)</span>
                      ) : null}
                    </>
                  ) : (
                    "Basic"
                  )}
                </span>
              </h2>
              <div>
                {["Special1", "special1"].includes(
                  userData?.user_data?.subscription_type
                )
                  ? null
                  : !["premium", "Premium"].includes(
                      userData?.user_data?.subscription_type
                    ) && <h3>A premium account offers more benefits.</h3>}
                <div className="subscription_basic_buttons">
                  {["Special1", "special1"].includes(
                    userData?.user_data?.subscription_type
                  )
                    ? null
                    : !["premium", "Premium"].includes(
                        userData?.user_data?.subscription_type
                      ) && (
                        <Link to="/pricing" className="a">
                          Show me the benefits of premium
                        </Link>
                      )}
                  {!["premium", "Premium"].includes(
                    userData?.user_data?.subscription_type
                  ) && (
                    <Link
                      to={paymentSessionUrl}
                      className="a2"
                      target={"_blank"}
                    >
                      Upgrade to premium
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </section>
      <CustomModal
        title="Opt-Out of Auto-Renewal"
        content={<p>Are You Sure You Want to Opt-Out of Auto-Renewal?</p>}
        footerButtons={autoRenewaFooter}
        show={autoRenewalModal}
        handleClose={handleStopAutoRecurringModal}
      />
    </div>
  );
};

export default ProfilePage;
