import React from 'react';
import { useSelector } from 'react-redux';
import SignUp from '../SignUp';
import SignUpSuccessPage from '../SignupSuccessPage';

const SignUpPage = () => {
	const isSignedUp = useSelector((state) => state.auth.signupSuccessMessage);
	if (isSignedUp) {
		return (
			<div>
				<SignUpSuccessPage />
			</div>
		);
	}
	return (
		<div>
			<SignUp />
		</div>
	);
};

export default SignUpPage;
