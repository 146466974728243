import CheckmarkIcon from '../../assets/images/checkmark.png';
import CrossIcon from '../../assets/images/cross.png';

export const discounts = [
	{
		user_no: '1 - 100',
		discount_code: 'first100',
		discount: '60% (you pay $10/yr instead of $25/yr)'
	},
	{
		user_no: '101 - 1,000',
		discount_code: 'first1k',
		discount: '50% (you pay $12.50/yr instead of $25/yr)'
	},
	{
		user_no: '1,001 - 10,000',
		discount_code: 'first10k',
		discount: '40% (you pay $15/yr instead of $25/yr)'
	},
	{
		user_no: '10,001 - 100,000',
		discount_code: 'first100k',
		discount: '30% (you pay $17.50/yr instead of $25/yr)'
	},
	{
		user_no: '100,001 - 1,000,000',
		discount_code: 'first1M',
		discount: '20% (you pay $20/yr instead of $25/yr)'
	}
];

export const tableContents = [
	{
		tableHead: 'YouTube Videos',
		tableDocument: [
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Resources (FREE)',
		tableDocument: [
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Weekly Practice',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Timed Practice',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Instant Grading',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'On-demand Practice *1',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Adaptive Practice *2',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	},
	{
		tableHead: 'Dashboard *3',
		tableDocument: [
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CrossIcon, class: 'crossicon' },
			{ icon: CheckmarkIcon, class: 'checkmarkicon' }
		]
	}
];

export const tableHeadContents = [
	{ head: 'Products & Services' },
	{ head: 'Public: FREE', tooltip_data: 'No account required' },
	{ head: 'Basic: FREE', tooltip_data: 'No credit card required' },
	{
		head: 'Premium: $25/yr',
		tooltip_data: [
			'^*! See details below'
			// 'Discount for early users, details below!'
			// 'Add up to 5 additional users',
			// 'Discount for the first 1M users (see below)',
			// 'Also lifetime price guarantee for the first 1M users (see below)'
		]
	}
];
