/* eslint-disable */
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFlaggedQuestion, showLoginModalView } from "../../../redux/actions";

const CommonFlag = ({ data }) => {
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleFlaggingQuestion = (ques) => {
    if (userData?.payload?.username && ques.q_id) {
      dispatch(getFlaggedQuestion({ q_id: ques.q_id, ques: ques }));
    } else {
      dispatch(showLoginModalView(true));
    }
  };
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Flag this question</Tooltip>}
      >
        <FontAwesomeIcon
          icon={faFlag}
          className="text-light2"
          onClick={() => handleFlaggingQuestion(data)}
        />
      </OverlayTrigger>
    </div>
  );
};

export default CommonFlag;
