export const passwordValidation = (passwordMsg) => {
	const validatePassword = {};
	if (passwordMsg.match(/[a-z]/)) {
		validatePassword.passwordLowerCaseError = true;
	} else {
		validatePassword.passwordLowerCaseError = false;
	}
	if (passwordMsg.match(/[A-Z]/)) {
		validatePassword.passwordUpperCaseError = true;
	} else {
		validatePassword.passwordUpperCaseError = false;
	}
	if (passwordMsg.match(/[0-9]/)) {
		validatePassword.passwordNumberError = true;
	} else {
		validatePassword.passwordNumberError = false;
	}
	if (passwordMsg.match(/[$%*&_]/)) {
		validatePassword.passwordSpecialCharError = true;
	} else {
		validatePassword.passwordSpecialCharError = false;
	}
	if (passwordMsg.length >= 8 && passwordMsg.length <= 16) {
		validatePassword.passwordLengthError = true;
	} else {
		validatePassword.passwordLengthError = false;
	}
	return validatePassword;
};
export const usernameValidation = (userName) => {
	const validateUsername = {};
	if (userName.length >= 6 && userName.length <= 16) {
		validateUsername.usernameLengthError = true;
	} else {
		validateUsername.usernameLengthError = false;
	}
	if (userName.match(/^[a-zA-Z]/)) {
		validateUsername.usernameLetterError = true;
	} else {
		validateUsername.usernameLetterError = false;
	}
	if (/^[A-Za-z0-9_]+$/.test(userName)) {
		validateUsername.usernameCharactorError = true;
	} else {
		validateUsername.usernameCharactorError = false;
	}
	return validateUsername;
};
