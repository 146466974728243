/* eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";
import {
  practiceSets,
  practiceSections,
  practiceSectionsSubmit,
  saveAsDraft,
  handleCounterGet,
  handleCounterPost,
  timedSaveAsDraft,
  getTimedSaveAsDraftData,
  adminPracticeSets,
  adminPracticeSections,
  adminPracticeSectionsSubmit,
  handleAdminCounterGet,
  handleAdminCounterPost,
  adminTimedSaveAsDraft,
  getAdminTimedSaveAsDraftData,
  getOndemandPracticeJson,
  sendOnDemandAdaptiveParameters,
  storePracticeData,
  sendOndemandParameters,
  updateOndemandAdaptiveCount,
  getOndemandAdaptiveCount,
  getGradesWeekly,
  getGradesOndemandAdaptive,
  practiceSectionsOndemandAdaptive,
  getFlaggedQuestion,
  hidelFaggedQuestionModal,
  storeCurrentQuestion,
  showLoginModalView,
  storeCurrentQuestionAns,
} from "../actions";

export const practiceSlice = createSlice({
  name: "practiceSets",
  initialState: {
    loading: false,
    timedSectionLoading: false,
    practiceSetData: [],
    practiceSectionData: {},
    sectionId: "",
    practiceSetsErrorMessage: "",
    practiceSectionErrorMessage: {},
    answerSubmitted: false,
    saveAsDraftSuccess: false,
    alertSuccessMessage: {},
    alertErrorMessage: "",
    alert: false,
    activeAccordion: null,
    baseUrlForPracticeImg: "",
    baseUrlForSectionImg: "",
    counterResponse: null,
    counterPostResponse: null,
    timedSaveAsDraftResponse: null,
    getTimedSaveAsDraftDataResponse: null,
    shouldShowAns: false,
    ondemandPracticeJson: [],
    ondemandAdaptiveData: {},
    setStore: {},
    countResponse: {},
    onAdtestCounts: [],
    gradesWeekly: [],
    ondemandAdaptiveGrades: {},
    flaggedQuestion: {},
    flagQuestionModal: false,
    currentQuestion: {},
    currentQuestionAns: {},
    showLoginModal: false,
  },
  reducers: {
    setSectionId: (state, { payload }) => {
      return { ...state, sectionId: payload };
    },
    resetFormData: (state, { payload }) => {
      return {
        ...state,
        practiceSectionData: {},
        answerSubmitted: false,
        saveAsDraftSuccess: false,
        alertSuccessMessage: {},
        alertErrorMessage: "",
        counterResponse: null,
        counterPostResponse: null,
        timedSaveAsDraftResponse: null,
        getTimedSaveAsDraftDataResponse: null,
      };
    },
    currentAccordion: (state, { payload }) => {
      return {
        ...state,
        activeAccordion: payload,
      };
    },
    setShouldShowAns: (state, { payload }) => {
      return {
        ...state,
        shouldShowAns: payload,
      };
    },
  },
  extraReducers: {
    [practiceSets.pending]: (state) => {
      return { ...state, loading: true };
    },
    [practiceSets.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        practiceSetData: payload,
        baseUrlForPracticeImg: payload?.s3_base_url,
        loading: false,
        practiceSetsErrorMessage: "",
      };
    },
    [practiceSets.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSetsErrorMessage: payload,
      };
    },
    [adminPracticeSets.pending]: (state) => {
      return { ...state, loading: true };
    },
    [adminPracticeSets.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        practiceSetData: payload,
        baseUrlForPracticeImg: payload?.s3_base_url,
        loading: false,
        practiceSetsErrorMessage: "",
      };
    },
    [adminPracticeSets.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSetsErrorMessage: payload,
      };
    },
    [practiceSections.pending]: (state) => {
      return { ...state, loading: true };
    },
    [practiceSections.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionData: payload,
        answerSubmitted: false,
        saveAsDraftSuccess: false,
        practiceSectionErrorMessage: "",
        baseUrlForSectionImg: payload?.s3_base_url,
      };
    },
    [practiceSections.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionErrorMessage: payload,
      };
    },

    [practiceSectionsOndemandAdaptive.pending]: (state) => {
      return { ...state, loading: true };
    },
    [practiceSectionsOndemandAdaptive.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionData: payload,
        answerSubmitted: false,
        saveAsDraftSuccess: false,
        practiceSectionErrorMessage: "",
        baseUrlForSectionImg: payload?.s3_base_url,
      };
    },
    [practiceSectionsOndemandAdaptive.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionErrorMessage: payload,
      };
    },

    [adminPracticeSections.pending]: (state) => {
      return { ...state, loading: true };
    },
    [adminPracticeSections.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionData: payload,
        answerSubmitted: false,
        saveAsDraftSuccess: false,
        practiceSectionErrorMessage: "",
        baseUrlForSectionImg: payload?.s3_base_url,
      };
    },
    [adminPracticeSections.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        practiceSectionErrorMessage: payload,
      };
    },
    [practiceSectionsSubmit.pending]: (state) => {
      return { ...state, loading: true };
    },
    [practiceSectionsSubmit.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        alertSuccessMessage: payload,
        answerSubmitted: true,
        alert: true,
      };
    },
    [practiceSectionsSubmit.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        alertErrorMessage: payload,
        answerSubmitted: false,
        alert: false,
      };
    },
    [adminPracticeSectionsSubmit.pending]: (state) => {
      return { ...state, loading: true };
    },
    [adminPracticeSectionsSubmit.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        alertSuccessMessage: payload,
        answerSubmitted: true,
        alert: true,
      };
    },
    [adminPracticeSectionsSubmit.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        alertErrorMessage: payload,
        answerSubmitted: false,
        alert: false,
      };
    },
    [saveAsDraft.pending]: (state) => {
      return { ...state, loading: true };
    },
    [saveAsDraft.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        alertSuccessMessage: payload,
        saveAsDraftSuccess: true,
        alert: true,
      };
    },
    [saveAsDraft.rejected]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        alertErrorMessage: payload,
        saveAsDraftSuccess: false,
        alert: false,
      };
    },
    [handleCounterGet.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [handleCounterGet.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        counterResponse: payload,
      };
    },
    [handleCounterGet.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        counterResponse: payload,
      };
    },
    [handleAdminCounterGet.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [handleAdminCounterGet.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        counterResponse: payload,
      };
    },
    [handleAdminCounterGet.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        counterResponse: payload,
      };
    },
    [handleCounterPost.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [handleCounterPost.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        counterPostResponse: payload,
      };
    },
    [handleCounterPost.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        counterPostResponse: payload,
      };
    },
    [handleAdminCounterPost.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [handleAdminCounterPost.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        counterPostResponse: payload,
      };
    },
    [handleAdminCounterPost.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        counterPostResponse: payload,
      };
    },
    [timedSaveAsDraft.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [timedSaveAsDraft.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        timedSaveAsDraftResponse: payload,
      };
    },
    [timedSaveAsDraft.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        timedSaveAsDraftResponse: payload,
      };
    },
    [adminTimedSaveAsDraft.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [adminTimedSaveAsDraft.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        timedSaveAsDraftResponse: payload,
        alert: true,
      };
    },
    [adminTimedSaveAsDraft.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        timedSaveAsDraftResponse: payload,
      };
    },
    [getTimedSaveAsDraftData.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getTimedSaveAsDraftData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },
    [getTimedSaveAsDraftData.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },
    [getAdminTimedSaveAsDraftData.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getAdminTimedSaveAsDraftData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },
    [getAdminTimedSaveAsDraftData.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },

    [getOndemandPracticeJson.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getOndemandPracticeJson.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        ondemandPracticeJson: payload,
      };
    },
    [getOndemandPracticeJson.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },

    [sendOnDemandAdaptiveParameters.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [sendOnDemandAdaptiveParameters.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        ondemandAdaptiveData: payload,
      };
    },
    [sendOnDemandAdaptiveParameters.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },

    [sendOndemandParameters.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [sendOndemandParameters.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        ondemandAdaptiveData: payload,
      };
    },
    [sendOndemandParameters.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        getTimedSaveAsDraftDataResponse: payload,
      };
    },

    [storePracticeData.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [storePracticeData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        setStore: payload,
      };
    },
    [storePracticeData.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [updateOndemandAdaptiveCount.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [updateOndemandAdaptiveCount.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        countResponse: payload,
      };
    },
    [updateOndemandAdaptiveCount.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [getOndemandAdaptiveCount.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getOndemandAdaptiveCount.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        onAdtestCounts: payload,
      };
    },
    [getOndemandAdaptiveCount.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [getGradesWeekly.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getGradesWeekly.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        gradesWeekly: payload,
      };
    },
    [getGradesWeekly.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [getGradesOndemandAdaptive.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getGradesOndemandAdaptive.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        ondemandAdaptiveGrades: payload,
      };
    },
    [getGradesOndemandAdaptive.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [getFlaggedQuestion.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [getFlaggedQuestion.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
        flagQuestionModal: true,
        flaggedQuestion: payload,
      };
    },
    [getFlaggedQuestion.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [hidelFaggedQuestionModal.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [hidelFaggedQuestionModal.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        flagQuestionModal: false,
        timedSectionLoading: false,
      };
    },
    [hidelFaggedQuestionModal.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [storeCurrentQuestion.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [storeCurrentQuestion.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        currentQuestion: payload,
      };
    },
    [storeCurrentQuestion.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [storeCurrentQuestionAns.pending]: (state) => {
      return { ...state };
    },
    [storeCurrentQuestionAns.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        currentQuestionAns: payload,
      };
    },
    [storeCurrentQuestionAns.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },

    [showLoginModalView.pending]: (state) => {
      return { ...state, timedSectionLoading: true };
    },
    [showLoginModalView.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        showLoginModal: payload,
      };
    },
    [showLoginModalView.rejected]: (state, { payload }) => {
      return {
        ...state,
        timedSectionLoading: false,
      };
    },
  },
});
export const {
  setSectionId,
  resetFormData,
  currentAccordion,
  setShouldShowAns,
} = practiceSlice.actions;
export default practiceSlice.reducer;
