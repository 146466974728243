import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';

// This is a replica of: .../boo9_web/src/components/Markdown/index.js
// https://github.com/remarkjs/react-markdown/issues/42
const Markdown = ({ children }) => {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkMath]}
			rehypePlugins={[rehypeKatex, rehypeRaw]}
			components={{
				p: React.Fragment
			}}
			// allowedElements={['h1', 'image']}
		>
			{children}
		</ReactMarkdown>
	);
};

export default Markdown;
