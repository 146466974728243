/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import {
  userScoreHistory,
  currentSubscriptionPlane,
  getUserProfile,
  paymentSessionCreate,
  adminPracticeSets,
  practiceSets,
  getGradesWeekly,
  getGradesOndemandAdaptive,
  practiceSectionsOndemandAdaptive,
} from "../../redux/actions";
import ChartComponent from "../../components/ChartComponent";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import timeIcon from "../../assets/images/icons/timer-icon.svg";
import starIcon from "../../assets/images/star.png";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";

const DashboardPage = () => {
  const today = new Date();
  const fourWeeksAgo = new Date(today);
  fourWeeksAgo.setDate(today.getDate() - 28);
  const [startDate, setStartDate] = useState(
    fourWeeksAgo.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldRenderCurrentPlan = useRef(true);
  const { authToken, isLoggedIn } = useSelector((state) => state.auth);
  const { userLastWeekScore, loading, userData, currentSubscriptionData } =
    useSelector((state) => state.profile);
  const {
    practiceSetData,
    practiceSetsErrorMessage,
    ondemandPracticeJson,
    onAdtestCounts,
    gradesWeekly,
    ondemandAdaptiveGrades,
  } = useSelector((state) => state.practiceSet);
  const {
    paymentSessionUrl,
    stopAutoRecurringSuccess,
    stopAutoRecurringError,
  } = useSelector((state) => state.payment);
  useEffect(() => {
    if (!authToken) {
      window.location.href = "/";
    }
    if (shouldRenderCurrentPlan.current && isLoggedIn) {
      shouldRenderCurrentPlan.current = false;
      dispatch(currentSubscriptionPlane());
    }
    if (currentSubscriptionData?.status_code === 400 && isLoggedIn) {
      dispatch(paymentSessionCreate());
    }
  }, [currentSubscriptionData, isLoggedIn]);

  useEffect(() => {
    if (!authToken) {
      window.location.href = "/";
    }
    dispatch(getUserProfile());
    dispatch(userScoreHistory());
    dispatch(currentSubscriptionPlane());
  }, []);

  useEffect(() => {
    const fetchPracticeSets = async () => {
      dispatch(getGradesWeekly({ user_id: userData?.user_data?._id }));
      dispatch(
        getGradesOndemandAdaptive({ user_id: userData?.user_data?._id })
      );
    };
    userData?.user_data?._id && fetchPracticeSets();
  }, [userData]);

  const handleClickWeekly = (url, setId) => {
    const practiceUrl = `/dashboard${url}?id=${setId}`;
    navigate(practiceUrl);
  };

  const handleClick = (url, setId, parseData, id) => {
    const data = {
      created_at: "",
      draft: { attempted: false },
      enumerate_qs: parseData?.q_set?.enumerate_qs,
      id: id,
      s3_base_url: "",
      set_description: parseData?.q_set?.set_description,
      set_id: parseData?.q_set?.set_id,
      set_name: parseData?.q_set?.set_name,
      submitted: {
        attempted: true,
        data: {
          total_noq_notgraded: parseData?.g_set?.total_noq_notgraded ?? null,
          total_score: parseData?.g_set?.total_score,
          total_score_outof: parseData?.g_set?.total_score_outof,
          total_score_pct: parseData?.g_set?.total_score_pct,
        },
      },
      subscription_type: "",
      time_hrs: parseData?.q_set?.time_hrs,
      time_mins: parseData?.q_set?.time_mins,
      timed: parseData?.q_set?.timed,
      list_sections: [
        {
          access_type: parseData?.q_set?.list_sections?.[0]?.access_type,
          section_noq_notgraded:
            parseData?.g_set?.list_sections?.[0]?.section_noq_attempted,
          section_score: parseData?.g_set?.list_sections?.[0]?.section_score,
          section_score_outof:
            parseData?.g_set?.list_sections?.[0]?.section_score_outof,
          section_title: parseData?.q_set?.list_sections?.[0]?.section_title,
          sno: parseData?.q_set?.list_sections?.[0]?.sno,
          list_qgs: [
            {
              description:
                parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]
                  ?.description,
              gr_type:
                parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.gr_type,
              group_title:
                parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]
                  ?.group_title,
              notes: parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.notes,
              tags: parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.tags,
              list_ques:
                parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.list_ques?.map(
                  (item, index) => ({
                    ...item,
                    attempted_obj:
                      parseData?.a_set?.list_sections?.[0]?.list_ags?.[0]
                        ?.list_ans?.[index]?.stud_ans,
                    ans_obj:
                      parseData?.g_set?.list_sections?.[0]?.list_ggs?.[0]
                        ?.list_graded[index],
                  })
                ),
            },
          ],
        },
      ],
    };
    dispatch(practiceSectionsOndemandAdaptive(data));
    const practiceUrl = `/dashboard${url}?sid=${setId}`;
    navigate(practiceUrl);
  };

  const handleEndDate = (e, bool) => {
    setShowToast(true);
    const dateValue = e.target.value;
    bool ? setEndDate(dateValue) : setStartDate(dateValue);
    if ((bool && dateValue > startDate) || (!bool && dateValue < endDate)) {
      dispatch(
        getGradesWeekly({
          user_id: userData?.user_data._id,
          start_date: bool ? startDate : dateValue,
          end_date: bool ? dateValue : endDate,
        })
      );
      dispatch(
        getGradesOndemandAdaptive({
          user_id: userData?.user_data._id,
          start_date: bool ? startDate : dateValue,
          end_date: bool ? dateValue : endDate,
        })
      );
    } else {
      toast.error("End date should be greater than start date");
    }
  };

  const handleDataString = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const expiryDate = new Date(userData?.user_data?.expiry_date);
  expiryDate.setDate(expiryDate.getDate() + 1);
  const currentDate = new Date();
  const validSubscriptionTypes = ["Special1", "special1", "Premium", "premium"];
  const isValidUser =
    expiryDate > currentDate &&
    (validSubscriptionTypes.includes(userData?.user_data?.subscription_type) ||
      validSubscriptionTypes.includes(userData?.subscription_type));

  const isBasicUser =
    ["Free", "free", "Basic", "basic"].includes(userData?.subscription_type) ||
    ["Free", "free", "Basic", "basic"].includes(
      userData?.user_data?.subscription_type
    );
  return (
    <div className="profile_page">
      <section className="inner_banner_section">
        <Container>
          <h1> Dashboard </h1>
        </Container>
      </section>
      <section className="history_section">
        <Container>
          <div className="history_wrapper">
            <h2>Score History</h2>
            <div className="history_chart">
              <ChartComponent
                userLastWeekScore={userLastWeekScore?.data}
                loading={loading}
              />
            </div>
          </div>
          <div className="history_wrapper">
            {userData.subscription_type === "paid" ? (
              <h3>Past Result</h3>
            ) : isBasicUser ? (
              <div className="mt-4">
                <hr />
                <h3>To view more of the dashboard, subscribe to the Premium</h3>
                <Link
                  to={paymentSessionUrl}
                  className="a2-subscription"
                  target={"_blank"}
                >
                  Upgrade to premium
                </Link>
              </div>
            ) : null}
          </div>
        </Container>
      </section>

      {isValidUser ? (
        <section>
          <h2 className="mb-2 mt-4 text-center">
            Practice history for the last 4 weeks
          </h2>
          <div className="App date-container">
            {/* <p className="mb-0 mt-0 fw-semibold">
              Practice history for the last 4 weeks
              </p> */}
            {/* <Form.Control
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={startDate}
              onChange={(e) => handleEndDate(e, false)}
              />
              
              <Form.Control
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={endDate}
              onChange={(e) => handleEndDate(e, true)}
              /> */}
          </div>

          <div className="d-flex justify-content-center">
            <hr className="width-82" />
          </div>
          <Container className="shadow-space pt-5 pb-3">
            <Accordion className="cs-accordion dash-bord">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Weekly :</Accordion.Header>

                <Accordion.Body className="">
                  <div className="header-row d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between p-3 fw-semibold bg-light">
                    <div className="w-40">Practice set</div>
                    <div className="w-20">Date</div>
                    <div className="w-20 text-center">
                      No. of questions answered
                    </div>
                    <div className="w-20 text-end ">Score</div>
                  </div>
                  {gradesWeekly?.data?.length ? (
                    gradesWeekly?.data?.map((item, index) => {
                      const listSections =
                        item?.result_data?.dict_gset?.list_sections;
                      let totalAttempted = 0;
                      if (listSections) {
                        totalAttempted = listSections.reduce(
                          (sum, ques) =>
                            sum + (ques?.section_noq_attempted || 0),
                          0
                        );
                      }
                      return (
                        <div className="accordion-inner" key={index}>
                          <ul className="subject-row p-2 mb-0 list-unstyled subject-english d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between">
                            <li
                              className="d-flex gap-1 align-items-center link-cursor-pointer w-40"
                              onClick={() =>
                                handleClickWeekly(`/practice`, item.set_id)
                              }
                            >
                              <p className="mb-0">{item?.set_name}</p>
                            </li>

                            <li className="d-flex gap-1 align-items-center w-20">
                              <p className="mb-0">
                                {handleDataString(item?.updated_at)}
                              </p>
                            </li>

                            <li className="d-flex gap-1 align-items-center w-20 justify-content-center">
                              {totalAttempted}
                            </li>

                            <li className="d-flex gap-1 align-items-center justify-content-end w-20">
                              <p className="mb-0">
                                <span className="percentage-text">
                                  {
                                    item?.result_data?.dict_gset
                                      ?.total_score_pct
                                  }
                                  %
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <div className="m-auto">Data not found!</div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="cs-accordion dash-bord">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Ondemand :</Accordion.Header>
                <Accordion.Body className="">
                  <div className="header-row d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between p-3 fw-semibold bg-light">
                    <div className="w-40">Practice set</div>
                    <div className="w-20">Date</div>
                    <div className="w-20 text-center">
                      No. of questions viewed
                    </div>
                    <div className="w-20 text-end ">Score</div>
                  </div>
                  {ondemandAdaptiveGrades?.ondemand_list?.length ? (
                    ondemandAdaptiveGrades?.ondemand_list?.map(
                      (item, index) => {
                        const parseData = JSON.parse(item?.end_result?.data);
                        return (
                          <div className="accordion-inner" key={index}>
                            <ul className="subject-row p-2 mb-0 list-unstyled subject-english d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between">
                              <li
                                className="d-flex gap-1 align-items-center link-cursor-pointer w-40"
                                onClick={() =>
                                  handleClick(
                                    `/practice`,
                                    item.sid,
                                    parseData,
                                    item?._id
                                  )
                                }
                              >
                                <p className="mb-0">
                                  {parseData?.q_set?.set_name}
                                </p>
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20">
                                <p className="mb-0">
                                  {handleDataString(item?.end_time)}
                                </p>
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20 justify-content-center">
                                {
                                  parseData?.q_set?.list_sections?.[0]
                                    ?.list_qgs?.[0]?.list_ques?.length
                                }
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20 justify-content-end text-end">
                                <p className="mb-0">
                                  <span className="percentage-text text-end w-100">
                                    {parseData?.g_set?.total_score_pct}%
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="m-auto">Data not found!</div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="cs-accordion dash-bord">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Adaptive :</Accordion.Header>
                <Accordion.Body className="">
                  <div className="header-row d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between p-3 fw-semibold bg-light">
                    <div className="w-40">Practice set</div>
                    <div className="w-20">Date</div>
                    <div className="w-20 text-center">
                      No. of questions viewed
                    </div>
                    <div className="w-20 text-end ">Score</div>
                  </div>
                  {ondemandAdaptiveGrades?.adaptive_list?.length ? (
                    ondemandAdaptiveGrades?.adaptive_list?.map(
                      (item, index) => {
                        const parseData = JSON.parse(item?.end_result?.data);
                        return (
                          <div className="accordion-inner" key={index}>
                            <ul className="subject-row px-3 py-2 mb-0 list-unstyled subject-english d-flex flex-md-nowrap flex-wrap gap-2 justify-content-between">
                              <li
                                className="d-flex gap-1 align-items-center link-cursor-pointer w-40"
                                onClick={() =>
                                  handleClick(
                                    `/practice`,
                                    item.sid,
                                    parseData,
                                    item?._id
                                  )
                                }
                              >
                                <p className="mb-0">
                                  {parseData?.q_set?.set_name}
                                </p>
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20">
                                <p className="mb-0">
                                  {handleDataString(item?.end_time)}
                                </p>
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20 justify-content-center">
                                {
                                  parseData?.q_set?.list_sections?.[0]
                                    ?.list_qgs?.[0]?.list_ques?.length
                                }
                              </li>

                              <li className="d-flex gap-1 align-items-center w-20 justify-content-end text-end">
                                <p className="mb-0">
                                  <span className="percentage-text text-end w-100">
                                    {parseData?.g_set?.total_score_pct}%
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="m-auto">Data not found!</div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </section>
      ) : null}
    </div>
  );
};

export default DashboardPage;
