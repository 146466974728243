import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import PasswordFloatingLabel from '../../../components/AuthComponents/PasswordFloatingLabel';
import AlertComponent from '../../../components/AuthComponents/AlertComponent';
import Loader from '../../../components/Loader';
import FormikErrorComponent from '../../../components/AuthComponents/FormikErrorComponent';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import { passwordValidation } from '../../../utils/Authentication';
import { changePassword } from '../../../redux/actions';
import passwordIcon from '../../../assets/images/lock.png';
import eyeIcon from '../../../assets/images/eye.png';
import eyeCloseIcon from '../../../assets/images/eye_close.png';
import './styles.scss';

const ChangePassword = () => {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const { loading, changePasswordResponse, error } = useSelector((state) => state.profile);
	const [values, setValues] = useState({ showCurrentP: false, showConfirmP: false });

	useEffect(() => {
		const timeId = setTimeout(() => {
			setShow(false);
		}, 3000);
		return () => {
			clearTimeout(timeId);
		};
	}, [changePasswordResponse]);

	const onSubmit = () => {
		setShow(true);
		const formData = new FormData();
		formData.append('current_password', formik.values.current_password);
		formData.append('password', formik.values.password);
		formData.append('confirm_password', formik.values.confirm_password);
		dispatch(changePassword(formData));
		formik.resetForm();
	};

	const initialValues = {
		current_password: '',
		password: '',
		confirm_password: ''
	};
	const validationSchema = Yup.object({
		current_password: Yup.string().required('Please enter current password'),
		password: Yup.string()
			.matches(
				/(?=.*\d)(?=.*[_$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}/,
				'Password must be in valid format'
			)
			.min(8)
			.max(16)
			.required('Please enter password'),
		confirm_password: Yup
			.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must be same')
			.required('Please enter Confirm password')
	});
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit
	});
	const handleClick = () => setShow(false);

	const passwordClick = (element) => {
		if (element === 'currentpassword') { setValues({ ...values, showCurrentP: !values.showCurrentP }); }
		if (element === 'confirmpassword') { setValues({ ...values, showConfirmP: !values.showConfirmP }); }
	};

	const passwordCheck = passwordValidation(formik.values.password);
	return (
		<div className='change_password_page'>
			<AlertComponent
				handleClick={handleClick}
				show={show}
				message={changePasswordResponse}
			/>
			<div className='change_password_form'>
				<div className='change_password_form_wrapper'>
					<h2>Change Password</h2>
					<Form className='reset_form_handle' onSubmit={formik.handleSubmit}>
						<div className="main-form-floating">
							<FloatingLabel
								label='Current Password'
								htmlFor='current_password'
							>
								<span className='icon'>
									<img src={passwordIcon} />
								</span>
								<Form.Control
									type={values.showCurrentP ? 'text' : 'password'}
									id='current_password'
									value={formik.values.current_password}
									onChange={formik.handleChange}
									placeholder='current_password'
								/>
								<span className='eye-icon' onClick={() => passwordClick('currentpassword')}>
									<img src={values.showCurrentP ? eyeCloseIcon : eyeIcon} />
								</span>
							</FloatingLabel>
							<FormikErrorComponent
								errorName={formik.errors.current_password}
								touched={formik.touched.current_password}
							/>
							<PasswordFloatingLabel
								passwordCheck={passwordCheck}
								formikTouched={formik.touched}
								formikErrors={formik.errors}
								formikValues={formik.values}
								onChange={formik.handleChange}
							/>
							<FormikErrorComponent
								errorName={formik.errors.password}
								touched={formik.touched.password}
							/>
							<FloatingLabel
								label='Confirm Password'
								htmlFor='confirm_password'
							>
								<span className='icon'>
									<img src={passwordIcon} />
								</span>
								<Form.Control
									type={values.showConfirmP ? 'text' : 'password'}
									id='confirm_password'
									value={formik.values.confirm_password}
									onChange={formik.handleChange}
									placeholder='confirm_password'
								/>
								<span className='eye-icon' onClick={() => passwordClick('confirmpassword')}>
									<img src={values.showConfirmP ? eyeCloseIcon : eyeIcon} />
								</span>
							</FloatingLabel>
							<FormikErrorComponent
								errorName={formik.errors.confirm_password}
								touched={formik.touched.confirm_password}
							/>
						</div>
						<Button variant='primary' type='submit' disabled={loading}>
							{loading ? <Loader /> : 'Change Password'}
						</Button>
					</Form>
					<ErrorHandleComponent errorMessage={error} />
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
