/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";
import {
  sendOndemandParameters,
  updateOndemandAdaptiveCount,
} from "../../../redux/actions";
// import './styles.scss';
import Accordion from "react-bootstrap/Accordion";
import infoIcon from "../../../assets/images/icons/info.svg";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoIcon2 from "../../../assets/images/icons/info.png";

// WEEKLY POPUP

//   ON-DEMAND POPUP

const OnDemandCenteredModal = (props) => {
  const dispatch = useDispatch();
  const [letAI, setLetAI] = useState(true);
  const [includePreviousChapters, setIncludePreviousChapters] = useState(false);
  const [includeBoosterQuestions, setIncludeBoosterQuestions] = useState(false);
  const [loaderstatus, setLoaderstatus] = useState(false);
  const navigate = useNavigate();

  const timeStamp = (hours, minutes) => {
    return (hours * 3600 + minutes * 60) * 1000;
  };
  const { userData } = useSelector((state) => state.profile);
  const authData = useSelector((state) => state.auth);

  const location = useLocation();
  const isAdmin =
    authData?.userData?.payload?.is_admin &&
    location.pathname.startsWith("/admin/practice");
  const startTest = () => {
    localStorage.setItem("let_ai", letAI);
    localStorage.setItem("include_prev", includePreviousChapters);
    localStorage.setItem("rank_booster", includeBoosterQuestions);

    setLoaderstatus(true);
    localStorage.setItem("sidId", props.sidid);
    dispatch(
      updateOndemandAdaptiveCount({
        user: isAdmin ? "admin" : "user",
        testType: "ondemand",
        username: userData?.user_data?.username,
        email: userData?.user_data?.email,
        userId: userData?.user_data?._id,
        sid: props.sidid,
        setTitle: props.titleset,
        startDate: new Date(),
      })
    );
    const data = {
      sid: props.sidid,
      lot__qid_rite: [],
      list_uans: [],
      finish: false,
      list_info: [],
      set_info: null,
      user_params: {
        let_ai: letAI,
        include_prev: includePreviousChapters,
        rank_booster: includeBoosterQuestions,
      },
    };
    dispatch(sendOndemandParameters(data))
      .unwrap()
      .then((res) => {
        if (res) {
          setLoaderstatus(false);
          localStorage.setItem(
            "time",
            Date.now() + timeStamp(props.mhours, props.minutes)
          );
          localStorage.setItem("is_admin", isAdmin ? true : false);
          isAdmin
            ? navigate("/admin/practices/ondemand")
            : navigate("/user/practices/ondemand");
        }
      });
  };

  const handleResetFields = () => {
    setLetAI(true);
    setIncludeBoosterQuestions(false);
    setIncludePreviousChapters(false);
  };

  useEffect(() => {
    if (props.show === false) {
      handleResetFields();
    }
  }, [props.show]);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    if (isNaN(date)) {
      return;
    }
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatDateNextTimeTest = (isoDateTime) => {
    const date = new Date(isoDateTime);
    if (isNaN(date)) {
      return;
    }
    date.setHours(date.getHours() + 48);

    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="cs-modal"
    >
      {loaderstatus ? (
        <div className="loader-ondemand-adaptive position-absolute start-50 top-50 translate-middle">
          <Loader />
        </div>
      ) : null}

      {props?.dataset?.submitted ||
      props?.dataset?.draft_save ||
      !props?.givenTest ? (
        <div>
          <Modal.Header closeButton className="cs-modal-heading">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="text-center h4 fw-bold"
            >
              <span className="fw-normal d-block">On-demand Practice</span>{" "}
              {props.testName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="cs-modal-body">
            <div className="modal-footerr px-2 py-4 text-center">
              <p className="mb-2">
                Sorry, too early to do this practice again ...
              </p>
              <p className="mb-2">
                You last did this practice:{" "}
                <span className="text-blue">
                  {formatDate(props?.testDateTime)}
                </span>
              </p>
              <p className="mb-2">
                You can do this practice again on or after:{" "}
                <span className="text-orange">
                  {formatDateNextTimeTest(props?.testDateTime)}
                </span>
              </p>
            </div>
          </Modal.Body>
        </div>
      ) : (
        props?.givenTest && (
          <div>
            <Modal.Header closeButton className="cs-modal-heading">
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="text-center h4 fw-bold"
              >
                <span className="fw-normal d-block">On-demand Practice</span>{" "}
                {props.testName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="cs-modal-body">
              <div className="timed_modal_info_wrap mw-600px">
                <div className="timed_modal_info">
                  <div className="timed_modal_info_box">
                    <h4>{props?.mques}</h4>
                    <h5>Maximum no. of questions</h5>
                  </div>
                  <div className="timed_modal_info_box">
                    <h4>
                      {props?.mhours}:{props?.minutes}
                    </h4>
                    <h5>Session times out after</h5>
                  </div>
                </div>
              </div>
              {props.userparams === null ? null : (
                <Accordion className="cs-accordion2">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Personalize it even more? (Optional)
                    </Accordion.Header>
                    <Accordion.Body className="">
                      <Form>
                        <div className="accordion-inner">
                          <div className="d-flex gap-2 cs-switch-modes mb-4 pb-4 fw-medium align-items-center justify-content-center font-20px">
                            <div class="center-content">
                              <div className={`${!letAI ? "opacity-25" : ""}`}>
                                Let AI do it for me
                              </div>

                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                                className={`mx-0 px-0 cs-switch ${
                                  letAI ? "opacity-25" : ""
                                }`}
                                checked={!letAI}
                                onChange={() => setLetAI(!letAI)}
                              />
                              <div className={`${letAI ? "opacity-25" : ""}`}>
                                Let me take charge
                              </div>
                            </div>
                            <div className="row-item space-left">
                              <button
                                type="button"
                                className="btn btn-ouline-orange"
                                onClick={() => handleResetFields()}
                              >
                                Reset the fields
                              </button>
                            </div>
                          </div>
                          <div className="form-other-fields">
                            <div className="row row-cols-lg-1 row-cols-1">
                              <div className="row-item row-item-1 pe-lg-3 mb-4 mb-lg-0 pb-4 pb-lg-0">
                                <div
                                  key={`default`}
                                  className="mb-3 row row-cols-2"
                                >
                                  <div className="cs-form-checkbox d-flex gap-2 align-items-start flex-row-reverse justify-content-end mb-2 mb-lg-4 pb-2">
                                    <label
                                      htmlFor={`inline-1`}
                                      className={`${letAI ? "opacity-25" : ""}`}
                                    >
                                      <span className="text-dark">
                                        Include previous chapters?{" "}
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip className="cs-tooltip">
                                              <small className="text-gray text-12px d-block">
                                                (Check this box to include
                                                previous chapter questions, if
                                                applicable.)
                                              </small>
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            src={infoIcon2}
                                            width={18}
                                            className=" d-inline-block"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    </label>
                                    <Form.Check
                                      className="text-start check-cs"
                                      reverse
                                      label=""
                                      name="group1"
                                      type="checkbox"
                                      // id={`inline-1`}
                                      checked={includePreviousChapters}
                                      onChange={() =>
                                        setIncludePreviousChapters(
                                          !includePreviousChapters
                                        )
                                      }
                                      disabled={letAI}
                                    />
                                  </div>
                                  <div className="cs-form-checkbox d-flex gap-2 align-items-start flex-row-reverse justify-content-end mb-2 mb-lg-4 pb-2">
                                    <label
                                      htmlFor={`inline-2`}
                                      className={`${letAI ? "opacity-25" : ""}`}
                                    >
                                      <span className="text-dark">
                                        Include rank-booster questions?{" "}
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip className="cs-tooltip">
                                              <small className="text-gray text-12px d-block">
                                                (Check this box to include rank
                                                booster questions. This is a
                                                super-personalized feature that
                                                will kick in after you have done
                                                enough practice questions.)
                                              </small>
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            src={infoIcon2}
                                            width={18}
                                            className=" d-inline-block"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    </label>
                                    <Form.Check
                                      className="text-start check-cs"
                                      reverse
                                      label=""
                                      name="group1"
                                      type="checkbox"
                                      // id={`inline-2`}
                                      checked={includeBoosterQuestions}
                                      onChange={() =>
                                        setIncludeBoosterQuestions(
                                          !includeBoosterQuestions
                                        )
                                      }
                                      disabled={letAI}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              <div className="modal-footerr px-2 pb-3 text-center">
                <p className="mb-3 w-100">
                  You can take this{" "}
                  <span className="text-orange">on-demand</span> practice once
                  every{" "}
                  <span className="text-orange">{props?.gaphours} hours</span>.
                </p>
                <button
                  type="button"
                  className="btn btn-primary btn-lg mx-auto"
                  onClick={() => startTest()}
                >
                  Start now
                </button>
              </div>
            </Modal.Body>
          </div>
        )
      )}
    </Modal>
  );
};

export default OnDemandCenteredModal;
