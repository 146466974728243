/* eslint-disable*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../constants/Api/axios";
import Axios from "axios";

const token = () => localStorage.getItem("authToken");
const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
    },
  };
};

export const signUp = createAsyncThunk(
  "signUp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/signup", values);
      return response.data.message;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);
export const sendOtp = createAsyncThunk(
  "sendOtp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/sendotp", values);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);
export const resetOtp = createAsyncThunk(
  "resetOtp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/resendotp", values);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/verifyotp", values);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const signIn = createAsyncThunk(
  "signIn",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/login", values);
      return response.data.data;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const refreshToken = createAsyncThunk(
  "verifyToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/auth/refresh-token", headers());
      return response.data.data.token[0];
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const handleSignupConfirm = createAsyncThunk(
  "handleSignupConfirm",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/auth/signup_confirm?token=${params}`,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/forget-password", values);
      return response.data.message;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/reset-password", values);
      return response.data.message;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const contactEmail = createAsyncThunk(
  "contactEmail",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/contact-mail", values);
      return response.data.message;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const practiceSets = createAsyncThunk(
  "practiceSets",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/get/practice/sets", headers());
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const adminPracticeSets = createAsyncThunk(
  "adminPracticeSets",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/admin/get/practice/sets", headers());
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const checkTimedSwitchAPI = createAsyncThunk(
  "checkTimedSwitchAPI",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/update-timed-sets",
        values,
        headers()
      );
      return response.data.message;
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue({
          responseError: ["Please try after some time"],
        });
      }
    }
  }
);

export const practiceSections = createAsyncThunk(
  "practiceSections",
  async (sectionId, { rejectWithValue, signal }) => {
    try {
      const source = Axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `/get/set/all-question?set_id=${sectionId}`,
        {
          cancelToken: source.token,
          headers: { Authorization: `Bearer ${token()}` },
        }
      );
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const practiceSectionsOndemandAdaptive = createAsyncThunk(
  "practiceSectionsOndemandAdaptive",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const adminPracticeSections = createAsyncThunk(
  "adminPracticeSections",
  async (sectionId, { rejectWithValue, signal }) => {
    try {
      const source = Axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `/admin/get/set/all-question?set_id=${sectionId}`,
        {
          cancelToken: source.token,
          headers: { Authorization: `Bearer ${token()}` },
        }
      );
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const saveAsDraft = createAsyncThunk(
  "saveAsDraft",
  async (valuesForDraft, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/practice/save-draft",
        valuesForDraft,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const practiceSectionsSubmit = createAsyncThunk(
  "practiceSectionsSubmit",
  async (finalAnsTosubmit, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/practice/submit",
        finalAnsTosubmit,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const adminPracticeSectionsSubmit = createAsyncThunk(
  "adminPracticeSectionsSubmit",
  async (finalAnsTosubmit, { getState, rejectWithValue }) => {
    const { setStore } = getState().practiceSet;
    const { practiceType } = setStore;
    finalAnsTosubmit.practiceType = practiceType;
    try {
      const response = await axios.post(
        "/admin/practice/submit",
        finalAnsTosubmit,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const subscriptionList = createAsyncThunk(
  "subscriptionList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/auth/subscription-list", headers());
      return response.data.data.data[0].name;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const paymentSessionCreate = createAsyncThunk(
  "paymentSessionCreate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/auth/payment-session-create",
        headers()
      );
      return response.data.data.url;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const stopAutoRecurring = createAsyncThunk(
  "stopAutoRecurring",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/auth/cancel-auto-recurring",
        _,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const paymentVarify = createAsyncThunk(
  "paymentVarify",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/auth/payment-verify",
        values,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const currentPlan = createAsyncThunk(
  "currentPlan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/auth/current-active-plan", headers());
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data);
      }
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/user-profile", _, headers());
      return response.data.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
export const updateUser = createAsyncThunk(
  "updateUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/user-update", _, headers());
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
export const changePassword = createAsyncThunk(
  "changePassword",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/change-password", _, headers());
      return response.data.message;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
export const currentSubscriptionPlane = createAsyncThunk(
  "currentSubscriptionPlane",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/auth/current-subscription-plan",
        headers()
      );
      return response.data.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data);
      }
    }
  }
);
export const userScoreHistory = createAsyncThunk(
  "userScoreHistory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/practice/last-week-result", headers());
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

// Timed section Apis
export const handleCounterGet = createAsyncThunk(
  "handleCounterGet",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/practice/get/timed-question-view-count?set_id=${params}`,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const handleAdminCounterGet = createAsyncThunk(
  "handleAdminCounterGet",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/practice/get/timed-question-view-count?set_id=${params}`,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const handleCounterPost = createAsyncThunk(
  "handleCounterPost",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "practice/set/timed-question-view-count",
        params,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const handleAdminCounterPost = createAsyncThunk(
  "handleAdminCounterPost",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "admin/practice/set/timed-question-view-count",
        params,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const timedSaveAsDraft = createAsyncThunk(
  "timedSaveAsDraft",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/practice/timed-questions/save-draft",
        params,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const adminTimedSaveAsDraft = createAsyncThunk(
  "adminTimedSaveAsDraft",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/admin/practice/timed-questions/save-draft",
        params,
        headers()
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getTimedSaveAsDraftData = createAsyncThunk(
  "getTimedSaveAsDraftData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `practice/get/timed-questions/save-draft?set_id=${params}`,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const getAdminTimedSaveAsDraftData = createAsyncThunk(
  "getAdminTimedSaveAsDraftData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `admin/practice/get/timed-questions/save-draft?set_id=${params}`,
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const getSpecial1Subscription = createAsyncThunk(
  "getSpecial1Subscription",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post("special1/subscribe", data, headers());
      dispatch(getUserProfile(true));
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const getOndemandPracticeJson = createAsyncThunk(
  "getOndemandPracticeJson",
  async ({ rejectWithValue }) => {
    try {
      const response = await axios.get(
        "ondemand/admin/get/practice/sets",
        headers()
      );
      return response.data;
    } catch (err) {
      if (err.response.data === undefined) {
        return rejectWithValue({ message: err.message });
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const sendOndemandParameters = createAsyncThunk(
  "sendOndemandParameters",
  async (data, { dispatch, rejectWithValue }) => {
    const is_admin = localStorage.getItem("is_admin");
    const newData = {
      data: data,
      is_admin: is_admin,
    };
    try {
      const response = await axios.post(
        "/admin/practice/prod/ondemand",
        newData,
        headers()
      );
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const sendOnDemandAdaptiveParameters = createAsyncThunk(
  "sendOnDemandAdaptiveParameters",
  async (data, { dispatch, rejectWithValue }) => {
    const is_admin = localStorage.getItem("is_admin");
    const newData = {
      data: data,
      is_admin: is_admin,
    };
    try {
      const response = await axios.post(
        "/admin/practice/prod/adaptive",
        newData,
        headers()
      );
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const storePracticeData = createAsyncThunk(
  "storePracticeData",
  async (data) => {
    return data;
  }
);

export const updateNotificationValues = createAsyncThunk(
  "updateNotificationValues",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ondemand/update/notification-values",
        data,
        headers()
      );
      if (response.status === 200) {
        dispatch(getUserProfile());
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateOndemandAdaptiveCount = createAsyncThunk(
  "updateOndemandAdaptiveCount",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ondemand/update-count/ondemand-adaptive",
        data,
        headers()
      );
      if (response.status === 200) {
        dispatch(
          getOndemandAdaptiveCount({
            username: data?.username,
            email: data?.email,
            userId: data?.userId,
          })
        );
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getOndemandAdaptiveCount = createAsyncThunk(
  "getOndemandAdaptiveCount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ondemand/get-count/ondemand-adaptive",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getGradesWeekly = createAsyncThunk(
  "getGradesWeekly",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/dashboard/get-all/grades-weekly",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getGradesOndemandAdaptive = createAsyncThunk(
  "getGradesOndemandAdaptive",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ondemand/get-test-grades/ondemand-adaptive",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getSingleGradesOndemandAdaptive = createAsyncThunk(
  "getSingleGradesOndemandAdaptive",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ondemand/get-single-test-grades/ondemand-adaptive",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const saveFlaggedQuestion = createAsyncThunk(
  "saveFlaggedQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/flagged/save/flagged-question",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const confirmSaveFlaggedQuestion = createAsyncThunk(
  "confirmSaveFlaggedQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/flagged/confirm-flag",
        data,
        headers()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const storeCurrentQuestion = createAsyncThunk(
  "storeCurrentQuestion",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const storeCurrentQuestionAns = createAsyncThunk(
  "storeCurrentQuestionAns",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getFlaggedQuestion = createAsyncThunk(
  "getFlaggedQuestion",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `/flagged/get/single-flagged-question?q_id=${data.q_id}`,
        headers()
      );
      if (response.status === 200) {
        dispatch(storeCurrentQuestion(data.ques));
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const hidelFaggedQuestionModal = createAsyncThunk(
  "hidelFaggedQuestionModal",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const showLoginModalView = createAsyncThunk(
  "showLoginModalView",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
