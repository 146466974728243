import React from 'react';
import { Container } from 'react-bootstrap';
import PlaceholdergifVernier from '../../assets/images/placeholdergif_vernier.gif';
import PlaceholdergifArchimedes from '../../assets/images/placeholdergif_archimedes.gif';
import PlaceholdergifPemdas from '../../assets/images/placeholdergif_pemdas.gif';
import './styles.scss';

const InteLaPage = () => {
	return (
		<div className='intela_page'>
			<section className='inner_banner_section'>
				<Container>
					<h1>InteLa&trade;: Interactive Learning</h1>
					<p>Online learning isn't a new idea … <i>how</i> we do this is where it gets exciting!</p>
				</Container>
			</section>
			<section className='intela_list_section'>
				<Container>
					<div className='intela_list_wrapper'>
						<div className='intela_item'>
							<div className='intela_item_image'>
								<img src={PlaceholdergifVernier} alt="placeholdergif_vernier" />
							</div>
							<div className='intela_item_content'>
								<h2>Try things hands-on</h2>
								<p>Measure with a vernier caliper | Adjust umbrella angle to protect from rain | ...</p>
							</div>
						</div>
						<div className='intela_item'>
							<div className='intela_item_image'>
								<img src={PlaceholdergifArchimedes} alt="placeholdergif_archimedes" />
							</div>
							<div className='intela_item_content'>
								<h2>Explore how things work</h2>
								<p>Rainbow colors | Fiber-optics | Why does a big ship float but a small
									stone sink | ...</p>
							</div>
						</div>
						<div className='intela_item'>
							<div className='intela_item_image'>
								<img src={PlaceholdergifPemdas} alt="placeholdergif_pemdas" />
							</div>
							<div className='intela_item_content'>
								<h2>Understand step-by-step</h2>
								<p>Learn interactively, with step-by-step explanations when you need a helping hand!</p>
							</div>
						</div>
					</div>
				</Container>
			</section>
			<section className='intela_cta_section'>
				<Container>
					<div className='intela_cta_wrapper'>
						<div className='intela_cta_content'>
							<h2>InteLa&trade; is in the works …</h2>
							<p>Do you want a sneak peek? </p>
						</div>
						<div className="intela_cta_btn">
							<a className="btn btn-primary" href="https://calendly.com/tikeswar/chat-w-tikka" target="_blank" rel="noopener noreferrer">
								Book an InteLa&trade; demo</a>
						</div>
					</div>
				</Container>
			</section>
		</div>
	);
};

export default InteLaPage;
