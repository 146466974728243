import React from 'react';
import Header from '../components/HeaderComponent';
import Footer from '../components/FooterComponent';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export const ProtectedRoute = ({ children, isTimed, isAdminRoute }) => {
	const { userData } = useSelector((state) => state.auth);

	if (isEmpty(userData)) {
		return <Navigate to='/' replace />;
	}

	if (isAdminRoute && !isTimed) {
		if (userData.payload.is_admin) {
			return (
				<div className='main'>
					<Header />
					<div className='content'>{children}</div>
					<Footer />
				</div>
			);
		} else {
			return <Navigate to='/' replace />;
		}
	}

	if (isTimed) {
		return (
			<div className='main'>
				<div className='content'>{children}</div>
			</div>
		);
	}

	return (
		<div className='main'>
			<Header />
			<div className='content'>{children}</div>
			<Footer />
		</div>
	);
};
