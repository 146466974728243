import { isArray, isEmpty } from 'lodash';
import React from 'react';

const AnsMarkingComponent = ({ data }) => {
	if (isEmpty(data) || isEmpty(data.ans_obj)) return;
	if (
		isArray(data.ans_obj.ugot_right) && data.ans_obj.ugot_right.every((ele) => ele)
	) return <p className='correct_icon'>&#x2713;</p>;
	if (data.ans_obj.ugot_right === true) return <p className='correct_icon'>&#x2713;</p>;
	return <p className='worng_icon'>&#x2717;</p>;
};

export default AnsMarkingComponent;
