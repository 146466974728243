/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { isEmpty, isNull } from "lodash";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
// import { InlineMath, BlockMath } from 'react-katex';
import { attemptedQuestionCounterType4 } from "../../../utils/PracticePage";
import "./styles.scss";
import Markdown from "../../Markdown";
import AnsMarkingComponent from "../AnsMarkingComponent";
import useShowHideAns from "../../../hooks/useShowHideAns";
import CommonFlag from "../CommonFlag";
// let Latex = require('react-latex');

const UniqueChoiceQ = ({
  data,
  indexValue,
  value,
  setFieldVal,
  fieldValue,
  name,
}) => {
  const { i, j, k } = indexValue;
  const [radioBut, setRadioBut] = useState(
    data.list_chtml.map(() => {
      return false;
    })
  );
  const [answer, setAnswer] = useState(fieldValue);
  const [id, setId] = useState(fieldValue);

  useEffect(() => {
    attemptedQuestionCounterType4(data, value, indexValue, fieldValue);
  }, [value]);

  useEffect(() => {
    if ((data.ans_obj || data.draft) && !isNull(fieldValue)) {
      const tempRadioButArray = radioBut;
      tempRadioButArray[fieldValue] = true;
      setRadioBut(tempRadioButArray);
    }
  }, []);

  const handleChange = (e, index) => {
    if (isEmpty(answer) || e.target.value !== answer) {
      setFieldVal(
        `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
        index
      );
    }
  };

  const handleClick = (e, index) => {
    if (
      radioBut[index] === false &&
      (answer === null || e.target.value !== answer)
    ) {
      radioBut[index] = true;
      radioBut[id] = false;
      setAnswer(e.target.value);
      setRadioBut(radioBut);
      setId(index);
    } else {
      setFieldVal(
        `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
        null
      );
      radioBut[index] = false;
      setAnswer(null);
      setRadioBut(radioBut);
      setId(null);
    }
  };

  const generateHTMLContent = (ctext) => {
    return <Markdown>{ctext}</Markdown>;
  };

  const handleStyle = (data, index) => {
    // if (data?.grading_type !== 'instant') return null;
    if (!useShowHideAns()) return;

    if (data?.ans_obj?.correctness === "unattempted") {
      if (data?.ans_obj?.ans_key === index) {
        return "right";
      }
      return "wrong";
    }
    if (data?.ans_obj?.ans_key === index) {
      return "right";
    }
    if (data?.attempted_obj === index) {
      return "wrong";
    }
    return "";
  };

  return (
    <div className="ucq_simple_display" id={`${data.q_type}_${data.qno}`}>
      <div className="ucq_simple_display_header">
        <div className="question_number">
          <h6>{data.qno}</h6>
          <QuestionScoreComponent data={data} />
          <QuestionHintComponent hints={data.hints} />
          <QueExplanationComponent
            data={data}
            explanations={data.explanations}
          />
          <AnsMarkingComponent data={data} />
          <CommonFlag data={data} />
        </div>
      </div>
      <div className="ucq_base_display_options_radio">
        {/* <h4 dangerouslySetInnerHTML={{ __html: data.q_html }} /> */}
        <h4>{generateHTMLContent(data.q_html)}</h4>
        <ol className={data.show_as_ABCD ? "" : "remove_abcd"}>
          {data.list_chtml.map((ctext, index) => {
            return (
              <Form.Check
                key={index}
                inline={data.align_h}
                className={data.align_h ? "" : "item"}
              >
                <li className={data.show_as_ABCD ? "show_as_abcd" : ""}>
                  <div>
                    <Form.Check.Input
                      key={index}
                      type="radio"
                      id={`${data.qno}-${index}`}
                      name={name}
                      onChange={(e) => handleChange(e, index)}
                      onClick={(e) => handleClick(e, index)}
                      checked={fieldValue === index && true}
                      value={index}
                      className={data.ans_obj && handleStyle(data, index)}
                      // className={`${
                      // 	data.ans_obj && questionCorrectnessType2(data, index)
                      // } ${
                      // 	index === data.attempted_obj &&
                      // 	data.ans_obj &&
                      // 	data.ans_obj.correctness === 'wrong' &&
                      // 	'wrong'
                      // } ${
                      // 	index === data.attempted_obj &&
                      // 	data.ans_obj &&
                      // 	data.ans_obj.correctness === 'right' &&
                      // 	'right'
                      // }`}
                      data-index={String.fromCharCode(65 + index)}
                      disabled={data.ans_obj}
                    />
                    <Form.Check.Label
                      htmlFor={`${data.qno}-${index}`}
                      data-index={String.fromCharCode(65 + index)}
                    >
                      {generateHTMLContent(ctext)}
                    </Form.Check.Label>
                  </div>
                </li>
              </Form.Check>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default UniqueChoiceQ;
