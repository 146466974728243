/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { Nav, Navbar, Container } from "react-bootstrap";
import { isEmpty } from "lodash";
import NavbarDropDown from "../NavbarDropDown";
import Logo from "../../assets/images/logo.svg";
import {
  signOut,
  location,
  setLogoutModalShow,
} from "../../redux/slices/authSlice";
import { refreshToken } from "../../redux/actions";
import { removeCurrentPlaneStatus } from "../../redux/slices/paymentSlice";
import { navbarContents } from "../../constants/HeaderData";
import "./styles.scss";
import CustomModal from "../CustomModal";

const HeaderComponent = (theme) => {
  const {
    isLoggedIn,
    authToken,
    logoutModalShow,
    userData,
    loading: authLoading,
  } = useSelector((state) => state.auth);
  const [barSticky, setBarSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.practiceSet);
  const { loading: priceLoading } = useSelector((state) => state.payment);
  const handleScroll = () => {
    const position = window.pageYOffset;
    position >= 30 ? setBarSticky(true) : setBarSticky(false);
  };
  useEffect(() => {
    if (!isEmpty(authToken)) {
      dispatch(refreshToken());
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(search)) {
      dispatch(location(pathname + search));
    } else {
      dispatch(location(pathname));
    }
  }, [pathname]);

  const handleClick = () => {
    dispatch(signOut());
    dispatch(removeCurrentPlaneStatus());
    setExpanded(false);
  };

  const handleLogoutCloseModal = () => {
    dispatch(setLogoutModalShow());
    window.location.href = "/";
  };

  const modalTitle = "Logout";
  const modalContent = <p>You have successfully logged out!</p>;
  const modalFooterButtons = [
    { label: "Close", variant: "danger", onClick: handleLogoutCloseModal },
  ];

  return (
    !loading &&
    !authLoading &&
    !priceLoading && (
      <Navbar
        fixed="top"
        expand="lg"
        className={barSticky ? "sticky pt-2 pb-2" : "top pt-4 pb-4"}
        expanded={expanded}
      >
        <Container className={theme.theme === "light" ? "light-theme" : ""}>
          <Navbar.Brand>
            <NavLink to="/" onClick={() => setExpanded(false)}>
              <ReactSVG src={Logo} />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {navbarContents.map((e, i) => {
                return !e.isAdminRoute && !userData?.payload?.is_admin ? (
                  <NavLink
                    key={i}
                    to={e.to}
                    className="nav-link"
                    onClick={() => setExpanded(false)}
                  >
                    {e.text}
                  </NavLink>
                ) : (
                  userData?.payload?.is_admin && (
                    <NavLink
                      key={i}
                      to={e.to}
                      className="nav-link"
                      onClick={() => setExpanded(false)}
                    >
                      {e.text}
                    </NavLink>
                  )
                );
              })}
              {isLoggedIn ? (
                <NavbarDropDown
                  setExpanded={setExpanded}
                  handleClick={handleClick}
                />
              ) : (
                <div className="auth_buttons">
                  <Link to="/login">Log in</Link>
                  <Link to="/signup">Sign up</Link>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
          <CustomModal
            size={"md"}
            title={modalTitle}
            content={modalContent}
            footerButtons={modalFooterButtons}
            show={logoutModalShow}
            handleClose={handleLogoutCloseModal}
          />
        </Container>
      </Navbar>
    )
  );
};

export default HeaderComponent;
