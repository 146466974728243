import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';

const Markdown = ({ children }) => {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkMath]}
			rehypePlugins={[rehypeKatex, rehypeRaw]}
			// allowedElements={['h1', 'image']}
		>
			{children}
		</ReactMarkdown>
	);
};

export default Markdown;
