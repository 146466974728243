import React, { useState, memo } from 'react';
import CustomModal from '../CustomModal';
import './styles.scss';
import Markdown from '../Markdown';

const VideoExplanationComponent = ({ videoLink, explanations }) => {
	const [explanationsModal, setExplanationsModal] = useState(false);

	const explanationsCloseModal = () => setExplanationsModal(false);
	const explanationsOpenModal = () => setExplanationsModal(true);

	const modalTitle = 'Video Explanation';
	const modalContent = <Markdown>{videoLink}</Markdown>;
	const modalFooterButtons = [
		{ label: 'Close', variant: 'danger', onClick: explanationsCloseModal }
	];

	return (
		<>
			<div className="btn btn-link" onClick={explanationsOpenModal}>
                Video Explanation
			</div>
			<CustomModal
				title={modalTitle}
				content={modalContent}
				footerButtons={modalFooterButtons}
				show={explanationsModal}
				handleClose={explanationsCloseModal}
				className='resource-video-modal'
			/>
		</>
	);
};

export default memo(VideoExplanationComponent);
