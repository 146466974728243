import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import contentImage from '../../../assets/images/signup.svg';
import LeftArrow from '../../../assets/images/white-left-arrow.png';
import userIcon from '../../../assets/images/user.png';
import infoIcon from '../../../assets/images/info.svg';
import checkIcon from '../../../assets/images/check.png';
import { signUp } from '../../../redux/actions';
import Loader from '../../../components/Loader';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import PasswordFloatingLabel from '../../../components/AuthComponents/PasswordFloatingLabel';
import FloatinglabelComponent from '../../../components/AuthComponents/FloatinglabelComponent';
import FormikErrorComponent from '../../../components/AuthComponents/FormikErrorComponent';
import verifyIcon from '../../../assets/images/icons/check-icon.svg';
import crossIcon from '../../../assets/images/cross.png';
import {
	initialValuesSignUp,
	signupSchema
} from '../../../constants/Authentication';
import {
	passwordValidation,
	usernameValidation
} from '../../../utils/Authentication';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import AuthCarousel from '../../../components/AuthComponents/AuthCarousel';

const SignUp = () => {
	const dispatch = useDispatch();
	const [userNameErrorDisplay, setUserNameErrorDisplay] = useState(false);
	const [nameCorrection, setNameCorrection] = useState(false);

	const {
		loading,
		signupErrorMessage
	} = useSelector((state) => state.auth);

	const onSubmit = () => {
		const formData = new FormData();
		formData.append('first_name', formik.values.firstname);
		formData.append('last_name', formik.values.lastname);
		formData.append('username', formik.values.username);
		formData.append('password', formik.values.password);
		formData.append('email', formik.values.email);
		formData.append('age_conformation', formik.values.confirm_age);
		dispatch(signUp(formData));
	};
	const formik = useFormik({
		initialValues: initialValuesSignUp,
		validationSchema: signupSchema,
		onSubmit
	});
	const usernameCheck = usernameValidation(formik.values.username);
	const passwordCheck = passwordValidation(formik.values.password);

	const isvalidUsername = () => {
		return usernameCheck.usernameCharactorError &&
			usernameCheck.usernameLengthError &&
			usernameCheck.usernameLetterError;
	};
	return (
		<div className='signup_page'>
			<AuthCarousel contentImage={contentImage} />
			<div className='signup_form'>
				<ToastContainer />
				<div className='signup_form_wrapper'>
					<div className='left_arrow'>
						<img src={LeftArrow} />
					</div>
					<h2>Sign up</h2>
					{!isEmpty(signupErrorMessage) && (
						<div className='top_error_message_handle'>
							<ErrorHandleComponent errorMessage={signupErrorMessage} />
						</div>
					)}
					<Form className='signup_form_handle' onSubmit={formik.handleSubmit}>
						<div className='main-form-floating'>
							<FloatinglabelComponent
								formikTouched={formik.touched.email}
								formikErrors={formik.errors.email}
								formikValues={formik.values.email}
								onChange={formik.handleChange}
								label='Email'
								id='email'
								type='email'
							/>
							<FormikErrorComponent
								errorName={formik.errors.email}
								touched={formik.touched.email}
							/>
						</div>
						<div className='row'>
							<div className='main-form-floating'>
								<FloatinglabelComponent
									formikTouched={formik.touched.firstname}
									formikErrors={formik.errors.firstname}
									formikValues={formik.values.firstname}
									onChange={formik.handleChange}
									label='First name'
									id='firstname'
									type='text'
								/>
								<FormikErrorComponent
									errorName={formik.errors.firstname}
									touched={formik.touched.firstname}
								/>
							</div>
							<div className='main-form-floating'>
								<FloatinglabelComponent
									formikTouched={formik.touched.lastname}
									formikErrors={formik.errors.lastname}
									formikValues={formik.values.lastname}
									onChange={formik.handleChange}
									label='Last name'
									id='lastname'
									type='text'
								/>
								<FormikErrorComponent
									errorName={formik.errors.lastname}
									touched={formik.touched.lastname}
								/>
							</div>
						</div>
						<div className='main-form-floating'>
							<FloatingLabel
								label='Username'
								htmlFor='username'
								className={
									formik.touched.username && formik.errors.username ? 'error' : null
								}
							>
								<span className='icon'>
									<img src={userIcon} />
								</span>
								<Form.Control
									type='text'
									id='username'
									value={formik.values.username}
									onChange={formik.handleChange}
									placeholder='Username'
									onFocus={() => {
										setUserNameErrorDisplay(true);
									}}
									onBlur={() => {
										setUserNameErrorDisplay(false);
										setNameCorrection(true);
									}}
								/>
								{
									nameCorrection && (
										<span className='correction_icon'>
											{
												isvalidUsername()
													? <ReactSVG src={verifyIcon} />
													: <img src={crossIcon} />
											}
										</span>
									)
								}
								<span className='info-icon'>
									<ReactSVG src={infoIcon} />
								</span>
								<div
									className={`form_error_handle ${userNameErrorDisplay ? 'errorShow' : 'errorHide'}`}
								>
									<p className={`${usernameCheck.usernameLengthError && 'valid'}`}>
										* 6-16 characters <img src={checkIcon} />
									</p>
									<p className={`${usernameCheck.usernameLetterError && 'valid'}`}>
										* Must start with a letter <img src={checkIcon} />
									</p>
									<p className={`${usernameCheck.usernameCharactorError && 'valid'}`}>
										* Only letters (a-z), numbers (0-9), and _ <img src={checkIcon} />
									</p>
								</div>
							</FloatingLabel>
							<FormikErrorComponent
								errorName={formik.errors.username}
								touched={formik.touched.username}
							/>
						</div>
						<div className='main-form-floating'>
							<PasswordFloatingLabel
								passwordCheck={passwordCheck}
								formikTouched={formik.touched}
								formikErrors={formik.errors}
								formikValues={formik.values}
								onChange={formik.handleChange}
							/>
							<FormikErrorComponent
								errorName={formik.errors.password}
								touched={formik.touched.password}
							/>
						</div>
						<div className='confirm_age_wrapper'>
							<input
								type='checkbox'
								id='confirm_age'
								value={formik.values.confirm_age}
								onChange={formik.handleChange}
								placeholder='confirm_age'
								checked={formik.values.confirm_age}
							/>
							<label htmlFor='confirm_age'>
								<span>
									I confirm that I am above 16 years old OR I am a parent
									signing up on behalf of the child who is under 16
								</span>
							</label>
							<FormikErrorComponent
								errorName={formik.errors.confirm_age}
								touched={formik.touched.confirm_age}
							/>
						</div>
						<Button
							variant='primary'
							type='submit'
							// disabled={!(formik.isValid && formik.dirty) || loading}  // this enables/disables the button
						>
							{loading ? <Loader /> : 'Sign up'}
						</Button>
					</Form>
					<span className='forgot'>
						By signing up you agree to the{' '}
						<NavLink to='/terms-of-use'> terms of use</NavLink> and{' '}
						<NavLink to='/privacy-policy'>privacy policy</NavLink>.
					</span>
					<span className='signup'>
						Already have an account?{' '}
						<b>
							<Link to='/login'>Log in</Link>
						</b>
					</span>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
