import { useEffect, useState } from 'react';

const useAutoCompleteFocus = ({
	parentRef,
	onFocused,
	onBlurred
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
		if (onFocused) {
			onFocused();
		}
	};

	const handleBlur = () => {
		setIsFocused(false);
		if (onBlurred) {
			onBlurred();
		}
	};

	useEffect(() => {
		if (parentRef.current) {
			const autoCompleteInput = parentRef.current.querySelector('input');
			if (autoCompleteInput) {
				autoCompleteInput.addEventListener('focus', handleFocus);
				autoCompleteInput.addEventListener('blur', handleBlur);
			}
		}

		return () => {
			if (parentRef.current) {
				const autoCompleteInput = parentRef.current.querySelector('input');
				if (autoCompleteInput) {
					autoCompleteInput.removeEventListener('focus', handleFocus);
					autoCompleteInput.removeEventListener('blur', handleBlur);
				}
			}
		};
	}, [parentRef.current]);

	return {
		isFocused
	};
};

export default useAutoCompleteFocus;
