/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesMathOlympiad = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Resources: DSA</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h4>Cheat sheets:</h4>
                    HashMap; Set; Stack; Queue: <a href="https://github.com/tikeswar/public/blob/main/dsa/HashMap_Set_Stack_Queue.pdf?raw=true" target="_blank">download pdf</a><br />
                    Insertion Sort; Merge Sort; Quick Sort: <a href="https://github.com/tikeswar/public/blob/main/dsa/SortingAlgos_InsertionSort_MergeSort_QuickSort.pdf?raw=true" target="_blank">download pdf</a><br />
                    Binary Tree Traversal: <a href="https://github.com/tikeswar/public/blob/main/dsa/BinaryTreeTraversal.pdf?raw=true" target="_blank">download pdf</a><br />
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathOlympiad;
