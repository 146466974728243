/* eslint-disable */
import SignInPage from '../../pages/Authentication/SignIn';
import SignUpPage from '../../pages/Authentication/SignUpPage';
import ForgotPassword from '../../pages/Authentication/ForgotPassword';
import ResetPassword from '../../pages/Authentication/ResetPassword';
import PaymentSuccessPage from '../../pages/PaymentGatway/PaymentSuccessPage';
import PaymentFailpage from '../../pages/PaymentGatway/PaymentFailpage';
import TermsPage from '../../pages/TermsPage';
import PrivacyPage from '../../pages/PrivacyPage';
import FaqPage from '../../pages/FaqPage';
import Practice from '../../pages/PracticePage';
import Sections from '../../pages/SectionsPage';
import InteLa from '../../pages/InteLaPage';
import Pricing from '../../pages/PaymentGatway/PricingPage';
import About from '../../pages/AboutPage';
import Contact from '../../pages/ContactPage';
import Account from '../../pages/AccountPage';
import ProfilePage from '../../pages/ProfilePage';
import ChangePassword from '../../pages/Authentication/ChangePassword';
import DashboardPage from '../../pages/DashboardPage';
import SettingsPage from '../../pages/SettingsPage';
import TimedSectionPage from '../../pages/TimedSectionPage';
import ResourcesPage from '../../pages/Resources';
import ResourcesMathK12 from '../../pages/Resources/MathK12';
import ResourcesMathK12G3Set001A from '../../pages/Resources/MathK12/G3Set001A';
import ResourcesMathK12G3Set001B from '../../pages/Resources/MathK12/G3Set001B';
import ResourcesMathK12G3Set001C from '../../pages/Resources/MathK12/G3Set001C';
import ResourcesMathK12G4Set001A from '../../pages/Resources/MathK12/G4Set001A';
import ResourcesMathK12G4Set001B from '../../pages/Resources/MathK12/G4Set001B';
import ResourcesMathK12G4Set001C from '../../pages/Resources/MathK12/G4Set001C';
import ResourcesMathK12G5Set001A from '../../pages/Resources/MathK12/G5Set001A';
import ResourcesMathK12G5Set001B from '../../pages/Resources/MathK12/G5Set001B';
import ResourcesMathK12G6Set001A from '../../pages/Resources/MathK12/G6Set001A';
import ResourcesMathK12G6Set001B from '../../pages/Resources/MathK12/G6Set001B';
import ResourcesMathK12G7Set001A from '../../pages/Resources/MathK12/G7Set001A';
import ResourcesMathK12G7Set001B from '../../pages/Resources/MathK12/G7Set001B';
import ResourcesMathK12G7Set001C from '../../pages/Resources/MathK12/G7Set001C';
// --
// Resources -> Math Kangaroo
import ResourcesMathKangaroo from '../../pages/Resources/MathKangaroo';
import ResourcesMathKangaroo2023L1 from '../../pages/Resources/MathKangaroo/2023L1';
import ResourcesMathKangaroo2023L2 from '../../pages/Resources/MathKangaroo/2023L2';
import ResourcesMathKangaroo2023L3 from '../../pages/Resources/MathKangaroo/2023L3';
import ResourcesMathKangaroo2023L4 from '../../pages/Resources/MathKangaroo/2023L4';
import ResourcesMathKangaroo2023L5 from '../../pages/Resources/MathKangaroo/2023L5';
import ResourcesMathKangaroo2023L6 from '../../pages/Resources/MathKangaroo/2023L6';
import ResourcesMathKangaroo2022L1 from '../../pages/Resources/MathKangaroo/2022L1';
import ResourcesMathKangaroo2022L2 from '../../pages/Resources/MathKangaroo/2022L2';
import ResourcesMathKangaroo2022L3 from '../../pages/Resources/MathKangaroo/2022L3';
import ResourcesMathKangaroo2022L4 from '../../pages/Resources/MathKangaroo/2022L4';
import ResourcesMathKangaroo2022L5 from '../../pages/Resources/MathKangaroo/2022L5';
import ResourcesMathKangaroo2022L6 from '../../pages/Resources/MathKangaroo/2022L6';
import ResourcesMathKangaroo2021L1 from '../../pages/Resources/MathKangaroo/2021L1';
import ResourcesMathKangaroo2021L2 from '../../pages/Resources/MathKangaroo/2021L2';
import ResourcesMathKangaroo2021L3 from '../../pages/Resources/MathKangaroo/2021L3';
import ResourcesMathKangaroo2021L4 from '../../pages/Resources/MathKangaroo/2021L4';
import ResourcesMathKangaroo2021L5 from '../../pages/Resources/MathKangaroo/2021L5';
import ResourcesMathKangaroo2021L6 from '../../pages/Resources/MathKangaroo/2021L6';
import ResourcesMathKangaroo2020L1 from '../../pages/Resources/MathKangaroo/2020L1';
import ResourcesMathKangaroo2020L2 from '../../pages/Resources/MathKangaroo/2020L2';
import ResourcesMathKangaroo2020L3 from '../../pages/Resources/MathKangaroo/2020L3';
import ResourcesMathKangaroo2020L4 from '../../pages/Resources/MathKangaroo/2020L4';
import ResourcesMathKangaroo2020L5 from '../../pages/Resources/MathKangaroo/2020L5';
import ResourcesMathKangaroo2020L6 from '../../pages/Resources/MathKangaroo/2020L6';
// --
// Resources -> Physics
import ResourcesPhysics from '../../pages/Resources/Physics';
// --
import ResourcesMathOlympiad from '../../pages/Resources/MathOlympiad';
import ResourcesMathOlympiadL2Set001A from '../../pages/Resources/MathOlympiad/L2Set001A';
import ResourcesMathOlympiadL2Set001B from '../../pages/Resources/MathOlympiad/L2Set001B';
import ResourcesMathOlympiadL2Set001C from '../../pages/Resources/MathOlympiad/L2Set001C';
import ResourcesMathOlympiadL2Set001D from '../../pages/Resources/MathOlympiad/L2Set001D';
import ResourcesMathOlympiadL2Set001E from '../../pages/Resources/MathOlympiad/L2Set001E';
import ResourcesMathOlympiadL2Set001F from '../../pages/Resources/MathOlympiad/L2Set001F';
import ResourcesMathOlympiadL2Set001G from '../../pages/Resources/MathOlympiad/L2Set001G';
import ResourcesMathOlympiadL2Set001H from '../../pages/Resources/MathOlympiad/L2Set001H';
import ResourcesMathOlympiadL2Set001I from '../../pages/Resources/MathOlympiad/L2Set001I';
import ResourcesMathOlympiadL2Set001J from '../../pages/Resources/MathOlympiad/L2Set001J';
import ResourcesMathOlympiadL2Set001K from '../../pages/Resources/MathOlympiad/L2Set001K';
import ResourcesMathOlympiadL2Set001L from '../../pages/Resources/MathOlympiad/L2Set001L';
import ResourcesMathOlympiadL2Set001M from '../../pages/Resources/MathOlympiad/L2Set001M';
import ResourcesSQL from '../../pages/Resources/SQL';
import ResourcesDSA from '../../pages/Resources/DSA';

import FormsSpecial1 from '../../pages/FormsSpecial1';
import OndemandAdaptivePage from '../../pages/PracticePage/OndemandAdaptivePage';

export const allRoutes = [
	{ path: '/practice', components: Practice, routeType: 'general' },
	{ path: '/admin/practice', components: Practice, routeType: 'protected', isAdminRoute: true },
	{ path: '/admin/practices/:slug', components: OndemandAdaptivePage, routeType: 'protected', isAdminRoute: true },
	{ path: '/user/practices/:slug', components: OndemandAdaptivePage, routeType: 'protected'},
	{ path: '/practice/:slug', components: Sections, routeType: 'general' },
	{ path: '/admin/practice/:slug', components: Sections, routeType: 'protected', isAdminRoute: true },
	{ path: '/dashboard/:slug', components: Sections, routeType: 'general' },
	{ path: '/dashboard/practice/:slug', components: Sections, routeType: 'protected', isAdminRoute: true },
	{
		path: '/practice/timed/:slug',
		components: TimedSectionPage,
		routeType: 'protected',
		isTimed: true
	},
	{
		path: '/admin/practice/timed/:slug',
		components: TimedSectionPage,
		routeType: 'protected',
		isTimed: true,
		isAdminRoute: true
	},
	{ path: '/intela', components: InteLa, routeType: 'general' },
	{ path: '/pricing', components: Pricing, routeType: 'general' },
	{
		path: '/payment-success',
		components: PaymentSuccessPage,
		routeType: 'protected'
	},
	{ path: '/payment-fail', components: PaymentFailpage, routeType: 'protected' },
	{ path: '/about', components: About, routeType: 'general' },
	{ path: '/contact', components: Contact, routeType: 'general' },
	{ path: '/terms-of-use', components: TermsPage, routeType: 'general' },
	{ path: '/privacy-policy', components: PrivacyPage, routeType: 'general' },
	{ path: '/faq', components: FaqPage, routeType: 'general' },
	{ path: '/profile', components: ProfilePage, routeType: 'protected' },
	{ path: '/dashboard', components: DashboardPage, routeType: 'protected' },
	{ path: '/settings', components:SettingsPage, routeType: 'protected' },
	{ path: '/account', components: Account, routeType: 'protected' },
	{ path: '/login', components: SignInPage, routeType: 'public' },
	{
		path: '/forgot-password',
		components: ForgotPassword,
		routeType: 'public'
	},
	{ path: '/resetpassword', components: ResetPassword, routeType: 'public' },
	{
		path: '/change-password',
		components: ChangePassword,
		routeType: 'protected'
	},
	{ path: '/signup', components: SignUpPage, routeType: 'public' },
	{ path: '/resources', components: ResourcesPage, routeType: 'general' },
	{ path: '/resources/mk12', components: ResourcesMathK12, routeType: 'general' },
	{ path: '/resources/mk12/g3-set001A', components: ResourcesMathK12G3Set001A, routeType: 'general' },
	{ path: '/resources/mk12/g3-set001B', components: ResourcesMathK12G3Set001B, routeType: 'general' },
	{ path: '/resources/mk12/g3-set001C', components: ResourcesMathK12G3Set001C, routeType: 'general' },
	{ path: '/resources/mk12/g4-set001A', components: ResourcesMathK12G4Set001A, routeType: 'general' },
	{ path: '/resources/mk12/g4-set001B', components: ResourcesMathK12G4Set001B, routeType: 'general' },
	{ path: '/resources/mk12/g4-set001C', components: ResourcesMathK12G4Set001C, routeType: 'general' },
	{ path: '/resources/mk12/g5-set001A', components: ResourcesMathK12G5Set001A, routeType: 'general' },
	{ path: '/resources/mk12/g5-set001B', components: ResourcesMathK12G5Set001B, routeType: 'general' },
	{ path: '/resources/mk12/g6-set001A', components: ResourcesMathK12G6Set001A, routeType: 'general' },
	{ path: '/resources/mk12/g6-set001B', components: ResourcesMathK12G6Set001B, routeType: 'general' },
	{ path: '/resources/mk12/g7-set001A', components: ResourcesMathK12G7Set001A, routeType: 'general' },
	{ path: '/resources/mk12/g7-set001B', components: ResourcesMathK12G7Set001B, routeType: 'general' },
	{ path: '/resources/mk12/g7-set001C', components: ResourcesMathK12G7Set001C, routeType: 'general' },
	// --
	// Resources -> Math Kangaroo
	{ path: '/resources/math-kangaroo', components: ResourcesMathKangaroo, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l1', components: ResourcesMathKangaroo2023L1, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l2', components: ResourcesMathKangaroo2023L2, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l3', components: ResourcesMathKangaroo2023L3, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l4', components: ResourcesMathKangaroo2023L4, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l5', components: ResourcesMathKangaroo2023L5, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2023-l6', components: ResourcesMathKangaroo2023L6, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l1', components: ResourcesMathKangaroo2022L1, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l2', components: ResourcesMathKangaroo2022L2, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l3', components: ResourcesMathKangaroo2022L3, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l4', components: ResourcesMathKangaroo2022L4, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l5', components: ResourcesMathKangaroo2022L5, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2022-l6', components: ResourcesMathKangaroo2022L6, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l1', components: ResourcesMathKangaroo2021L1, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l2', components: ResourcesMathKangaroo2021L2, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l3', components: ResourcesMathKangaroo2021L3, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l4', components: ResourcesMathKangaroo2021L4, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l5', components: ResourcesMathKangaroo2021L5, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2021-l6', components: ResourcesMathKangaroo2021L6, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l1', components: ResourcesMathKangaroo2020L1, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l2', components: ResourcesMathKangaroo2020L2, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l3', components: ResourcesMathKangaroo2020L3, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l4', components: ResourcesMathKangaroo2020L4, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l5', components: ResourcesMathKangaroo2020L5, routeType: 'general' },
	{ path: '/resources/math-kangaroo/2020-l6', components: ResourcesMathKangaroo2020L6, routeType: 'general' },
	// --
	// Resources -> Physics
	{ path: '/resources/physics', components: ResourcesPhysics, routeType: 'general' },
	// --
	{ path: '/resources/math-olympiad', components: ResourcesMathOlympiad, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001A', components: ResourcesMathOlympiadL2Set001A, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001B', components: ResourcesMathOlympiadL2Set001B, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001C', components: ResourcesMathOlympiadL2Set001C, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001D', components: ResourcesMathOlympiadL2Set001D, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001E', components: ResourcesMathOlympiadL2Set001E, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001F', components: ResourcesMathOlympiadL2Set001F, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001G', components: ResourcesMathOlympiadL2Set001G, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001H', components: ResourcesMathOlympiadL2Set001H, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001I', components: ResourcesMathOlympiadL2Set001I, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001J', components: ResourcesMathOlympiadL2Set001J, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001K', components: ResourcesMathOlympiadL2Set001K, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001L', components: ResourcesMathOlympiadL2Set001L, routeType: 'general' },
	{ path: '/resources/math-olympiad/l2-set001M', components: ResourcesMathOlympiadL2Set001M, routeType: 'general' },
	{ path: '/resources/sql', components: ResourcesSQL, routeType: 'general' },
	{ path: '/resources/dsa', components: ResourcesDSA, routeType: 'general' },
	{ path: '/forms/special1', components: FormsSpecial1, routeType: 'general' }
];
