import React from 'react';
import './styles.scss';

const FormikErrorComponent = ({ errorName, touched }) => {
	return (
		<>{errorName && touched && <h6 className='error_message'>{errorName}</h6>}</>
	);
};

export default FormikErrorComponent;
