/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesPage = () => {
    return (
		<div className='resources-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>All Resources</h1>
                </div>
            </section>
            <div class='resource-page-content'>
                <Container>
                    <p>
                        <h3>Math competitions</h3>
                        {/* <div>
                            <Link to='/resources/mk12'>Math K-12</Link>
                        </div> */}
                        <div>
                            <Link to='/resources/math-kangaroo'>Math Kangaroo</Link>
                        </div>
                        {/* <div>
                            <Link to='/resources/math-olympiad'>Math Olympiad</Link>
                        </div> */}
                    </p>

                    <p>
                        <h3>NEET & JEE</h3>
                        <div>
                            <Link to='/resources/physics'>Physics (under construction)</Link>
                        </div>
                    </p>

                    {/* <br></br>
                    <p>
                        <h3>YouTube playlists</h3>
                        <div><strong>Math Kangaroo</strong></div>
                        <table>
                            <tr>
                                <td><a href='https://www.youtube.com/watch?v=YDU0U1Xv-ng&list=PLMUONR1emNhIQvQY1sy5pv-wT3TAo39q4' target='_blank' rel='noreferrer'>Level 1</a> (Grades 1-2)</td>
                                <td><a href='https://www.youtube.com/watch?v=5pG8NDer26Y&list=PLMUONR1emNhJcq_WpVP991pwJDXCROzfP' target='_blank' rel='noreferrer'>Level 2</a> (Grades 3-4)</td>
                                <td><a href='https://www.youtube.com/watch?v=b6Ih7mUmq_M&list=PLMUONR1emNhJsjLWxj6g6F2wL5Nk-oqpN' target='_blank' rel='noreferrer'>Level 3</a> (Grades 5-6)</td>
                            </tr>
                            <tr>
                                <td><a href='https://www.youtube.com/watch?v=UESEiSQ17nU&list=PLMUONR1emNhJM_IlI1ceu8_njPDzQtQMq' target='_blank' rel='noreferrer'>Level 4</a> (Grades 7-8)</td>
                                <td><a href='https://www.youtube.com/watch?v=dmjRYVjXwj4&list=PLMUONR1emNhIhHXheZKQJ-qh2Ae1ur9Gb' target='_blank' rel='noreferrer'>Level 5</a> (Grades 9-10)</td>
                                <td><a href='https://www.youtube.com/watch?v=x125NxCX3xw&list=PLMUONR1emNhKVF7_NaFGmI7iTcigU8n6f' target='_blank' rel='noreferrer'>Level 6</a> (Grades 11-12)</td>
                            </tr>
                        </table>
                    </p> */}

                    {/* <p>
                        <h3>Software Engineering | Data Science | Machine Learning</h3>
                        <div>
                            <Link to='/resources/sql'>SQL</Link>
                        </div>
                        <div>
                            <Link to='/resources/dsa'>Data Structures and Algorithms</Link>
                        </div>
                    </p> */}
                </Container>
            </div>
		</div>
	);
};

export default ResourcesPage;
