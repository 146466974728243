/* eslint-disable */
import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import "./styles.scss";

const Timer = ({
  handleSubmit,
  setClosetimer,
  setClosewarntimer,
  setSubmitMode,
}) => {
  const [minutesLeft, setMinutesLeft] = useState(null);
  const [hoursLeft, setHoursLeft] = useState(null);
  const renderer = ({ hours, minutes, seconds }) => {
    setMinutesLeft(minutes);
    setHoursLeft(hours);
    if ((hours===0 || hours==='0') && (minutes <= 4 || minutes <= "4")) {
      setClosetimer(true);
      setClosewarntimer(true);
    }
    return (
      <span className={handleClass()}>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const handleClass = () => {
    if (parseInt(localStorage.getItem("time")) - Date.now() < 60000) {
      return "danger_time";
    }
    return "";
  };

  if (!localStorage.getItem("time")) return;

  return (
    <div
      className="timer"
      style={{
        backgroundColor: (hoursLeft===0 || hoursLeft==='0') && (minutesLeft <= 4 || minutesLeft <= "4") ? "red" : "",
      }}
    >
      <div>
        <Countdown
          date={parseInt(localStorage.getItem("time"))}
          renderer={renderer}
          onComplete={() => {
            handleSubmit();
            setSubmitMode("timed-out");
          }}
        />
      </div>
    </div>
  );
};

export default Timer;
