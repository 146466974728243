import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import contentImage from '../../../assets/images/forgot_password.svg';
import LeftArrow from '../../../assets/images/white-left-arrow.png';
import { resetPassword } from '../../../redux/actions';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import PasswordFloatingLabel from '../../../components/AuthComponents/PasswordFloatingLabel';
import AuthCarousel from '../../../components/AuthComponents/AuthCarousel';
import Loader from '../../../components/Loader';
import FormikErrorComponent from '../../../components/AuthComponents/FormikErrorComponent';
import {
	initialValuesResetPassword,
	resetPasswordSchema
} from '../../../constants/Authentication';
import { passwordValidation } from '../../../utils/Authentication';
import { reset } from '../../../redux/slices/authSlice';
import './styles.scss';
import CustomModal from '../../../components/CustomModal';

const ResetPassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [ResetSuccess, setResetSuccess] = useState(false);
	const { loading, resetPaswordSuccessMessage, resetPaswordErrorMessage } =
		useSelector((state) => state.auth);

	const token = new URLSearchParams(location.search).get('token');

	useEffect(() => {
		if (!isEmpty(resetPaswordSuccessMessage)) {
			setResetSuccess(true);
		}
		return () => {
			dispatch(reset());
		};
	}, [resetPaswordSuccessMessage]);

	const handleResetSuccessClose = () => {
		navigate('/login');
	};

	const onSubmit = () => {
		const formData = new FormData();
		formData.append('password', formik.values.password);
		formData.append('confirm_password', formik.values.password);
		formData.append('token', token);
		dispatch(resetPassword(formData));
		formik.resetForm();
	};

	const formik = useFormik({
		initialValues: initialValuesResetPassword,
		validationSchema: resetPasswordSchema,
		onSubmit
	});

	const passwordCheck = passwordValidation(formik.values.password);

	const modalTitle = 'Reset successfully';
	const modalContent = <p>Your password has been reset successfully. <br />
	Proceed to <Link to='/login'>log in</Link><br />
	If you have further questions, please <Link to='/contact'>contact us</Link></p>;
	const modalFooterButtons = [
		{ label: 'Close', variant: 'danger', onClick: handleResetSuccessClose }
	];

	return (
		<div className='reset_page'>
			{loading && (
				<div className='forgot_loader'>
					<Loader />
				</div>
			)}
			<CustomModal
				title={modalTitle}
				content={modalContent}
				footerButtons={modalFooterButtons}
				show={ResetSuccess}
				handleClose={handleResetSuccessClose}
			/>
			<AuthCarousel
				contentImage={contentImage}
				contentMessage={resetPaswordSuccessMessage}
			/>
			<div className='reset_form'>
				<div className='reset_form_wrapper'>
					<div className='left_arrow'>
						<img src={LeftArrow} />
					</div>
					<h2>Reset Password</h2>
					<Form className='reset_form_handle' onSubmit={formik.handleSubmit}>
						<div className="main-form-floating">
							<PasswordFloatingLabel
								passwordCheck={passwordCheck}
								formikTouched={formik.touched}
								formikErrors={formik.errors}
								formikValues={formik.values}
								onChange={formik.handleChange}
							/>
							<FormikErrorComponent
								errorName={formik.errors.password}
								touched={formik.touched.password}
							/>
						</div>
						<Button variant='primary' type='submit' disabled={loading}>
							{loading ? <Loader /> : 'Reset Password'}
						</Button>
					</Form>
					{!isEmpty(resetPaswordErrorMessage) && (
						<ErrorHandleComponent errorMessage={resetPaswordErrorMessage} />
					)}
					<span className='signup'>
						Do not have an account?{' '}
						<b>
							<Link to='/signup'>Sign up</Link>
						</b>
					</span>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
