/* eslint-disable */
import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HandleButtonComponent = ({ data, onSaveAsDraft, LockIcon }) => {
	const { userData } = useSelector(state => state.auth);
	const location = useLocation();

	const navigate = useNavigate();

	if (userData?.payload?.is_admin) {
		const enableTimmerdraft = localStorage.getItem('enable_non_timmer_draft');
		// !location.pathname.startsWith('/admin/') &&
		return (
			<div className='paid_button_div'>
				{(!data?.submitted?.attempted) || enableTimmerdraft ? (
					<Button className='save_draft' onClick={onSaveAsDraft}>
						Save draft
					</Button>
				):null}
				{!data?.submitted?.attempted && (
					<Button type='submit'>
						<div>
							<h5>Submit for grading</h5>
							<p>Don't worry you will get a chance to confirm</p>
						</div>
					</Button>
				)}
				{!data?.submitted?.attempted && (
					<p className='mobile_text'>
						Don't worry you will get a chance to confirm
					</p>
				)}
			</div>
		);
	}

	switch (data.subscription_type) {
		case 'free':
			return (
				<div className='unpaid_button_div'>
					<Button className='unpaid_btn' onClick={() => navigate('/login')}>
						<div>
							<h5>Log in to view all sections</h5>
							<p>No credit card required to sign-up</p>
						</div>
						<ReactSVG src={LockIcon} className='lock_icon' />
					</Button>
					<p className='mobile_text'>No credit card required to sign-up</p>
				</div>
			);
		case 'login':
		case 'paid':
			return (
				<div className='paid_button_div'>
					{!data?.submitted?.attempted && (
						<Button className='save_draft' onClick={onSaveAsDraft}>
							Save draft
						</Button>
					)}
					{!data?.submitted?.attempted && (
						<Button type='submit'>
							<div>
								<h5>Submit for grading</h5>
								<p>Don't worry you will get a chance to confirm</p>
							</div>
						</Button>
					)}
					{!data?.submitted?.attempted && (
						<p className='mobile_text'>
							Don't worry you will get a chance to confirm
						</p>
					)}
				</div>
			);
		default:
			return '';
	}
};
export default memo(HandleButtonComponent);
