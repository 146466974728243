/* eslint-disable */
import React, { useState, memo } from "react";
import { Accordion } from "react-bootstrap";
import AccordionHeader from "../AccordionHeader";
import QuestionParagraph from "../QuestionParagraph";
import Markdown from "../../Markdown";
const AccordionItem = ({
  section,
  index,
  handleAttempted,
  navigateToQuestion,
  data,
  LockIcon,
  currentActiveAccordion,
  handleClick,
}) => {
  return (
    <Accordion.Item
      eventKey={section.sno}
      className={section.sno === currentActiveAccordion && "active_Accordion"}
    >
      <AccordionHeader
        section={section}
        handleAttempted={handleAttempted}
        navigateToQuestion={navigateToQuestion}
        index={index}
        data={data}
        LockIcon={LockIcon}
      />
      <Accordion.Body>
        {section.list_qgs.map((group, j) => (
          <div key={j} className="group">
            <h4 className="group_title">{group.group_title}</h4>
            <p className="group_description">
              <Markdown>{group.description}</Markdown>
            </p>
            <QuestionParagraph group={group} />
            {group.list_ques.map((ques, k) => (
              <div key={k} className="question-container">
                {handleClick(ques.q_type, ques, index, j, k)}
              </div>
            ))}
          </div>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default memo(AccordionItem);
