import { createSlice } from '@reduxjs/toolkit';
import {
	subscriptionList,
	paymentSessionCreate,
	paymentVarify,
	currentPlan,
	stopAutoRecurring
} from '../actions';

export const paymentSlice = createSlice({
	name: 'paymentSlice',
	initialState: {
		loading: false,
		availableSubscriptionList: '',
		paymentSessionUrl: '',
		paymentVarificationSuccessData: {},
		paymentVarificationError: '',
		currentplaneStatus: {},
		paymentSessionError: '',
		stopAutoRecurringSuccess: {},
		stopAutoRecurringError: ''
	},
	reducers: {
		removeCurrentPlaneStatus: (state) => {
			return { ...state, currentplaneStatus: {}, paymentSessionError: '' };
		}
	},
	extraReducers: {
		[subscriptionList.pending]: (state) => {
			return { ...state, loading: true };
		},
		[subscriptionList.fulfilled]: (state, { payload }) => {
			return {
				...state,
				availableSubscriptionList: payload
			};
		},
		[subscriptionList.rejected]: (state, { payload }) => {
			return {
				...state
			};
		},
		[paymentSessionCreate.pending]: (state) => {
			return { ...state, loading: true };
		},
		[paymentSessionCreate.fulfilled]: (state, { payload }) => {
			return {
				...state,
				paymentSessionUrl: payload,
				loading: false,
				paymentSessionError: ''
			};
		},
		[paymentSessionCreate.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				paymentSessionError: payload
			};
		},
		[paymentVarify.pending]: (state) => {
			return { ...state, loading: true };
		},
		[paymentVarify.fulfilled]: (state, { payload }) => {
			return {
				...state,
				paymentVarificationSuccessData: payload,
				loading: false
			};
		},
		[paymentVarify.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				paymentVarificationError: payload
			};
		},
		[currentPlan.pending]: (state) => {
			return { ...state, loading: true };
		},
		[currentPlan.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				currentplaneStatus: payload,
				paymentSessionError: ''
			};
		},
		[currentPlan.rejected]: (state, { payload }) => {
			return {
				...state,
				currentplaneStatus: payload,
				loading: false,
				paymentSessionError: ''
			};
		},
		[stopAutoRecurring.pending]: (state) => {
			return { ...state, loading: true };
		},
		[stopAutoRecurring.fulfilled]: (state, { payload }) => {
			return {
				...state,
				stopAutoRecurringSuccess: payload,
				loading: false
			};
		},
		[stopAutoRecurring.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				stopAutoRecurringError: payload
			};
		}
	}
});

export const { removeCurrentPlaneStatus } = paymentSlice.actions;

export default paymentSlice.reducer;
