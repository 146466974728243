import React from 'react';
import RouterContainer from './router';
import './theme.scss';

function App () {
	return (
		<RouterContainer />
	);
}

export default App;
