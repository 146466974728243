/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesPhysics = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Resources: Physics for NEET & JEE</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h3>Under construction ...</h3>
                    <p>This page is under construction. Please check back by the end of Nov 2024.</p>
                </Container>
            </div>
		</div>
	);
};

export default ResourcesPhysics;
