/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

const ResourcesMathOlympiad = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Resources: Math Olympiad</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h3>Practice Problems: Level 2 (Grades 6-8)</h3>
                    <table>
                        <tr>
                            <td><Link to='/resources/math-olympiad/l2-set001A'>Set 1</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001B'>Set 2</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001C'>Set 3</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001D'>Set 4</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001E'>Set 5</Link></td>
                        </tr>
                        <tr>
                            <td><Link to='/resources/math-olympiad/l2-set001F'>Set 6</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001G'>Set 7</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001H'>Set 8</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001I'>Set 9</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001J'>Set 10</Link></td>
                        </tr>
                        <tr>
                            <td><Link to='/resources/math-olympiad/l2-set001K'>Set 11</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001L'>Set 12</Link></td>
                            <td><Link to='/resources/math-olympiad/l2-set001M'>Set 13</Link></td>
                        </tr>
                    </table> 
                </Container>
            </div>
		</div>
	);
};

export default ResourcesMathOlympiad;
